import CustomInput from "components/Input";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CustomButton from "components/Button";
import useFetch from "hooks/useFetch";
import ShowFalse from "assets/icons/ShowFalse";
import ShowTrue from "assets/icons/ShowYes";

import ToastMessage from "components/ToastMessage";
import { getToken } from "utils/getToken";

const ProfilePasswordChangePage = () => {
  const { t } = useTranslation();
  const token = getToken();
  const [userEmail, setUserEmail] = useState("");
  const [oldPsw, setOldPsw] = useState(false);
  const [newPsw, setNewPsw] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const toggleOldPsw = () => {
    setOldPsw(!oldPsw);
  };
  const toggleNewPsw = () => {
    setNewPsw(!newPsw);
  };
  useEffect(() => {
    const savedUserData = JSON.parse(localStorage.getItem("userData") || "{}");
    setUserEmail(savedUserData.email || "");
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const { triggerFetch } = useFetch({
    url: "profile/change-password",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      email: userEmail,
      newPassword: formData.newPassword,
      oldPassword: formData.oldPassword,
    },
  });

  const handleChangePassword = async () => {
    const res = await triggerFetch();
    if (res.status === 200) {
      const message = res.message;
      ToastMessage({ mode: "info", message });
      setFormData({
        oldPassword: "",
        newPassword: "",
      });
    } else {
      const message = res.message;
      ToastMessage({ mode: "error", message });
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", maxWidth: "360px" }}>
        <Typography
          sx={{
            marginTop: { xs: "0px", md: "14px" },
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            color: "#2E263D",
          }}
        >
          {t("changepsw")}
        </Typography>
        <CustomInput
          required
          fullWidth
          id="oldPassword"
          height="40px"
          name="oldPassword"
          placeholder={t("enterOldPsw")}
          label={t("oldpsw")}
          value={formData.oldPassword}
          onChange={handleInputChange}
          customBgColor="#F6F7F9"
          type={oldPsw ? "text" : "password"}
          iconEnd={
            <Box sx={{ cursor: "pointer", paddingTop: "5px" }} onClick={toggleOldPsw}>
              {oldPsw ? <ShowTrue /> : <ShowFalse />}
            </Box>
          }
        />
        <Box>
          <CustomInput
            required
            fullWidth
            id="newPassword"
            height="40px"
            name="newPassword"
            placeholder={t("enterOldPsw")}
            label={t("enterNewPsw")}
            value={formData.newPassword}
            onChange={handleInputChange}
            customBgColor="#F6F7F9"
            type={newPsw ? "text" : "password"}
            iconEnd={
              <Box sx={{ cursor: "pointer", paddingTop: "5px" }} onClick={toggleNewPsw}>
                {newPsw ? <ShowTrue /> : <ShowFalse />}
              </Box>
            }
          />
          <Typography sx={{ fontSize: "12px", color: "#6D6777" }}>{t("min8Chars")}</Typography>
        </Box>
        <CustomButton
          width={"224px"}
          textButton={t("saveChanges")}
          height={40}
          customBgColor={"#F4701B"}
          customHoverColor={"#F4701B"}
          customBorderRadius="8px"
          customFontSize="14px"
          onClick={handleChangePassword}
          disabled={!formData.oldPassword || !formData.newPassword}
        />
      </Box>
    </>
  );
};

export default ProfilePasswordChangePage;
