import { useTranslation } from "react-i18next";
import { fetchCountries } from "utils/fetchCountries";

export default function useMenuData() {
  const { t } = useTranslation();
  const countries = fetchCountries();

  const menuData = [
    {
      title: t("status"),
      codeTop: "status",
      subItems: [
        { title: "Active", id: "1.1", code: "active" },
        { title: "Not active", id: "1.2", code: "notactive" },
        { title: "Pause", id: "1.3", code: "pause" },
      ],
    },
    {
      title: t("country"),
      codeTop: "geo",
      subItems: countries.map(({ name, dial_code, code }) => ({
        title: name,
        id: `${dial_code}${code}`,
        code: code,
      })),
    },
    {
      title: t("vertical"),
      codeTop: "categoryVertical",
      subItems: [
        { title: t("nutra"), id: "3.1", code: "nutra" },
        { title: t("betting"), id: "3.2", code: "betting" },
        { title: t("dating"), id: "3.3", code: "dating" },
        { title: t("gambling"), id: "3.4", code: "gambling" },
        { title: t("trading"), id: "3.5", code: "trading" },
      ],
    },
  ];

  return menuData;
}
