import React, { ChangeEvent, useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";
import { usePushStore } from "store/pushStore";
import CustomSelectComponent from "components/Select";
import { getLanguages, translateText } from "utils/translationService";
import CustomButton from "components/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import PushTemplatesModal from "components/PushTemplatesModal";
import { IPwa } from "interfaces/pwas";

export interface ILang {
  name: string;
  code: string;
}

interface INewsletterContentProps {
  selectedPwa: IPwa | null;
}

export default function NewsletterContent({ selectedPwa }: INewsletterContentProps) {
  const { t } = useTranslation();
  const [languagesList, setLanguagesList] = useState<ILang[]>([]);
  const { pushData, savePushData } = usePushStore();
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  useEffect(() => {
    const lang = async () => {
      const fetchLanguages: any = await getLanguages();
      setLanguagesList(fetchLanguages?.languages);
    };
    lang();
  }, []);

  const handleHeadingChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    savePushData({ headings: value });
  };

  const handleContentChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    savePushData({ contents: value });
  };

  const handleURLChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    savePushData({ url: value });
  };

  const handleLanguageChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    const textsToTranslate = {
      headings: pushData.headings,
      contents: pushData.contents,
    };

    const translatedTexts = await translateText(textsToTranslate, value as string);
    savePushData({ ...translatedTexts, language: value as string });
  };

  return (
    <>
      <Accordion
        defaultExpanded
        sx={{
          boxShadow: "none",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          "&::before": {
            content: "none",
          },
          "&.Mui-expanded": { margin: 0 },
        }}
      >
        <AccordionSummary
          sx={{ padding: { xs: "0px 16px", md: "0px 20px" }, height: { xs: "56px", md: "64px" } }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            sx={{
              lineHeight: "32px",
              fontSize: "16px",
              color: "#2E263D",
              fontFamily: "var(--cygreMedium), sans-serif",
            }}
          >
            {t("campaignContent")}
          </Typography>
        </AccordionSummary>
        <Box sx={{ maxWidth: "480px" }}>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: { xs: "0px 16px 16px 16px", md: "0px 20px 20px 20px" },
            }}
          >
            <CustomInput
              required
              fullWidth
              id="author"
              height="40px"
              placeholder={t("enterHeadline")}
              name="author"
              label={t("headline")}
              value={pushData.headings}
              onChange={handleHeadingChange}
              customBgColor="#F6F7F9"
            />

            <CustomInput
              required
              fullWidth
              id="descriptionPC"
              placeholder={t("enterPushText")}
              multiline={true}
              rows={4}
              name="descriptionPC"
              label={t("text")}
              value={pushData.contents}
              onChange={handleContentChange}
              customBgColor="#F6F7F9"
            />
            <CustomInput
              fullWidth
              id="url"
              placeholder="https://example.com?cid={cid}&status={source}"
              name="url"
              label={t("enterLinkPush")}
              value={pushData.url}
              onChange={handleURLChange}
              customBgColor="#F6F7F9"
            />
            <CustomSelectComponent
              options={languagesList.map((lang: ILang) => ({
                value: lang.code,
                label: lang.name,
              }))}
              placeholder={t("selectlanguage")}
              title={t("translatePush")}
              value={pushData.language}
              onChange={(e: any) => handleLanguageChange(e)}
              noBorder={true}
              backgroundColor="#F6F7F9"
              customComponentStyles={{ paddingBottom: "16px" }}
            />
            <CustomButton
              textButton={t("pushTemplates")}
              customBgColor="#F4701B"
              customHoverColor="#F0670B"
              customTextTransform="none"
              customBorderRadius="8px"
              width="100%"
              height={40}
              startIconSrc={<DescriptionIcon sx={{ marginRight: "6px" }} />}
              onClick={() => setIsTemplatesModalOpen(true)}
            />

            {/*
          TODO: return later
          <Box sx={{ paddingBottom: "24px" }}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px dashed #C9D0D8",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
              }}
            >
              <input
                type="file"
                multiple
                id="preview-upload"
                name="preview-upload"
                onChange={(e) => {
                  // console.log("image", e);
                }}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  margin: "auto",
                  color: "#2E263D",
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "CygreRegular",
                }}
              >
                {t("image")}
              </Typography>
            </Box>
          </Box> */}
          </AccordionDetails>
        </Box>
      </Accordion>
      {isTemplatesModalOpen && (
        <PushTemplatesModal
          open={isTemplatesModalOpen}
          setIsModalOpen={setIsTemplatesModalOpen}
          selectedPwa={selectedPwa}
        />
      )}
    </>
  );
}
