import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { Box } from "@mui/material";
import useFetch from "hooks/useFetch";
import useIdStore from "store/pwaId";
import EditIcon from "assets/icons/EditIcon";
import Eye from "assets/icons/Eye";
import PlayIcon from "assets/icons/PlayIcon";
import StopIcon from "assets/icons/StopIcon";
import useFetchFormData from "hooks/useFetchFormData";
import { useNavigate } from "react-router";
import { getToken } from "utils/getToken";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { useTranslation } from "react-i18next";

interface IActionButton {
  id: string;
  status: string;
  getPwas: any;
  domain: string;
}

export default function ActionButton({ getPwas, id, status, domain }: IActionButton) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const session = getToken();

  const { setId } = useIdStore();
  const { triggerFetch: getPwa } = useFetch({
    url: `pwa/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const handlePreview = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (domain) {
      window.open(`https://${domain}`, "_blank");
    } else {
      navigate(`/pwa-preview/${id}`);
    }
  };

  const handleEdit = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const res = await getPwa();
    setId(res.data._id);
    navigate(`edit-pwa/${id}`);
  };

  const handleStats = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const res = await getPwa();
    navigate("/stats", { state: res.data.appName });
  };

  const { triggerFetch: updatePWA } = useFetchFormData({
    url: `pwa/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  async function handleUpdatePwa(value: string) {
    const data = new FormData();
    data.append("status", value);

    const res = await updatePWA(data);
    if (res) {
      getPwas();
    }
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
      {status === "active" && (
        <BootstrapTooltip title={t("setstop")} placement="top">
          <Box
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              void handleUpdatePwa("pause");
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            <StopIcon width={21} height={21} color={"#6D6777"} />
          </Box>
        </BootstrapTooltip>
      )}
      {status === "pause" && (
        <BootstrapTooltip title={t("setactive")} placement="top">
          <Box
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              void handleUpdatePwa("active");
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            <PlayIcon width={21} height={21} color={"#6D6777"} />
          </Box>
        </BootstrapTooltip>
      )}
      <BootstrapTooltip title={t("seestats")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
            "& svg": { width: "24", height: "24", color: "#6d6777" },
          }}
          onClick={handleStats}
        >
          <AssessmentOutlinedIcon sx={{ color: "#6D6777" }} />
        </Box>
      </BootstrapTooltip>

      <BootstrapTooltip title={t("editPWA")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
            "& svg": { width: "24", height: "24", color: "#6d6777" },
          }}
          onClick={handleEdit}
        >
          <EditIcon />
        </Box>
      </BootstrapTooltip>
      <BootstrapTooltip title={t("gotopreview")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
            "& svg": { width: "24", height: "24", color: "#6d6777" },
          }}
          onClick={handlePreview}
        >
          <Eye />
        </Box>
      </BootstrapTooltip>
    </Box>
  );
}
