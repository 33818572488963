import React from "react";
import Select, { MultiValue, ActionMeta, components } from "react-select";
import { Box, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export interface CountryOption {
  value: string;
  label: string;
  flag?: string;
}

interface CustomSelectProps {
  options: CountryOption[];
  placeholder: string;
  title: string;
  value: MultiValue<CountryOption>;
  onChange: (value: MultiValue<CountryOption>, actionMeta: ActionMeta<CountryOption>) => void;
  noBorder?: boolean;
  backgroundColor?: string;
}

const MultiSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder,
  title,
  value,
  onChange,
  backgroundColor = "#F6F7FB",
}) => {
  const customSingleValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.data.flag && (
        <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      )}
      {props.data.label}
    </div>
  );

  const customMultiValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center", backgroundColor, padding: "3px" }}>
      {props.data.flag && (
        <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      )}
      {props.data.label}
      <span onClick={() => props.removeProps.onClick()} style={{ marginLeft: "10px", cursor: "pointer" }}>
        ×
      </span>
    </div>
  );

  const CustomDropdownIndicator = (props: any) => {
    const { menuIsOpen } = props.selectProps;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <ArrowDropUpIcon style={{ width: "24px", color: "#6D6777" }} />
        ) : (
          <ArrowDropDownIcon style={{ width: "24px", color: "#6D6777" }} />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <Box style={{ width: "100%" }}>
      <InputLabel
        htmlFor="custom-select"
        sx={{
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "12px",
          lineHeight: 1.22,
          textAlign: "left",
          marginBottom: "10px",
        }}
      >
        {title}
      </InputLabel>
      <Select
        options={options}
        isMulti
        id="custom-select"
        onChange={onChange}
        placeholder={placeholder}
        isSearchable={false}
        value={value}
        components={{
          SingleValue: customSingleValue,
          MultiValue: customMultiValue,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
            backgroundColor,
            borderRadius: "8px",
          }),
          control: (base) => ({
            ...base,
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "14px",
            lineHeight: 1.43,
            color: "#2E263D",
            backgroundColor,
            borderRadius: "8px",
            border: "none",
            boxShadow: "none",
            outline: "none",
            cursor: "pointer",
          }),
          multiValue: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            padding: "5px",
            backgroundColor,
            border: "none",
            borderRadius: "4px",
            margin: "2px",
            flexShrink: 0,
          }),
          multiValueLabel: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            backgroundColor,
          }),
          multiValueRemove: (base) => ({
            ...base,
            cursor: "pointer",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            color: "#6D6777",
          }),
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            gap: "4px",
            padding: "0px 13px",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "14px",
            lineHeight: 1.43,
            color: "#2E263D",
          }),
          option: (base) => ({
            ...base,
            fontSize: "14px",
            lineHeight: 1.43,
            fontFamily: "var(--cygreRegular), sans-serif",
            color: "#2E263D",
            cursor: "pointer",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "14px",
            lineHeight: 1.43,
            fontFamily: "var(--cygreRegular), sans-serif",
            textOverflow: "ellipsis !important",
            color: "#b4b1b9",
            opacity: 1,
          }),
        }}
      />
    </Box>
  );
};

export default MultiSelect;
