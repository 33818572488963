import useModalStore from "store/modalStore";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseButtonIcon from "assets/icons/Close";
import { Avatar, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomInput from "components/Input";
import { useEffect, useState } from "react";
import { IPwa } from "interfaces/pwas";
import { ITransaction } from "interfaces/stats";
import Profile from "assets/Profile_pic.png";
import showPreviewIcon from "utils/showPreviewImage";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import TelegramIcon from "@mui/icons-material/Telegram";
import SelectPeriod from "components/Charts/SelectPeriod";
import UserBoughtDomainsList from "./BoughtDomainsList";
import PwaStatsList from "./PwaStatsList";
import Preloader from "components/GlobalPreloader/Preloader";
import { IListedDomain } from "interfaces/domains";
import imgSrc from "assets/imageAuth.png";
import useMediaByValue from "hooks/useMediaByValue";

interface IUserData {
  user: { id: string; email: string; telegram: string; icon: string };
  domains: IListedDomain[];
  pwaList: IPwa[];
  userTransactions: {
    installs: ITransaction[];
    deposits: ITransaction[];
    referrals: ITransaction[];
    promocodes: ITransaction[];
  };
}

const UserStatsModal = () => {
  const token = getToken();
  const { t } = useTranslation();
  const { isUserStatsModalOpen, closeModal, userInfo } = useModalStore();
  const [userStatsData, setUserStatsData] = useState<IUserData | null>(null);
  const [statsRange, setStatsRange] = useState<string>("7_days_ago");
  const isTabletOrDesctop = useMediaByValue("(min-width:768px)");

  const { id, email, telegram, icon } = userStatsData?.user || {};
  const domainsList = userStatsData?.domains || [];
  const totalDepositsAmount = userStatsData?.userTransactions.deposits
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);
  const totalInstallsAmount = userStatsData?.userTransactions.installs
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);
  const totalReferralsBonusAmount = userStatsData?.userTransactions.referrals
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);
  const totalPromocodesBonusAmount = userStatsData?.userTransactions.promocodes
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);

  const mergedPwaData = userStatsData?.pwaList.map((pwa) => {
    const { _id, appName, creatives, campaignId } = pwa;

    const allPwaInstalls = userStatsData?.userTransactions.installs.filter((transaction) => {
      return transaction.pwaId === _id;
    });

    const groupedInstallsByPrice = allPwaInstalls.reduce((acc: Record<string, number>, transaction) => {
      const { amount } = transaction;

      acc[amount] = (acc[amount] || 0) + 1;
      return acc;
    }, {});

    const mergedInstallsAmountByPrice = Object.entries(groupedInstallsByPrice).map(([price, installs]) => ({
      [price]: installs,
    }));

    const totalInstallsAmount = allPwaInstalls
      .reduce((sum: number, transaction) => sum + transaction.amount, 0)
      .toFixed(2);

    return {
      id: _id,
      appName,
      icon: creatives.icon || null,
      campaignId,
      totalInstalls: allPwaInstalls.length,
      installsArrByPrice: mergedInstallsAmountByPrice,
      totalInstallsAmount,
    };
  });

  const { loading: userStatsLoading, triggerFetch: getStats } = useFetch({
    url: `admin/users/${userInfo?.id}/stats`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      range: statsRange,
    },
  });

  const getUserStats = async () => {
    const userStats = await getStats();

    if (userStats.success) {
      setUserStatsData(userStats.data);
    }
  };

  useEffect(() => {
    getUserStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsRange]);

  return (
    <Modal
      open={isUserStatsModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-user-stats-title"
      aria-describedby="modal-user-stats-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: { xs: "24px", md: "36px" },
          bgcolor: "#FFFFFF",
          boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
          width: "90dvw",
          maxWidth: "1000px",
          height: "90dvh",
          maxHeight: "90dvh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16, width: 32, height: 32 }}
          aria-label="modal close button"
          onClick={closeModal}
        >
          <CloseButtonIcon />
        </IconButton>
        {userStatsLoading ? (
          <Preloader customStyles={{ border: "none", padding: "0", margin: "0" }} />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "flex-start", md: "center" },
                flexDirection: { xs: "column", md: "row" },
                marginBottom: "40px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Avatar src={`${icon ? showPreviewIcon(icon) : Profile}`} sx={{ width: 100, height: 100 }} />
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <PermIdentityIcon
                      sx={{
                        color: "#6D6777",
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                        color: "#6D6777",
                      }}
                    >
                      {id}
                    </Typography>
                  </Box>
                  {email && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <AlternateEmailIcon sx={{ color: "#6D6777", width: "18px", height: "18px" }} />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {email}
                      </Typography>
                    </Box>
                  )}

                  {telegram && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <TelegramIcon
                        sx={{
                          color: "#6D6777",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {telegram}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <SelectPeriod
                selectedPeriod={statsRange}
                onChange={setStatsRange}
                isAdminUserStatsSelect
                customStyles={{
                  width: { xs: "100%", md: "200px" },
                  backgroundColor: "#F6F7F9",
                  "&.MuiInputBase-root": {
                    margin: { xs: "40px 0 0 0", md: "0 0 0 auto" },
                  },
                }}
              />
            </Box>
            <Grid container rowSpacing={4} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  fullWidth
                  id="depositAmount"
                  name="depositAmount"
                  value={totalDepositsAmount}
                  label={t("totalDeposits")}
                  height="40px"
                  customBgColor="#F6F7FB"
                  customFontSize="14px"
                  customLableMargin="6px"
                  customLablePadding="0"
                  isReadOnly
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  fullWidth
                  id="promocodesAmount"
                  name="promocodesAmount"
                  value={totalPromocodesBonusAmount}
                  label={t("totalPromocodesBonus")}
                  height="40px"
                  customBgColor="#F6F7FB"
                  customFontSize="14px"
                  customLableMargin="6px"
                  customLablePadding="0"
                  isReadOnly
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  fullWidth
                  id="installsAmount"
                  name="installsAmount"
                  value={totalInstallsAmount}
                  label={t("totalInstalls")}
                  height="40px"
                  customBgColor="#F6F7FB"
                  customFontSize="14px"
                  customLableMargin="6px"
                  customLablePadding="0"
                  isReadOnly
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  fullWidth
                  id="referralBonusesAmount"
                  name="referralBonusesAmount"
                  value={totalReferralsBonusAmount}
                  label={t("totalReferralBonus")}
                  height="40px"
                  customBgColor="#F6F7FB"
                  customFontSize="14px"
                  customLableMargin="6px"
                  customLablePadding="0"
                  isReadOnly
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  fullWidth
                  id="boughtDomainsAmount"
                  name="boughtDomainsAmount"
                  value={userStatsData?.domains.length}
                  label={t("totalBoughtDomains")}
                  height="40px"
                  customBgColor="#F6F7FB"
                  customFontSize="14px"
                  customLableMargin="6px"
                  customLablePadding="0"
                  isReadOnly
                />
                {domainsList.length !== 0 && <UserBoughtDomainsList domains={domainsList} />}
              </Grid>
              {isTabletOrDesctop && domainsList.length !== 0 && mergedPwaData?.length !== 0 ? (
                <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={imgSrc} alt="duck-image" style={{ maxWidth: "200px", height: "200px" }} />
                </Grid>
              ) : null}
              {mergedPwaData?.length !== 0 && (
                <Grid size={12}>
                  <PwaStatsList pwaData={mergedPwaData || []} />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UserStatsModal;
