import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Box } from "@mui/material";
import AdminActionButton from "./AdminActionButtons";
import styled from "@emotion/styled";
import showPreviewIcon from "utils/showPreviewImage";
import { useTranslation } from "react-i18next";
import { IUser } from "interfaces/auth";
import TelegramIcon from "@mui/icons-material/Telegram";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Profile from "assets/Profile_pic.png";
import moment from "moment";

interface AdminUsersTabProps {
  usersArr: IUser[];
  page: number;
  totalUsers: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
}

const TypographyStyled = styled(Typography)(() => {
  return {
    color: "#6D6777",
    fontWeight: 500,
    fontFamily: "var(--cygreRegular), sans-serif",
  };
});

const AdminUsersTab: React.FC<AdminUsersTabProps> = ({ usersArr, totalUsers, page, handleChangePage }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%", overflowY: "auto" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#F6F7FB",
            }}
          >
            <TableCell
              sx={{
                paddingLeft: "36px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
            >
              <TypographyStyled>{t("user")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("registrationdate")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("team")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("wallet")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("group")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled>{t("bonus")}</TypographyStyled>
            </TableCell>
            <TableCell sx={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
              <TypographyStyled
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {t("actions")}
              </TypographyStyled>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersArr?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Typography variant="subtitle2">{t("noitems")}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            usersArr.map(
              ({
                _id,
                createdAt,
                icon,
                email,
                telegram,
                telegramUsername,
                team,
                group,
                wallet,
                referralDepositBonusRate,
                referralInstallBonusRate,
              }) => {
                const formattedDate = moment(createdAt).format("DD.MM.YYYY HH:mm:ss");
                const depositBonusRate = Number((referralDepositBonusRate * 100).toFixed(0));
                const installBonusRate = Number((referralInstallBonusRate * 100).toFixed(0));

                const userInfo = {
                  id: _id,
                  email: email || "",
                  telegram: telegram || "",
                  telegramUsername: telegramUsername || "",
                  referralDepositBonusRate: depositBonusRate || 5,
                  referralInstallBonusRate: installBonusRate || 13,
                };
                return (
                  <TableRow key={_id}>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "36px",
                      }}
                    >
                      <img
                        src={icon ? showPreviewIcon(icon) : Profile}
                        alt="icon"
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: "18px",
                          borderRadius: "50%",
                        }}
                      />
                      <Box>
                        {email && email?.length !== 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <AlternateEmailIcon sx={{ color: "#6D6777", width: "18px", height: "18px" }} />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "var(--cygreRegular), sans-serif",
                              }}
                            >
                              {email}
                            </Typography>
                          </Box>
                        ) : null}

                        {(telegram && telegram?.length !== 0) || (telegramUsername && telegramUsername.length !== 0) ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <TelegramIcon
                              sx={{
                                color: "#6D6777",
                                width: "18px",
                                height: "18px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontFamily: "var(--cygreRegular), sans-serif",
                              }}
                            >
                              {telegram || telegramUsername}
                            </Typography>
                          </Box>
                        ) : null}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <PermIdentityIcon
                            sx={{
                              color: "#6D6777",
                              width: "18px",
                              height: "18px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              fontFamily: "var(--cygreRegular), sans-serif",
                              color: "#6D6777",
                            }}
                          >
                            {_id}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {createdAt ? formattedDate : "—"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {team || "—"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {wallet.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {group || "—"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {!depositBonusRate && !installBonusRate
                          ? `default`
                          : `${depositBonusRate}% / ${installBonusRate}%`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <AdminActionButton userInfo={userInfo} />
                    </TableCell>
                  </TableRow>
                );
              }
            )
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalUsers}
        rowsPerPage={20}
        page={page}
        labelRowsPerPage={null}
        labelDisplayedRows={() => ""}
        onPageChange={handleChangePage}
        sx={{
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-toolbar": {
            padding: 0,
            justifyContent: "flex-start",
          },
        }}
      />
    </Box>
  );
};

export default AdminUsersTab;
