import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import showPreviewIcon from "utils/showPreviewImage";
import ImageIcon from "assets/icons/ImageIcon";
import { t } from "i18next";

interface Option {
  appName: string;
  value?: string;
  label?: string;
}

interface CustomSelectComponentProps {
  options: Option[] | any;
  title?: string;
  value: any;
  onChange?: any;
  noBorder?: boolean;
  placeholder?: string;
  backgroundColor?: string;
  name?: string;
  disabled?: boolean;
}

const CustomFormControl = styled(FormControl)<{ noBorder?: boolean }>(({ theme, noBorder }) => ({
  "& .MuiOutlinedInput-root": {
    height: 40,
    borderRadius: "8px",
    borderColor: noBorder ? "transparent" : undefined,
    "& fieldset": {
      borderColor: noBorder ? "transparent" : undefined,
    },
    "&:hover fieldset": {
      borderColor: noBorder ? "transparent" : "#F4701B",
    },
    "&.Mui-focused fieldset": {
      borderColor: noBorder ? "transparent" : "#F4701B",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "transparent",
    },
  },
}));

const CustomSelect = styled(Select)<{ backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  height: 40,
  backgroundColor: backgroundColor || "inherit",
  "& .MuiSelect-select": {
    fontSize: "14px",
    fontFamily: "var(--cygreRegular), sans-serif",
    color: "#808080",
    display: "flex !important",
    alignItems: "center !important",
  },
  "& .MuiSelect-icon": {
    color: "#808080",
  },
}));

const StatsSelectComponent: React.FC<CustomSelectComponentProps> = ({
  options,
  title,
  value,
  onChange,
  noBorder = false,
  backgroundColor,
  placeholder,
  name,
  disabled,
}) => {
  return (
    <Box sx={{ minWidth: 160 }}>
      <CustomFormControl fullWidth noBorder={noBorder}>
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "12px",
            lineHeight: 1.22,
            textAlign: "left",
            paddingBottom: "10px",
          }}
          id="custom-select-label"
        >
          {title}
        </Typography>
        <CustomSelect
          labelId="custom-select-label"
          id="custom-select"
          name={name}
          value={value}
          sx={{
            color: "#2E263D",
            textOverflow: "ellipsis !important",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: 14,
          }}
          onChange={onChange}
          backgroundColor={backgroundColor}
          displayEmpty
          disabled={disabled}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              sx: {
                borderRadius: "8px",
                marginTop: "8px",
                maxHeight: 320,
                "& .MuiMenu-list": {
                  padding: "0px !important",
                  width: "100% !important",
                },
              },
            },
          }}
        >
          {placeholder && (
            <MenuItem sx={{ fontSize: "14px", fontStyle: "italic" }} value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options?.map((option: any) => (
            <MenuItem
              sx={{
                fontFamily: "var(--cygreRegular), sans-serif",
                color: "#2E263D",
                fontSize: 14,
              }}
              key={option._id}
              value={option.campaignId}
            >
              <Box position={"relative"} sx={{ display: "flex", alignItems: "center" }}>
                {option.icon ? (
                  <img
                    src={showPreviewIcon(option.icon)}
                    alt="Загруженное изображение"
                    style={{
                      width: 28,
                      height: 28,
                      borderRadius: "50%",
                      marginRight: "12px",
                    }}
                  />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 36,
                      height: 36,
                      marginRight: "5px",
                      borderRadius: "50%",
                    }}
                  >
                    <ImageIcon />
                  </Box>
                )}
                {option.appName}
                {option.campaignId === "empty" && (
                  <Typography
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      transform: "translateX(110%)",
                      fontFamily: "var(--cygreRegular), sans-serif",
                      color: "#F4701B",
                    }}
                    fontSize={10}
                    fontWeight={400}
                  >
                    {t("noitems")}
                  </Typography>
                )}
              </Box>
            </MenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </Box>
  );
};

export default StatsSelectComponent;
