import { Box, Modal } from "@mui/material";
import PhoneMockup from "components/PhoneMockup";
import CloseIcon from "@mui/icons-material/Close";
import PhonePushContent from "components/PhonePush";

interface IPhoneMockupModal {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  outline: "none",
};

export default function PushMockupModal({ openModal, setOpenModal }: IPhoneMockupModal) {
  const handleClose = () => setOpenModal(false);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-phone-mockup"
      aria-describedby="modal-phone-mockup-description"
      style={{ backdropFilter: "blur(5px)" }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: { xs: "-30px", sm: "-50px" },
              cursor: "pointer",
            }}
            aria-label="close-button"
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: "#fff", fontSize: "36px" }} />
          </Box>
          <PhoneMockup>
            <PhonePushContent isModal={true} />
          </PhoneMockup>
        </Box>
      </Box>
    </Modal>
  );
}
