import { useEffect, useState } from "react";
import Select, { MultiValue, ActionMeta, components } from "react-select";
import { fetchCountries } from "utils/fetchCountries";
import { useTranslation } from "react-i18next";
import { Box, InputLabel } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface CountryOption {
  value: string;
  label: string;
  flag: string;
}

interface GeoMultiSelectProps {
  onChange: (selected: MultiValue<CountryOption>) => void;
  geo?: string[];
}

const GeoMultiSelect = ({ onChange, geo = [] }: GeoMultiSelectProps) => {
  const [options, setOptions] = useState<CountryOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<CountryOption>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const getCountries = () => {
      const data = fetchCountries();
      const formattedOptions = data.map((country: any) => ({
        value: country.code,
        label: country.name,
        flag: country.flag,
      }));
      setOptions(formattedOptions);
    };
    getCountries();
  }, []);

  useEffect(() => {
    if (Array.isArray(geo)) {
      const countries = geo
        .join(",")
        .split(",")
        .map((country) => country.trim());

      const geoArray = countries
        .map((country) => {
          const found = options.find((option) => option.value === country);
          return found || null;
        })
        .filter(Boolean) as MultiValue<CountryOption>;

      setSelectedOptions(geoArray);
    }
  }, [geo, options]);

  const handleChange = (selected: MultiValue<CountryOption>, _actionMeta: ActionMeta<CountryOption>) => {
    setSelectedOptions(selected);
    onChange(selected);
  };

  const customSingleValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={props.data.flag} alt={props.data.label} style={{ width: "20px", marginRight: "10px" }} />
      {props.data.label}
    </div>
  );

  const customMultiValue = (props: any) => (
    <div style={{ display: "flex", alignItems: "center", backgroundColor: "#F6F7FB", padding: "3px" }}>
      {props.data.flag}
      {props.data.label}
      <span onClick={() => props.removeProps.onClick()} style={{ marginLeft: "10px", cursor: "pointer" }}>
        ×
      </span>
    </div>
  );

  const CustomDropdownIndicator = (props: any) => {
    const { menuIsOpen } = props.selectProps;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <ArrowDropUpIcon style={{ width: "24px", color: "#6D6777" }} />
        ) : (
          <ArrowDropDownIcon style={{ width: "24px", color: "#6D6777" }} />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <Box style={{ width: "100%" }}>
      <InputLabel
        htmlFor="geo-select"
        sx={{
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "12px",
          lineHeight: 1.22,
          textAlign: "left",
          marginBottom: "10px",
        }}
      >
        {t("geo")}
      </InputLabel>
      <Select
        options={options}
        isMulti
        id="geo-select"
        onChange={handleChange}
        placeholder={t("setGeo")}
        value={selectedOptions}
        components={{
          SingleValue: customSingleValue,
          MultiValue: customMultiValue,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
            backgroundColor: "#F6F7FB !important",
            borderRadius: "8px",
          }),
          control: (base) => ({
            ...base,
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "14px",
            lineHeight: 1.43,
            color: "#2E263D",
            backgroundColor: "#F6F7FB !important",
            borderRadius: "8px",
            border: "none",
            boxShadow: "none",
            outline: "none",
            cursor: "pointer",
          }),
          multiValue: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            padding: "5px",
            backgroundColor: "#F6F7FB !important",
            border: "none",
            borderRadius: "4px",
            margin: "2px",
            flexShrink: 0,
          }),
          multiValueLabel: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F6F7FB !important",
          }),
          multiValueRemove: (base) => ({
            ...base,
            cursor: "pointer",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            color: "#6D6777",
          }),
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            gap: "4px",
            padding: "0px 13px",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "14px",
            lineHeight: 1.43,
            color: "#2E263D",
          }),
          option: (base) => ({
            ...base,
            fontSize: "14px",
            lineHeight: 1.43,
            fontFamily: "var(--cygreRegular), sans-serif",
            color: "#2E263D",
            cursor: "pointer",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "14px",
            lineHeight: 1.43,
            fontFamily: "var(--cygreRegular), sans-serif",
            textOverflow: "ellipsis !important",
            color: "#b4b1b9",
            opacity: 1,
          }),
        }}
      />
    </Box>
  );
};

export default GeoMultiSelect;
