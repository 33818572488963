import { create } from "zustand";
import { IPromocodeCreationData } from "interfaces/promocode";

interface AppState {
  promocodeData: IPromocodeCreationData;
  savePromocodeData: (newData: Partial<IPromocodeCreationData>) => void;
  clearPromocodeData: () => void;
  getDefaultPromocodeData: () => IPromocodeCreationData;
}

export const emptyPromocodeData: IPromocodeCreationData = {
  name: "",
  type: "",
  status: "",
  expiredAt: null,
  amount: "",
  description: { en: "", ru: "", ua: "" },
};

const usePromocodesStore = create<AppState>((set) => ({
  promocodeData: { ...emptyPromocodeData },
  savePromocodeData: (newData) =>
    set((state) => {
      const updatedPromocodeData = { ...state.promocodeData, ...newData };

      return { promocodeData: updatedPromocodeData };
    }),
  clearPromocodeData: () => set({ promocodeData: { ...emptyPromocodeData } }),
  getDefaultPromocodeData: () => emptyPromocodeData,
}));

export { usePromocodesStore };
