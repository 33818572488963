import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import CustomButton from "components/Button";
import CustomInput from "components/Input";
import { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import NSInputs from "./NsInputs";
import AnswerModal from "./AnswerModal";
import useIdStore from "store/pwaId";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import useMediaByValue from "hooks/useMediaByValue";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "80vh",
};

interface IModalDomain {
  open: boolean;
  setOpen: (value: boolean) => void;
  getDomains: () => void;
}

export default function ModalBindDomain({ open, setOpen, getDomains }: IModalDomain) {
  const { t } = useTranslation();
  const { id } = useIdStore();
  const token = getToken();
  const [isLinkDomainDisabled, setIsLinkDomainDisabled] = useState(true);
  const [showNSInstructions, setShowNSInstructions] = useState(false);
  const [AnswerCheckModal, setAnswerCheckModal] = useState(false);
  const isTablet = useMediaByValue("(min-width:768px)");
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

  const [newDomain, setNewDomain] = useState({
    nsFirst: "",
    nsSecond: "",
    _id: "",
    status: "",
  });

  const [formData, setFormData] = useState({
    domain: "",
  });

  const { loading: loadingDomain, triggerFetch: createDomain } = useFetch({
    url: "domain/attach-to-pwa",
    method: "POST",
    body: { name: formData.domain, pwaId: id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: checkLoading, triggerFetch: triggercheckFetch } = useFetch({
    url: `domain/${newDomain?._id}/check`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: keitaroAttachLoading, triggerFetch: attachDomainToKeitaro } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/domains",
      requestBody: {
        name: `${formData.domain}`,
      },
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.toLocaleLowerCase(),
    }));
  };

  const handleClose = () => {
    setShowNSInstructions(false);
    setFormData((prevState) => ({
      ...prevState,
      domain: "",
    }));
    setOpen(false);
  };

  const handleLinkDomain = async () => {
    if (!domainRegex.test(formData.domain)) {
      toast.error(t("customDomainNameError"));
      return;
    }

    if (!id) {
      toast.error(t("attachdomainmessage"));
      return;
    }

    try {
      const result = await createDomain();

      if (result.success) {
        await attachDomainToKeitaro();
        await getDomains();
        setNewDomain(result.data);
        setShowNSInstructions(true);
        toast.success(t("customDomainCreated"));
      } else {
        handleClose();
        setFormData((prevState) => ({
          ...prevState,
          domain: "",
        }));
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(t("customDomainCreatingError"));
    }
  };

  const handleCheck = async () => {
    const result = await triggercheckFetch();

    if (result) {
      setShowNSInstructions(false);
      handleClose();
      await getDomains();
    }

    setAnswerCheckModal(true);
  };

  useEffect(() => {
    setIsLinkDomainDisabled(!formData.domain || formData.domain.length < 3);
  }, [formData]);

  const isLoading = loadingDomain || keitaroAttachLoading || checkLoading;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                textAlign: "left",
                fontSize: "24px",
                lineHeight: "45px",
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#2E263D",
              }}
            >
              {t("linkdomain")}
            </Typography>
            <Box
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>
          </Box>
          {!showNSInstructions ? (
            <>
              <CustomInput
                required
                fullWidth
                id="domain"
                placeholder="mynewpwadomain.com"
                name="domain"
                label={t("domain")}
                autoFocus={isTablet ? true : false}
                value={formData.domain}
                onChange={handleInputChange}
                customBgColor="#F6F7F9"
              />

              {/*//TODO: Move to attach NS modal*/}
              {/*<Typography*/}
              {/*  sx={{*/}
              {/*    fontSize: "12px",*/}
              {/*    lineHeight: "19px",*/}
              {/*    color: "#555",*/}
              {/*    marginTop: "-10px",*/}
              {/*    marginBottom: "32px",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {t("toconfigurethedomain")}{" "}*/}
              {/*  <a*/}
              {/*    style={{ color: "#F4701B" }}*/}
              {/*    href="https://www.cloudflare.com/a/account/my-account"*/}
              {/*    target="_blank"*/}
              {/*    rel="noopener noreferrer"*/}
              {/*  >*/}
              {/*    API Keys*/}
              {/*  </a>{" "}*/}
              {/*  {t("andgettheglobalAPIkey")}*/}
              {/*</Typography>*/}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <CustomButton
                  textButton={t("cancel")}
                  customBgColor="inhirit"
                  height={40}
                  width="100%"
                  customColor="#6D6777"
                  customBorderColor="#C9D0D8"
                  customHoverColor="transparent"
                  customBorderRadius="8px"
                  customFontSize="14px"
                  customTextTransform="none"
                  onClick={handleClose}
                />
                <CustomButton
                  loading={isLoading}
                  textButton={t("linkdomain")}
                  height={40}
                  width="100%"
                  customBgColor="#F4701B"
                  customHoverColor="#F4701B"
                  customBorderRadius="8px"
                  customFontSize="14px"
                  customTextTransform="none"
                  onClick={handleLinkDomain}
                  disabled={isLinkDomainDisabled || isLoading}
                />
              </Box>
            </>
          ) : (
            newDomain && (
              <NSInputs
                status={newDomain.status}
                id={newDomain._id}
                nsFirst={newDomain.nsFirst}
                nsSecond={newDomain.nsSecond}
                handleClose={handleCheck}
              />
            )
          )}
        </Box>
      </Modal>
      <AnswerModal open={AnswerCheckModal} setOpen={setAnswerCheckModal} />
    </>
  );
}
