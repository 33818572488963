import { Box, List } from "@mui/material";
import NavItem from "./NavItem";
import NavGroup from "./NavGroup/NavGroup";
import useMenuItems from "./MenuItems";

interface ISidebarItemsProps {
  toggleMobileSidebar: () => void;
}

const SidebarItems = ({ toggleMobileSidebar }: ISidebarItemsProps) => {
  const menuItems = useMenuItems();
  const pathDirect = "pathname";
  return (
    <Box sx={{ px: 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav" component="div">
        {menuItems.map((item: any) => {
          // {/********SubHeader**********/}
          if (item.subheader) {
            return <NavGroup item={item} key={item.subheader} />;

            // {/********If Sub Menu**********/}
            /* eslint no-else-return: "off" */
          } else {
            return (
              <NavItem item={item} key={item.id} pathDirect={pathDirect} toggleMobileSidebar={toggleMobileSidebar} />
            );
          }
        })}
      </List>
    </Box>
  );
};
export default SidebarItems;
