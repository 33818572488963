import { Autocomplete, Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { styled } from "@mui/system";

interface ICustomMultiValueInputProps extends Omit<TextFieldProps, "variant"> {
  tags: string[];
  setTags: (tags: string[]) => void;
  label?: string;
  placeholder?: string;
  height?: string;
  customBgColor?: string;
  customFontSize?: string;
}

const CustomMultiValueInput = ({
  tags,
  setTags,
  label,
  placeholder,
  height,
  customBgColor,
  customFontSize,
}: ICustomMultiValueInputProps) => {
  const StyledTextField = styled(TextField)<{
    customBgColor?: string;
    customFontSize?: string;
    height?: string;
  }>(({ customBgColor, customFontSize, height }) => ({
    "&.MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-root": {
      padding: "0px 16px",
      borderRadius: "8px",
      backgroundColor: customBgColor || "inherit",
      height: height || "auto",
      minHeight: "40px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "input, textarea": {
      padding: "0 16px",
      fontFamily: "var(--cygreRegular), sans-serif",
      fontSize: customFontSize || "14px",
      lineHeight: 1.43,
      textTransform: "none",
      color: "#2E263D",
      border: "none",
      "&::placeholder": {
        fontSize: "14px",
        lineHeight: 1.43,
        fontFamily: "var(--cygreRegular), sans-serif",
        textOverflow: "ellipsis !important",
        color: "#b4b1b9",
        opacity: 1,
      },
    },

    textarea: { padding: 0 },
    "& .MuiButtonBase-root": {
      margin: "0px 4px 0px 0px",
    },

    "& .MuiChip-label": {
      fontFamily: "var(--cygreRegular), sans-serif",
      lineHeight: 1.43,
      color: "#2E263D",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          marginBottom: "10px",
          paddingLeft: "0px",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "12px",
          lineHeight: 1.22,
          textAlign: "left",
          color: "#2E263D",
        }}
      >
        {label}
      </Typography>
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        value={tags}
        onChange={(event, newValue) => setTags(newValue as string[])}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            height={height}
            customBgColor={customBgColor}
            customFontSize={customFontSize}
            placeholder={placeholder}
          />
        )}
      />
    </Box>
  );
};

export default CustomMultiValueInput;
