import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "components/Button";
import { IconButton, Typography } from "@mui/material";
import { useCreativesUplodStore, ICreative } from "store/Creatives";
import TrashIcon from "assets/icons/Trash";
import { AddCircle } from "@mui/icons-material";
import theme from "../../../utils/theme";
import { SortableContainer, SortableElement, SortableHandle, SortEnd } from "react-sortable-hoc";
import { BootstrapTooltip } from "../mainTab/CommentsTable";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "520px",
  maxHeight: "80vh",
};

interface IModalCheckDomain {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function OwnPicture({ open, setOpen }: IModalCheckDomain) {
  const { t } = useTranslation();
  const handleClose = () => {
    setLocalIcon(null);
    setLocalPreviews([]);
    setOpen(false);
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const iconInputRef = useRef<HTMLInputElement>(null);

  const { addCreativeUpload } = useCreativesUplodStore();
  const [localIcon, setLocalIcon] = useState<File | null>(null);
  const [localPreviews, setLocalPreviews] = useState<File[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      if (name === "icon-upload") {
        setLocalIcon(files[0]);
      } else if (name === "preview-upload") {
        setLocalPreviews((prevPreviews) => [...prevPreviews, ...Array.from(files)]);
      }
    }
  };

  const handleAdd = (event: any) => {
    if (event.currentTarget.id === "icon-input" && iconInputRef.current) {
      iconInputRef.current.click();
    } else if (event.currentTarget.id === "icon-preview" && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCopyImage = async () => {
    const newCreative: ICreative = {
      icon: localIcon,
      previews: localPreviews,
    };
    addCreativeUpload(newCreative);
    setLocalIcon(null);
    setLocalPreviews([]);
    setOpen(false);
  };

  const filterLocalImage = (index: number) => {
    const newPreviews = localPreviews.filter((preview, i) => i !== index);
    setLocalPreviews(newPreviews);
  };

  const DragHandle = SortableHandle(({ src, alt }: { src: string; alt: string }) => (
    <Box component="img" sx={{ width: "100px", margin: "0 5px", cursor: "grab" }} src={src} alt={alt} />
  ));

  const SortableItem = SortableElement(({ value, ...rest }: any) => {
    return (
      <Box
        sx={{
          position: "relative",
          zIndex: 5000,
        }}
      >
        <BootstrapTooltip title={t("delete")} placement="top">
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0, transform: "translate(35%, -50%)" }}
            aria-label="delete-icon"
            onClick={(e) => {
              filterLocalImage(value.lastIndex);
            }}
          >
            <DeleteOutlineIcon
              sx={{
                width: "20px",
                height: "20px",
                color: "#dc1d00",
                border: "1px solid #dc1d00",
                backgroundColor: "#FFF",
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </BootstrapTooltip>
        {/*@ts-ignore*/}
        <DragHandle src={URL.createObjectURL(value.file)} alt={`preview-${value.lastIndex}`} />
      </Box>
    );
  });

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "200px",
          backgroundColor: "#F6F7FB",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "center",
          position: "relative",
        }}
      >
        {items.map((value: any, index: any) => {
          const valueWithIndex = {
            file: value,
            lastIndex: index,
          };
          return (
            //@ts-ignore
            <SortableItem key={`item-${index}-${value.name}`} index={index} value={valueWithIndex} />
          );
        })}
      </Box>
    );
  });

  const reOrderPreviews = (sort: SortEnd) => {
    const newPreviews = localPreviews.map((preview, i) => {
      if (i === sort.oldIndex) {
        return sort.newIndex === sort.oldIndex ? preview : localPreviews[sort.newIndex];
      } else if (i === sort.newIndex) {
        return sort.oldIndex === sort.newIndex ? preview : localPreviews[sort.oldIndex];
      } else {
        return preview;
      }
    });
    setLocalPreviews(newPreviews);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "16px",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              textAlign: "left",
              fontSize: "24px",
              lineHeight: 1.22,
              fontFamily: "var(--cygreMedium), sans-serif",
              color: "#2E263D",
            }}
          >
            {t("uploadyourimages")}
          </Typography>
          <Box sx={{ marginTop: "4px", cursor: "pointer" }} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "var(--cygreRegular), sans-serif",
              color: "#2E263D",
            }}
          >
            {t("selectfile")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "24px",
          }}
        >
          {localIcon ? (
            <Box sx={{ position: "relative", width: "104px", height: "104px" }}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  border: "1px dashed #C9D0D8",
                  borderRadius: "8px",
                  backgroundColor: "#F6F7FB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                src={URL.createObjectURL(localIcon)}
                alt="icon"
              />
              <BootstrapTooltip title={t("delete")} placement="top">
                <IconButton
                  sx={{ position: "absolute", top: 0, right: 0, transform: "translate(50%, -50%)" }}
                  aria-label="delete-icon"
                  onClick={() => {
                    setLocalIcon(null);
                  }}
                >
                  <DeleteOutlineIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#dc1d00",
                      border: "1px solid #dc1d00",
                      backgroundColor: "#FFF",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
              </BootstrapTooltip>
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "104px",
                height: "104px",
                border: "1px dashed #C9D0D8",
                borderRadius: "8px",
                backgroundColor: "#F6F7FB",
                textAlign: "center",
                cursor: "pointer",
              }}
              id="icon-input"
              onClick={handleAdd}
            >
              <input
                type="file"
                id="icon-upload"
                name="icon-upload"
                onChange={handleInputChange}
                ref={iconInputRef}
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  display: "none",
                }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "#2E263D",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "var(--cygreRegular), sans-serif",
                }}
              >
                {t("icon")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            paddingTop: "24px",
            position: "relative",
          }}
        >
          <AddCircle
            sx={{
              fill: theme.palette.primary.main,
              width: "50px",
              height: "50px",
              position: "absolute",
              right: 0,
              bottom: 0,
              cursor: "pointer",
              zIndex: 2,
            }}
            id="icon-preview"
            onClick={handleAdd}
          />
          <input
            type="file"
            multiple
            id="preview-upload"
            name="preview-upload"
            onChange={handleInputChange}
            style={{
              display: "none",
            }}
            ref={fileInputRef}
          />
          {localPreviews.length > 0 ? (
            <Box
              sx={{
                border: "1px dashed #C9D0D8",
                borderRadius: "8px",
                backgroundColor: "#F6F7FB",
                overflowX: "auto",
              }}
            >
              <SortableList
                //@ts-ignore
                items={localPreviews}
                onSortEnd={reOrderPreviews}
                axis={"x"}
                useDragHandle={true}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px dashed #C9D0D8",
                borderRadius: "8px",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
                cursor: "pointer",
              }}
              id="icon-preview"
              onClick={handleAdd}
            >
              <Typography
                sx={{
                  margin: "auto",
                  color: "#2E263D",
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "var(--cygreRegular), sans-serif",
                }}
              >
                {t("preview")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          <CustomButton
            textButton={t("cancel")}
            height={40}
            width="100%"
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleClose}
          />
          <Box sx={{ width: "24px" }}></Box>
          <CustomButton
            textButton={t("loadButton")}
            height={40}
            width="100%"
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleCopyImage}
          />
        </Box>
      </Box>
    </Modal>
  );
}
