import Grid from "@mui/material/Grid2";
import NotificationCard from "./NotificationCard";
import { getCountryFlags } from "utils";
import { IAppData, INotificationsListProps, IPushNotification } from "interfaces/push";

const NotificationsList = ({ notifications, countries, getNotificationFetch }: INotificationsListProps) => {
  return (
    <>
      {notifications?.map((notification) => (
        <Grid size={{ xs: 12, lg: 12 / 2, xl: 12 / 3 }} key={notification._id}>
          <NotificationCard
            notification={notification}
            countryFlags={getCountryFlags(notification.geo_filter, countries)}
            icon={notification.icon}
            getNotificationFetch={getNotificationFetch}
          />
        </Grid>
      ))}
    </>
  );
};

export default NotificationsList;
