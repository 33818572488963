import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import CustomInput from "components/Input";
import { useEffect, useState } from "react";
import IconUploader from "components/ProfileAvatarUpdater";
import { useAuthStore } from "store/authStore";
import Grid from "@mui/material/Grid2";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { toast } from "react-toastify";

type ReferralAmount = Record<string, number>;

const ProfilePage = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const token = getToken();
  const [image, setImage] = useState<string | File | null>(null);
  const [referralAmountArr, setReferralAmountArr] = useState<ReferralAmount[]>([]);
  const [isRefLinkCopied, setIsRefLinkCopied] = useState<boolean>(false);
  const userReferralCode = user?.referralCode;
  const referralLink = `https://ducksay-pwa.com/landing?ref=${userReferralCode}`;

  const { triggerFetch } = useFetch({
    url: "transaction/referral",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getReferralTransactions = async () => {
    const { data } = await triggerFetch();
    setReferralAmountArr(data ? data : []);
  };

  const handleCopyReferralLink = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setIsRefLinkCopied(true);
      toast.success(t("successfulRefLinkCopy"));
    } catch (err) {
      toast.error(t("unsuccessRefLinkCopy"));
    }
  };

  useEffect(() => {
    void getReferralTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ maxWidth: "360px" }}>
      <Typography
        sx={{
          marginTop: { xs: "0px", md: "12px" },
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "20px",
          fontWeight: 500,
          color: "#2E263D",
        }}
      >
        {t("profile")}
      </Typography>
      <Typography sx={{ color: "#6D6777", fontFamily: "var(--cygreRegular), sans-serif", fontSize: "10px" }}>
        ID: {user?.userId}
      </Typography>
      <Box sx={{ padding: "24px 0", display: "flex", alignItems: "center" }}>
        <IconUploader image={image} onImageChange={setImage} userIcon={user?.icon || ""} />
        {!image && !user?.icon && (
          <Typography sx={{ color: "#6D6777", fontSize: "12px", width: "150px" }}>
            Allowed .jpeg, .jpg, .png, .gif max size of 3 Mb
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", marginBottom: "24px" }}>
        <CustomInput
          required
          fullWidth
          id="email"
          height="40px"
          name="email"
          label={t("email")}
          value={user?.email || ""}
          isReadOnly
          customBgColor="#F6F7F9"
        />
        <CustomInput
          required
          fullWidth
          id="telegram"
          height="40px"
          name="telegram"
          label="Telegram"
          value={user?.telegram || ""}
          isReadOnly
          customBgColor="#F6F7F9"
        />
      </Box>
      <Grid container spacing={1} sx={{ alignItems: "flex-end", marginBottom: "10px" }}>
        <Grid size={10.5}>
          <CustomInput
            fullWidth
            id="referalLink"
            height="40px"
            name="referralLink"
            label={t("referralLink")}
            value={userReferralCode ? referralLink : ""}
            customBgColor="#F6F7F9"
            isReadOnly={true}
          />
        </Grid>
        <Grid size={1.5} sx={{ paddingBottom: "8px" }}>
          <IconButton
            disabled={isRefLinkCopied || !userReferralCode}
            onClick={() => {
              handleCopyReferralLink();
            }}
          >
            <BootstrapTooltip title={t("сopy")} placement="top">
              <ContentCopyIcon />
            </BootstrapTooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Grid sx={{ marginBottom: "20px" }}>
        <Typography sx={{ paddingLeft: "5px", fontSize: "12px", marginBottom: "8px" }}>
          {t("referralDescriptionTitle")}
        </Typography>
        <List
          dense
          component="ol"
          disablePadding
          sx={{
            listStyleType: "disc",
            pl: 4,
          }}
        >
          <ListItem component="li" sx={{ fontSize: "12px", display: "list-item" }} disableGutters>
            {t("referralDepositDescription")}
          </ListItem>
          <ListItem component="li" sx={{ fontSize: "12px", display: "list-item" }} disableGutters>
            {t("referralInstallDescription")}
          </ListItem>
        </List>
      </Grid>

      {referralAmountArr.length !== 0 && (
        <Grid
          container
          spacing={1}
          sx={{ alignItems: "flex-end", padding: "14px", border: "1px solid #EEEEEE", borderRadius: "8px" }}
        >
          <Grid size={12} sx={{ marginBottom: "8px" }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "12px",
                fontWeight: "400",
                color: "#2E263D",
              }}
            >
              {t("referralListTitle")}
            </Typography>
          </Grid>
          <Grid container spacing={1} sx={{ maxHeight: "302px", overflowY: "auto", paddingRight: "6px" }}>
            {referralAmountArr.map((referral) => {
              const referralName = Object.keys(referral);
              const referralAmount = Object.values(referral);
              return (
                <>
                  <Grid size={9}>
                    <CustomInput
                      sx={{ margin: 0, input: { fontSize: "12px" } }}
                      fullWidth
                      id="referralName"
                      minHeight="30px"
                      height="30px"
                      name="referralName"
                      value={referralName}
                      customBgColor="#F6F7F9"
                      isReadOnly={true}
                    />
                  </Grid>
                  <Grid size={3}>
                    <CustomInput
                      sx={{ margin: 0, input: { fontSize: "12px", color: "#56CA00", textAlign: "center" } }}
                      fullWidth
                      id="referralAmount"
                      minHeight="30px"
                      height="30px"
                      name="referralAmount"
                      value={`$${Number(referralAmount).toFixed(2)}`}
                      customBgColor="#F6F7F9"
                      isReadOnly={true}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ProfilePage;
