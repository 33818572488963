import { useTranslation } from "react-i18next";
import { Box, List, ListItem, Typography } from "@mui/material";
import CustomInput from "components/Input";
import { IListedDomain } from "interfaces/domains";
import { formatDateDDMMYYYY } from "utils/formateDate";

interface IDomainsListProps {
  domains: IListedDomain[];
}

const UserBoughtDomainsList = ({ domains }: IDomainsListProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginTop: "32px",
        padding: "14px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <Typography
        component="h3"
        sx={{
          marginBottom: "12px",
          fontFamily: "var(--cygreMedium), sans-serif",
          fontSize: "12px",
          fontWeight: "400",
          color: "#2E263D",
        }}
      >
        {t("domainsListTitle")}
      </Typography>

      <List
        dense
        sx={{
          "&.MuiList-root": { display: "flex", flexDirection: "column", gap: "12px", padding: 0 },
        }}
      >
        {domains.map((domain) => {
          const { _id: domainId, name, expiredAt } = domain;

          return (
            <ListItem key={domainId} sx={{ display: "flex", gap: "4px" }} disablePadding>
              <CustomInput
                sx={{ margin: 0 }}
                id="domainName"
                fullWidth
                minHeight="34px"
                height="34px"
                name="domainName"
                value={name}
                customBgColor="#F6F7F9"
                customFontSize="14px"
                isReadOnly={true}
              />
              <CustomInput
                sx={{ margin: 0, input: { color: "#F4701B", textAlign: "center" } }}
                id="domainExpiredDate"
                customWidth="auto"
                minHeight="34px"
                height="34px"
                name="domainExpiredDate"
                value={formatDateDDMMYYYY(expiredAt)}
                customBgColor="#F6F7F9"
                customFontSize="14px"
                isReadOnly={true}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default UserBoughtDomainsList;
