import { DefaultizedPieValueType, pieArcLabelClasses, PieChart } from "@mui/x-charts";
import Grid from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useMediaByValue from "hooks/useMediaByValue";

interface IAdminStatsPieChartProps {
  statsData: {
    deposits: any;
    installs: any;
    referrals: any;
    promocodes: any;
  };
}

const AdminStatsPieChart = ({ statsData }: IAdminStatsPieChartProps) => {
  const { t } = useTranslation();
  const { deposits, referrals, promocodes } = statsData;
  const isLargeViewPort = useMediaByValue("(min-width:1120px)");

  const pieChartData = [
    { id: 0, value: Number(promocodes), color: "#5119B7", label: t("promocodes") },
    { id: 1, value: Number(referrals), color: "#FFAB00", label: t("referralBonuses") },
    { id: 2, value: Number(deposits), color: "#56CA00", label: t("deposits") },
  ];

  const pieEmptyChartData = [{ id: 0, value: 100, color: "#16B1FF" }];

  const totalAmount = pieChartData.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalAmount;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const getEmptyArcLabel = () => {
    return t("noData");
  };

  const isEmptyPieData = pieChartData.every((item) => item.value === 0);
  const isOnlyOnePieChartValue = pieChartData.filter((item) => item.value > 0).length === 1;

  return (
    <Grid
      size={isLargeViewPort ? 4 : 12}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "24px",
        borderRadius: "8px",
        boxShadow:
          "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
      }}
    >
      <PieChart
        series={[
          {
            arcLabel: isEmptyPieData ? getEmptyArcLabel : getArcLabel,
            arcLabelMinAngle: 35,
            arcLabelRadius: "140%",
            data: isEmptyPieData ? pieEmptyChartData : pieChartData,
            outerRadius: 100,
            cx: 95,
            innerRadius: 25,
            paddingAngle: isOnlyOnePieChartValue ? 0 : 3,
            cornerRadius: 5,
            highlightScope: { fade: "global", highlight: "item" },
            faded: { innerRadius: 30, additionalRadius: -15, color: "gray" },
          },
        ]}
        width={200}
        height={200}
        slotProps={{
          legend: {
            hidden: true,
          },
          popper: {
            hidden: isEmptyPieData,
          },
        }}
        sx={{
          marginBottom: "40px",
          [`& .${pieArcLabelClasses.root}`]: {
            fontSize: "12px",
            fill: "#FFFFFF",
          },
        }}
      />
      <Divider sx={{ width: "100%", borderStyle: "dashed" }} />

      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "12px", padding: "24px 0" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              background: "#56CA00",
            }}
          />
          <Typography sx={{ fontSize: "14px" }}>{t("deposits")}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              background: "#5119B7",
            }}
          />
          <Typography sx={{ fontSize: "14px" }}>{t("promocodes")}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              background: "#FFAB00",
            }}
          />
          <Typography sx={{ fontSize: "14px" }}>{t("referralBonuses")}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default AdminStatsPieChart;
