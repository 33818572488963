import { useState } from "react";
import { usePromocodesStore } from "store/promocodesStore";
import { Box, styled, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Calendar from "assets/icons/Calendar";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { t } from "i18next";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",
  padding: "0",

  "& .MuiIconButton-root": {
    paddingRight: "15px",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#F6F7FB",
    borderRadius: 8,
    width: "100%",
    height: 40,
    marginRight: "8px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    height: "40px",
    fontWeight: "400",
    fontFamily: "var(--cygreMedium), sans-serif",
  },
}));

export default function PromocodeDataPicker() {
  const { promocodeData, savePromocodeData } = usePromocodesStore();
  const { expiredAt } = promocodeData;

  const handleDateSave = (newDate: Dayjs | null) => {
    if (newDate) {
      const standartDate = newDate.format();
      savePromocodeData({ expiredAt: standartDate });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "12px",
          paddingLeft: "5px",
          paddingBottom: "4px",
          fontFamily: "var(--cygreRegular), sans-serif",
        }}
      >
        {t("expirationdate")}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDatePicker
          slots={{
            openPickerIcon: Calendar,
          }}
          onChange={(newDate) => handleDateSave(newDate)}
          value={expiredAt ? dayjs(expiredAt) : null}
          format="DD.MM.YYYY"
          slotProps={{
            textField: {
              inputProps: {
                placeholder: "DD.MM.YYYY",
                disabled: true,
              },
            },
          }}
          minDate={dayjs()}
        />
      </LocalizationProvider>
    </Box>
  );
}
