import CustomTable, { IColumn } from "components/CustomTable";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatTime } from "utils/formateDate";
import showPreviewIcon from "utils/showPreviewImage";
import { IPwaStatisticsItem } from "pages/DashboardPage";

interface IAccountsTable {
  statistics: IPwaStatisticsItem[];
}

export default function AccountsTable({ statistics }: IAccountsTable) {
  const filtredStatistics = statistics.filter((statsItem) => {
    return statsItem.name !== "";
  });

  const { t } = useTranslation();
  const columns: IColumn[] = [
    {
      id: "name",
      label: "PWA",
      align: "left",
      width: 264,
      render: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <img
            src={showPreviewIcon(row.icon)}
            alt="pwa-icon"
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              marginRight: "12px",
            }}
          />
          <Typography
            sx={{
              maxWidth: "170px",
              paddingRight: "10px",
              fontSize: 14,
              fontFamily: "var(--cygreMedium), sans-serif",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: "updatedAt",
      label: t("dateofwriteOff"),
      align: "left",
      width: 160,
      // format: formatTime,
      render: (row: any) => (
        <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "14px", whiteSpace: "nowrap" }}>
          {formatTime(row.updatedAt)}
        </Typography>
      ),
    },
    {
      id: "installCount",
      label: t("installs"),
      align: "center",
      width: 72,
    },
    {
      id: "totalAmount",
      label: t("ammount"),
      align: "left",
      width: 104,
      render: (row: any) => (
        <Typography
          sx={{ color: "red", fontFamily: "var(--cygreMedium), sans-serif", fontSize: "14px", whiteSpace: "nowrap" }}
        >
          {`— ${row.totalAmount.toFixed(3)}`}
        </Typography>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "640px",
        padding: "24px 20px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          marginBottom: "24px",
          fontSize: "16px",
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
        }}
      >
        {t("accounts")}
      </Typography>

      {filtredStatistics.length > 0 ? (
        <CustomTable columns={columns} rows={filtredStatistics} />
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "var(--cygreMedium), sans-serif",
            fontSize: "16px",
            lineHeight: "32px",
            color: "#2E263D",
          }}
        >
          {t("noitems")}
        </Typography>
      )}
    </Box>
  );
}
