import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@mui/material";
import { SortableContainer, SortableElement, SortableHandle, SortEnd } from "react-sortable-hoc";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface IPreveiwsListProps {
  fileInputRef: any;
  localPreviews: (File | string)[];
  handleImagesAdd: (event: any) => void;
  reOrderPreviews: (sort: SortEnd) => void;
  handleInputChange: (event: any) => void;
  handlePreviewDelete: ({ preview, index }: { preview: string | File; index: number }) => void;
}

const PreveiwsList = ({
  localPreviews,
  handleImagesAdd,
  reOrderPreviews,
  fileInputRef,
  handleInputChange,
  handlePreviewDelete,
}: IPreveiwsListProps) => {
  const { t } = useTranslation();
  const DragHandle = SortableHandle(({ src, alt }: { src: string; alt: string }) => (
    <Box
      component="img"
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        display: "block",
        borderRadius: "8px",
        cursor: "grab",
      }}
      src={src}
      alt={alt}
    />
  ));

  const SortableItem = SortableElement(({ value, ...rest }: any) => {
    return (
      <Box
        sx={{
          position: "relative",
          height: "170px",
          zIndex: 5000,
        }}
      >
        <BootstrapTooltip title={t("delete")} placement="top">
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0, transform: "translate(40%, -50%)" }}
            aria-label="delete-icon"
            onClick={() => handlePreviewDelete({ preview: value.file, index: value.lastIndex })}
          >
            <DeleteOutlineIcon
              sx={{
                width: "20px",
                height: "20px",
                color: "#dc1d00",
                border: "1px solid #dc1d00",
                backgroundColor: "#FFF",
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </BootstrapTooltip>

        <DragHandle
          /*@ts-ignore*/
          src={typeof value.file === "string" ? value.file : URL.createObjectURL(value.file)}
          alt={`preview-${value.lastIndex}`}
        />
      </Box>
    );
  });

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "200px",
          padding: "6px",
          backgroundColor: "#F6F7FB",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "5px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <BootstrapTooltip title={t("add")} placement="top">
          <IconButton
            sx={{ position: "absolute", bottom: 0, right: 0, zIndex: 5001 }}
            aria-label="add-icon"
            id="icon-preview"
            onClick={handleImagesAdd}
          >
            <AddCircleOutlineIcon
              sx={{
                width: "36px",
                height: "36px",
                color: "#F4701B",
              }}
            />
          </IconButton>
        </BootstrapTooltip>
        {items.map((value: any, index: any) => {
          const valueWithIndex = {
            file: value,
            lastIndex: index,
          };
          return (
            //@ts-ignore
            <SortableItem key={`item-${index}-${value.name}`} index={index} value={valueWithIndex} />
          );
        })}
      </Box>
    );
  });

  return (
    <>
      <input
        type="file"
        multiple
        id="preview-upload"
        name="preview-upload"
        onChange={handleInputChange}
        style={{
          display: "none",
        }}
        ref={fileInputRef}
      />
      {localPreviews.length > 0 ? (
        <Box
          sx={{
            border: "1px dashed #C9D0D8",
            borderRadius: "8px",
            backgroundColor: "#F6F7FB",
            overflowX: "auto",
          }}
        >
          <SortableList
            //@ts-ignore
            items={localPreviews}
            onSortEnd={reOrderPreviews}
            axis={"x"}
            useDragHandle={true}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "200px",
            border: "1px dashed #C9D0D8",
            borderRadius: "8px",
            backgroundColor: "#F6F7FB",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
            cursor: "pointer",
          }}
          id="icon-preview"
          onClick={handleImagesAdd}
        >
          <Typography
            sx={{
              margin: "auto",
              color: "#2E263D",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "var(--cygreRegular), sans-serif",
            }}
          >
            {t("preview")}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PreveiwsList;
