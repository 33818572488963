import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import useMediaByValue from "hooks/useMediaByValue";
import useMenuData from "mokcup/menuData";

interface IFilterSubItem {
  title: string;
  id: string;
  code: string;
}

interface IFilterItem {
  title: string;
  codeTop: string;
  subItems?: IFilterSubItem[];
}

interface IFilterState {
  [key: string]: string[];
}

interface IFilterPwaProps {
  filter: IFilterState;
  setFilter: React.Dispatch<React.SetStateAction<IFilterState>>;
}

const FilterPwa = ({ filter, setFilter }: IFilterPwaProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState<string>("");
  const [tempFilter, setTempFilter] = useState<IFilterState>(filter);
  const menuData: IFilterItem[] = useMenuData();
  const isDesktop = useMediaByValue("(min-width:1170px)");
  const isMenuOpen = Boolean(anchorEl);
  const isSubMenuOpen = Boolean(hoveredMenuItem);

  const currentSubMenuDate = menuData.find((item) => item.codeTop === hoveredMenuItem)?.subItems || [];

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
    setHoveredMenuItem("");
  };

  const handleMenuItemHover = (event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget.parentElement);
    setHoveredMenuItem(event.currentTarget.id);
  };

  const handleFilterChange = (param: Record<string, string>) => {
    const key = Object.keys(param)?.[0];

    setTempFilter((prev) => {
      const newParam = prev[key];
      const saveParam = newParam.includes(param[key])
        ? newParam?.filter((p) => p !== param[key])
        : [...newParam, param[key]];

      return { ...prev, [key]: saveParam };
    });
  };

  const handleConfirmFilter = () => {
    setFilter(tempFilter);
    handleMenuClose();
  };

  const clearFilter = () => {
    const clearedFilter = Object.keys(tempFilter).reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {} as IFilterState);

    setTempFilter(clearedFilter);
    setFilter(clearedFilter);
    handleMenuClose();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        variant="contained"
        sx={{
          width: { xs: "100%", sm: "114px" },
          height: 40,
          fontFamily: "var(--cygreRegular), sans-serif",
          color: "#6D6777",
          backgroundColor: "#F6F7FB",
          boxShadow: "none",
          borderRadius: "8px",
          "& .MuiButton-icon": { marginRight: "4px" },
          "&:hover": {
            backgroundColor: alpha("#F4701B", 0.1),
            color: "#F4701B",
            "& .MuiSvgIcon-root": {
              color: "#F4701B",
            },
          },
        }}
        startIcon={<TuneIcon sx={{ width: "20px", height: "20px", color: "#6D6777" }} />}
        onClick={handleMenuClick}
      >
        {t("filter")}
      </Button>
      <Menu
        id="pwa-filter-menu"
        aria-labelledby="pwa-filter-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          marginTop: "8px",
          "& .MuiPaper-root": { borderRadius: "8px" },
          "& .MuiList-root": { display: "flex", flexDirection: "column", gap: "4px", width: "264px", padding: "0px" },
          "& .MuiMenuItem-root:first-child": { marginTop: "8px" },
          "& .MuiMenuItem-root:nth-child(3)": { marginBottom: "4px" },
        }}
      >
        {menuData.map(({ title, codeTop, subItems }) => {
          return (
            <>
              <MenuItem
                id={codeTop}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0px 8px",
                  padding: "8px 12px",
                  fontSize: "16px",
                  color: "#2e263d",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "rgba(51, 51, 51, 0.05)",
                  },
                }}
                onMouseEnter={handleMenuItemHover}
              >
                {title}
                <ArrowRightIcon sx={{ width: "24px", height: "24px", color: "#6d6777" }} />
              </MenuItem>
            </>
          );
        })}
        <MenuItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            backgroundColor: "#f6f7fb",
            "&:hover": {
              backgroundColor: "#f6f7fb",
            },
            borderTop: "1px solid #eee",
          }}
        >
          <Button
            color="inherit"
            variant="text"
            sx={{
              padding: "10px 12px",
              fontFamily: "var(--cygreMedium), sans-serif",
              fontSize: "16px",
              lineHeight: 1,
              cursor: "pointer",
              color: "#b4b1b9",
              borderRadius: "8px",
              textTransform: "initial",
              "&:hover": {
                color: "#f0670b",
              },
            }}
            onClick={clearFilter}
          >
            {t("clear")}
          </Button>
          <Button
            color="inherit"
            variant="text"
            sx={{
              padding: "10px 12px",
              fontFamily: "var(--cygreMedium), sans-serif",
              fontSize: "16px",
              lineHeight: 1,
              cursor: "pointer",
              borderRadius: "8px",
              textTransform: "initial",
              color: "#F4701B",
              "&:hover": {
                color: "#f0670b",
              },
            }}
            onClick={handleConfirmFilter}
          >
            {t("apply")}
          </Button>
        </MenuItem>
      </Menu>
      <Popper
        id="pwa-filter-submenu"
        open={isSubMenuOpen}
        anchorEl={submenuAnchorEl}
        placement={isDesktop ? "right-start" : "bottom-start"}
        sx={{ zIndex: 2000 }}
      >
        <Paper sx={{ width: "264px", margin: isDesktop ? "0px 0px 0px 8px" : "4px 0px 0px 0px", padding: "8px" }}>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              maxHeight: "256px",
              padding: "0px",
              overflowY: "auto",
              bgcolor: "background.paper",
            }}
          >
            {currentSubMenuDate.map((value) => {
              return (
                <ListItem key={value.id} disablePadding>
                  <ListItemButton
                    sx={{ padding: "0px 12px 0px 0px" }}
                    role={undefined}
                    onClick={() => handleFilterChange({ [hoveredMenuItem]: value.code })}
                    dense
                  >
                    <ListItemIcon sx={{ minWidth: "40px", height: "40px" }}>
                      <Checkbox
                        checked={tempFilter[hoveredMenuItem]?.includes(value?.code)}
                        tabIndex={-1}
                        disableRipple
                        sx={{
                          input: { height: "40px" },
                          "&.Mui-checked svg": { fill: "#F4701B" },
                          "&:not(.Mui-checked) svg": { fill: "#C9D0D8" },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText id={value.id} primary={value.title} sx={{ fontSize: "16px", color: "#2e263d" }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Popper>
    </Box>
  );
};

export default FilterPwa;
