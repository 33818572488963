import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageIcon from "assets/icons/ImageIcon";
import { t } from "i18next";
import { IStatsCardProps } from "interfaces/stats";
import { formatDateByLocale } from "utils/formateDate";
import showPreviewIcon from "utils/showPreviewImage";

const StatsCard = ({ statsCardData }: IStatsCardProps) => {
  const styleText = {
    fontSize: "14px",
    fontFamily: "var(--cygreMedium), sans-serif",
  };

  return (
    <>
      {statsCardData?.map((statsData, index) => {
        const formattedDate = formatDateByLocale(statsData?.day);
        const storeClick = statsData?.storeStats?.clicks || 0;
        const storeUniqueClick = statsData?.storeStats?.campaign_unique_clicks || 0;
        const crInstalPercentage = statsData.installs
          ? ((storeUniqueClick / statsData.installs) * 100).toFixed(2) + "%"
          : "0.00%";
        const crPushPercentage = statsData.push_subs
          ? ((storeUniqueClick / statsData.push_subs) * 100).toFixed(2) + "%"
          : "0.00%";
        const crInstalPushPercentage =
          statsData.installs && statsData.push_subs
            ? ((statsData.installs / statsData.push_subs) * 100).toFixed(2) + "%"
            : "0.00%";
        const reopenings = statsData.clicks - statsData.campaign_unique_clicks || 0;
        return (
          <Grid key={index} size={{ xs: 12, lg: 12 / 2, xl: 12 / 3 }}>
            <Card
              tabIndex={-1}
              sx={{
                borderRadius: "11 px",
                boxShadow: "0px 0px 4px 0px rgba(165, 163, 174, 0.3)",
                height: "100%",
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  boxShadow: "none",
                }}
              >
                {statsData.statsType === "global" ? (
                  <Box
                    sx={{
                      borderBottom: "2px solid #EEEEEE",
                      padding: "20px",
                      fontFamily: "var(--cygreMedium), sans-serif",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {statsData.icon ? (
                      <img
                        src={showPreviewIcon(statsData.icon)}
                        alt={`${statsData.campaign} icon`}
                        style={{ width: "30px", height: "30px", marginRight: "10px", borderRadius: "50%" }}
                      />
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 36,
                          height: 36,
                          marginRight: "5px",
                          borderRadius: "50%",
                        }}
                      >
                        <ImageIcon />
                      </Box>
                    )}

                    {statsData.campaign}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      padding: "20px 16px",
                      borderBottom: "2px solid #EEEEEE",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--cygreMedium), sans-serif",
                        fontWeight: "500",
                      }}
                    >
                      {formattedDate}
                    </Typography>
                  </Box>
                )}

                <Grid container sx={{ borderBottom: "2px solid #EEEEEE", padding: "8px 16px" }}>
                  <Grid size={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("clicks")}:</Typography>
                      <Typography sx={{ ...styleText }}>{storeClick}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("installs")}:</Typography>
                      <Typography sx={{ ...styleText }}>{statsData.installs | 0}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("uniqueClicks")}:</Typography>
                      <Typography sx={{ ...styleText }}>{storeUniqueClick}</Typography>
                    </Box>
                  </Grid>

                  <Grid size={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("regs")}:</Typography>
                      <Typography sx={{ ...styleText }}>{statsData.regs | 0}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("unique")}:</Typography>
                      <Typography sx={{ ...styleText }}>{statsData.campaign_unique_clicks | 0}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("deps")}:</Typography>
                      <Typography sx={{ ...styleText }}>{statsData.deposits | 0}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container sx={{ padding: "8px 16px 0px 16px" }}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("crInst")}:</Typography>
                      <Typography sx={{ ...styleText }}>{crInstalPercentage}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push")}:</Typography>
                      <Typography sx={{ ...styleText }}>{statsData.push_subs | 0}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("crPush")}:</Typography>
                      <Typography sx={{ ...styleText }}>{crPushPercentage}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("reopen")}:</Typography>
                      <Typography sx={{ ...styleText }}>{reopenings | 0}</Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("crInstPush")}:</Typography>
                      <Typography sx={{ ...styleText }}>{crInstalPushPercentage}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default StatsCard;
