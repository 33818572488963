import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styled from "@emotion/styled";
import useMediaByValue from "hooks/useMediaByValue";

const useSharePopup = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [shareLink, setShareLink] = useState<string>("");
  const [copied, setCopied] = useState<boolean>(false);

  const handleOpenPopup = (link: string) => {
    setShareLink(link);
    setOpenPopup(true);
  };

  const closePopup = () => {
    setOpenPopup(false);
    setCopied(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
  };

  return {
    // eslint-disable-next-line no-restricted-globals
    open,
    shareLink,
    copied,
    setCopied,
    openPopup,
    closePopup,
    handleCopy,
    handleOpenPopup,
  };
};

interface SharePopupProps {
  open: boolean;
  onClose: () => void;
  shareLink: string;
  copied: boolean;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  handleCopy: () => void;
}

const SharePopup: React.FC<SharePopupProps> = ({ open, onClose, shareLink, copied, setCopied, handleCopy }) => {
  const { t } = useTranslation();
  const isTablet = useMediaByValue("(min-width:768px)");
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose();
    setCopied(false);
  };

  const TextFieldStyled = styled(TextField)(() => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "40px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    border: "none",

    "& .MuiInputBase-input": {
      fontFamily: "var(--cygreRegular), sans-serif",
      fontSize: "14px",
      color: "#2e263d",
      padding: "0px 16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  }));

  const ButtonStyled = styled(Button)(() => ({
    backgroundColor: "#F4701B",
    minWidth: "160px",
    width: isTablet ? "160px" : "100%",
    height: "40px",
    fontSize: "16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#F0670B",
    },
  }));

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          minWidth: { xs: "auto", md: "632px" },
          width: { xs: "100%", md: "632px" },
          padding: { xs: "16px 24px", md: "24px 24px" },
          margin: "0px 16px",
          borderRadius: "8px",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: { xs: "24px", md: "32px" },
        }}
      >
        <DialogTitle sx={{ padding: 0, fontSize: "24px", fontWeight: 500, color: "#2e263d" }}>
          {isTablet ? t("sharepwaLong") : t("sharepwa")}
        </DialogTitle>
        <DialogActions sx={{ padding: 0 }}>
          <Button sx={{ minWidth: "24px", padding: 0 }} onClick={handleClose}>
            <CloseOutlinedIcon
              sx={{ width: { xs: "24px", md: "32px" }, height: { xs: "24px", md: "32px" }, color: "#2E263D" }}
            />
          </Button>
        </DialogActions>
      </Box>
      <DialogContent
        sx={{
          padding: { xs: "12px", md: "24px 16px" },
          backgroundColor: "rgba(51, 51, 51, 0.05)",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "8px", marginBottom: "8px" }}>
          <TextFieldStyled
            value={shareLink}
            slotProps={{
              input: {
                readOnly: true,
              },
            }}
            fullWidth
          />
          <ButtonStyled
            variant="contained"
            aria-label="copy button"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              return handleCopy();
            }}
          >
            {t("copyLink")}
          </ButtonStyled>
        </Box>
        {copied && (
          <Typography sx={{ fontSize: "14px", paddingLeft: "16px" }} color="primary">
            {t("copiedToClipboard")}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { useSharePopup, SharePopup };
