import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import useCautionStore from "store/cautionStore";
import { useTranslation } from "react-i18next";
import { IPromocode } from "interfaces/promocode";
import { Box, Fab, TablePagination, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AdminPromocodesTab from "components/AdminPromocodesTab";
import PromocodeCreationForm from "components/AdminPromocodeCreateSection";
import { toast } from "react-toastify";
import CautionModal from "components/CautionModal";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

const PromocodesPage = () => {
  const token = getToken();
  const [promocodes, setPromocodes] = useState<Array<IPromocode>>([]);
  const [page, setPage] = useState(0);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [isNewPromocodeCreated, setIsNewPromocodeCreated] = useState(false);
  const [statusCollapsSection, setstatusCollapsSection] = useState(false);
  const rowsPerPage = 20;
  const { isPromocodeDeleteModalOpen, promocodeToDelete, handleDeletePromocodeChange, closeModal } = useCautionStore();
  const { t } = useTranslation();
  const currentPromocodesRows = promocodes.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const { triggerFetch: getPromocodes } = useFetch({
    url: "admin/promocodes",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading, triggerFetch: deletePromocode } = useFetch({
    url: `admin/promocodes/${promocodeToDelete}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getAllPromocodes = async () => {
    const { promocodes } = await getPromocodes();
    setPromocodes(promocodes ? promocodes.reverse() : []);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handlePromocodeDelete = async () => {
    try {
      await deletePromocode();
      setPromocodes((prevPromocodes) => {
        const updatedPromocodes = prevPromocodes.filter((promocode) => promocode._id !== promocodeToDelete);
        if (currentPromocodesRows.length === 1 && page > 0) {
          setPage(page - 1);
        }
        return updatedPromocodes;
      });
      handleDeletePromocodeChange(null);
      closeModal();
      toast.success(t("successfulPromocodeDeletion"));
    } catch {
      toast.error(t("unsuccessfulPromocodeDeletion"));
    }
  };

  useEffect(() => {
    getAllPromocodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isStatusChanged && !isNewPromocodeCreated) {
      return;
    }
    getAllPromocodes();
    setIsStatusChanged(false);
    setIsNewPromocodeCreated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusChanged, isNewPromocodeCreated]);

  return (
    <Grid
      size={12}
      sx={{
        overflowX: "auto",
      }}
    >
      <Box sx={{ display: "flex", gap: "6px", alignItems: "center", marginBottom: "10px" }}>
        <CardGiftcardIcon sx={{ width: "36px", height: "36px", color: "#6D6777" }} />
        <Typography
          sx={{
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            color: "#2E263D",
          }}
        >
          {t("promocodes")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={promocodes.length}
          rowsPerPage={20}
          page={page}
          labelRowsPerPage={null}
          labelDisplayedRows={() => ""}
          onPageChange={handleChangePage}
          sx={{
            "& .MuiTablePagination-actions": { margin: "0px !important" },
            "& .MuiTablePagination-spacer": {
              display: "none",
            },
            "& .MuiTablePagination-toolbar": {
              padding: 0,
              justifyContent: "flex-start",
            },
          }}
        />
        <Fab
          color="primary"
          aria-label="add"
          sx={{ zIndex: 1, width: "36px", height: "36px", minHeight: "30px", marginRight: "20px" }}
          onClick={() => setstatusCollapsSection(!statusCollapsSection)}
        >
          {!statusCollapsSection ? <AddIcon /> : <CloseIcon />}
        </Fab>
      </Box>
      <PromocodeCreationForm
        isPromocodeFormOpen={statusCollapsSection}
        handleIsNewPromocode={setIsNewPromocodeCreated}
      />
      <AdminPromocodesTab
        totalPromocodes={promocodes.length}
        promocodesArr={currentPromocodesRows}
        page={page}
        handleChangePage={handleChangePage}
        handleStatus={setIsStatusChanged}
      />
      {isPromocodeDeleteModalOpen && <CautionModal handleConfirm={handlePromocodeDelete} isLoading={loading} />}
    </Grid>
  );
};

export default PromocodesPage;
