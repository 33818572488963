import { useMemo, useEffect } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import MapCountryTable from "./MapCountryTable";
import { world_map } from "./world-map";
import { getCountryWithCenterData } from "utils/mapsHelper";
import Phone from "assets/icons/Phone";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import { fetchCountries } from "../../utils/fetchCountries";
import { getToken } from "utils/getToken";
import { darkenColor, randomizeColor } from "../../utils/randomizeColor";

const geoUrl = world_map;

type DataItem = {
  totalTransactions: number;
  pwaId: string;
  installGeo: string;
  totalAmount: number;
  appName: string;
  icon: string;
};

type GroupedData = {
  [installGeo: string]: DataItem[];
};

function groupByInstallGeo(data: DataItem[]): GroupedData {
  return data.reduce((acc: GroupedData, item: DataItem) => {
    const { installGeo } = item;
    if (!acc[installGeo]) {
      acc[installGeo] = [];
    }
    acc[installGeo].push(item);
    return acc;
  }, {});
}

export default function StatsMap() {
  const { t } = useTranslation();
  const token = getToken();
  const { triggerFetch: geoStats, data } = useFetch({
    url: "statistics/bypwageo",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const countries = fetchCountries();

  useEffect(() => {
    void geoStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtredData = data?.data.filter((item: DataItem) => {
    return item.appName;
  });

  const groupedData = groupByInstallGeo(filtredData || []);
  const markers = useMemo(() => getCountryWithCenterData(groupedData, world_map, countries), [groupedData]);

  const tableRows = Object.keys(groupedData)
    .map((key) => {
      const country = countries.find((country) => country.code === key);
      const totalinstalls = groupedData[key].reduce((acc, item) => acc + item.totalTransactions, 0);
      return {
        country: country?.name || "",
        totalinstalls: totalinstalls,
      };
    })
    .filter((row) => {
      return row.country !== "";
    });

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "24px",
        padding: { xs: "20px 16px 16px 16px", lg: "20px 20px 24px 20px" },
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          marginBottom: "16px",
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "16px",
          lineHeight: "32px",
        }}
      >
        {t("trafficMap")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", xl: "row" },
          justifyContent: "space-between",
          gap: { xs: "16px", xl: "0px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", xl: "65%" },
          }}
        >
          <ComposableMap projectionConfig={{ center: [12, 8], rotate: [-1, 7, 0], scale: 160 }} height={400}>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const countryDetails = countries.find((country) => country.name === geo.properties.name) || {
                    code: "",
                    name: "",
                    flag: "",
                  };
                  // const countryValue = getCountryValue(geo.properties.name);
                  const groupedCountryData = groupedData[countryDetails?.code || ""];
                  const baseColor = randomizeColor("#8C57FF");
                  const hoverColor = darkenColor(baseColor, 10); // Darkens the color by reducing lightness by 10%

                  return (
                    <Tooltip
                      key={geo.rsmKey}
                      title={
                        groupedCountryData ? (
                          <Box sx={{ width: "220px", color: "white" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 24,
                                  }}
                                >
                                  {countryDetails.flag}
                                </span>
                                <Typography
                                  sx={{
                                    paddingLeft: "10px",
                                    fontSize: "14px",
                                    fontFamily: "var(--cygreMedium), sans-serif",
                                  }}
                                >
                                  {countryDetails.name}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Phone color="#fff" />
                                <Typography
                                  sx={{
                                    fontFamily: "var(--cygreMedium), sans-serif",
                                    paddingLeft: "10px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {groupedCountryData.length} PWA
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "236px",
                                background: "#5c5959",
                                marginLeft: "-8px",
                                padding: "5px",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "16px" }}>
                                {t("name")}
                              </Typography>
                              <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "16px" }}>
                                {t("install")}
                              </Typography>
                            </Box>
                            <Box>
                              {groupedCountryData.map((log: any) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #848484FF",
                                    padding: "3px 0",
                                    ":last-child": {
                                      border: "none",
                                    },
                                  }}
                                  key={log.appName}
                                >
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img
                                      style={{ width: "28px", height: "28px", borderRadius: "50%" }}
                                      src={log.icon}
                                      alt={log.appName}
                                    />
                                    <Typography
                                      sx={{
                                        paddingLeft: "10px",
                                        fontFamily: "var(--cygreMedium), sans-serif",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {log.appName}
                                    </Typography>
                                  </Box>
                                  <Typography sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "14px" }}>
                                    {log.totalTransactions} installs
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )
                      }
                      placement="top"
                      arrow
                    >
                      <Geography
                        geography={geo}
                        data-country={geo.properties.name}
                        style={{
                          default: { fill: groupedCountryData ? hoverColor : "#E7E7E7", outline: "none" },
                          hover: { fill: groupedCountryData ? hoverColor : "#E7E7E7", outline: "none" },
                          pressed: { fill: "#FF5722", outline: "none" },
                        }}
                      />
                    </Tooltip>
                  );
                })
              }
            </Geographies>
            {markers.map((item: any, index: any) => (
              <Marker key={index} coordinates={[item.longitude, item.latitude]}>
                <span data-country={item.country}></span>
                <svg>
                  <circle cx="10" cy="10" r="3" fill="#2E263D" opacity="1" />
                  <circle cx="10" cy="10" r="6" fill="#2E263D" opacity="0.7" />
                  <circle cx="10" cy="10" r="10" fill="#2E263D" opacity="0.4" />
                </svg>
              </Marker>
            ))}
          </ComposableMap>
        </Box>

        <Box sx={{ overflowX: "auto", width: { xs: "100%", xl: "35%" } }}>
          <MapCountryTable rows={tableRows} />
        </Box>
      </Box>
    </Box>
  );
}
