import { Box, Typography } from "@mui/material";
import CustomizedSwitches from "./Switch";

interface Switch {
  description: string;
  label: string;
  isSelected: boolean;
  handleChange?: () => void;
}

const TrackSwitch = ({ description, label, isSelected, handleChange }: Switch) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          marginBottom: "8px",
          fontFamily: "var(--cygreMedium), sans-serif",
          fontSize: "14px",
          fontWeight: "600",
          color: "#2E263D",
        }}
      >
        {label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          sx={{
            paddingRight: "32px",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: 1.43,
            color: "#6D6777",
          }}
        >
          {description}
        </Typography>
        <CustomizedSwitches isSelected={isSelected} handleChange={handleChange} />
      </Box>
    </Box>
  );
};

export default TrackSwitch;
