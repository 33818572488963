import { useAppStore } from "store/appStore";
import { useTranslation } from "react-i18next";
import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import CustomInput from "components/Input";
import { BootstrapTooltip } from "../mainTab/CommentsTable";
import DeleteIcon from "@mui/icons-material/Delete";

const PostbacksList = () => {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();

  const handlePostbackDelete = (postbackUrl: string) => {
    const updatedPostbacksArr = appData.customPostbacks.filter((postback) => {
      return postback.url !== postbackUrl;
    });

    saveAppData({ customPostbacks: updatedPostbacksArr });
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "24px",
        padding: "14px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        overflowX: "auto",
      }}
    >
      <Typography
        component="h3"
        sx={{
          marginBottom: "12px",
          fontFamily: "var(--cygreMedium), sans-serif",
          fontSize: "14px",
          fontWeight: "600",
          color: "#2E263D",
        }}
      >
        {t("postbacksListTitle")}
      </Typography>

      <List
        dense
        sx={{
          "&.MuiList-root": { display: "flex", flexDirection: "column", gap: "12px", padding: 0 },
        }}
      >
        {appData.customPostbacks.map((postback) => {
          const { url, method, statuses } = postback;

          return (
            <ListItem key={url} sx={{ display: "flex", gap: "4px" }} disablePadding>
              <CustomInput
                sx={{ margin: 0 }}
                id="customPostback"
                fullWidth
                minHeight="34px"
                height="34px"
                name="customPostback"
                value={url}
                customBgColor="#F6F7F9"
                isReadOnly={true}
              />
              <CustomInput
                sx={{ margin: 0, input: { color: "#56CA00" } }}
                id="customPostback"
                customWidth="auto"
                minHeight="34px"
                height="34px"
                name="customPostback"
                value={method}
                customBgColor="#F6F7F9"
                isReadOnly={true}
              />
              <CustomInput
                sx={{ margin: 0, input: { color: "#F4701B" } }}
                id="customPostback"
                customWidth="auto"
                minHeight="34px"
                height="34px"
                name="customPostback"
                value={statuses}
                customBgColor="#F6F7F9"
                isReadOnly={true}
              />
              <IconButton
                edge="end"
                aria-label="delete-icon"
                onClick={() => {
                  handlePostbackDelete(url);
                }}
              >
                <BootstrapTooltip title={t("delete")} placement="top">
                  <DeleteIcon sx={{ width: "18px", height: "18px", color: "#6D6777" }} />
                </BootstrapTooltip>
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default PostbacksList;
