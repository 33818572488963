import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePushStore } from "store/pushStore";
import CustomSelectComponent from "../Select";
import { TPushEvent } from "../../interfaces/mockupApp";

export default function EventSets() {
  const { t } = useTranslation();
  const { savePushData, pushEvent } = usePushStore((state) => ({
    savePushData: state.savePushData,
    pushEvent: state.pushData.pushEvent,
  }));

  const handleChange = (newValue: TPushEvent) => {
    if (newValue) {
      savePushData({ intervalDates: {}, standartDate: undefined, pushEvent: newValue });
    }
  };

  const options = [
    { value: "0", label: "None", id: "0", disabled: true },
    { value: "lead", label: t("eventLeadOption"), id: "lead" },
    { value: "sale", label: t("eventSaleOption"), id: "sale" },
    { value: "subscription", label: t("eventSubscriptionOption"), id: "subscription" },
    { value: "install", label: t("eventInstall"), id: "install" },
  ];

  return (
    <CustomSelectComponent
      title={""}
      options={options}
      backgroundColor="#F6F7F9"
      noBorder={true}
      value={pushEvent || ""}
      onChange={(e: any) => handleChange(e.target.value)}
      placeholder={t("selectEvent")}
    />
  );
}
