import { Button } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { formatDateDDMMYYYY } from "utils/formateDate";
import useMediaByValue from "hooks/useMediaByValue";
import "react-datepicker/dist/react-datepicker.css";
import "./basic-styles.scss";

type DateRangeArray = [Date | string | null, Date | string | null];

interface ICustomDatePickerProps {
  onChangeCallback: (dates: DateRangeArray) => void;
  pageChange?: (page: number) => void;
}

export const DatePickerRange = ({ onChangeCallback, pageChange }: ICustomDatePickerProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const isTablet = useMediaByValue("(min-width:768px)");

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (pageChange) {
      pageChange(0);
    }
    onChangeCallback(dates);
  };

  const ExampleCustomInput = forwardRef(({ onClick }: any, ref) => (
    <Button
      variant="contained"
      onClick={onClick}
      ref={ref as React.Ref<HTMLButtonElement>}
      sx={{
        width: "100%",
        height: "24px",
        padding: "6px",
        fontSize: "12px",
        borderRadius: "8px",
        color: "#6D6777",
        backgroundColor: "#F6F7FB",
      }}
    >
      {`${formatDateDDMMYYYY(startDate)}${endDate ? ` — ${formatDateDDMMYYYY(endDate)}` : ""}`}
    </Button>
  ));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <DatePicker
      open={isOpen}
      selected={startDate}
      startDate={startDate}
      endDate={endDate as any}
      maxDate={new Date()}
      onChange={onChange}
      selectsRange
      withPortal
      monthsShown={isTablet ? 2 : 1}
      customInput={<ExampleCustomInput />}
      onClickOutside={() => setIsOpen(false)}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
    />
  );
};
