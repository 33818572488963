import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

interface IAdminWidgetCardProps {
  title: string;
  value: number | string;
  icon: any;
  iconBoxBackground: string;
  iconColor: string;
}

const AdminWidgetCard = ({ title, value, icon, iconBoxBackground, iconColor }: IAdminWidgetCardProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: "124px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        // boxShadow: "0px 0px 4px 0px #A5A3AE4D",
        boxShadow:
          "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#6D6777",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "15px",
            marginBottom: "8px",
          }}
        >
          {t(title)}
        </Typography>
        <Typography sx={{ color: "#2E263D", fontFamily: "var(--cygreRegular), sans-serif", fontSize: "26px" }}>
          {value}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "56px",
          height: "56px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: iconBoxBackground,

          "& svg": {
            width: "30px",
            height: "30px",
            fill: iconColor,
          },
        }}
      >
        {icon}
      </Box>
    </Box>
  );
};

export default AdminWidgetCard;
