import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CustomButton from "components/Button";
import { IconButton, Typography } from "@mui/material";
import CustomInput from "components/Input";
import CloseButtonIcon from "assets/icons/Close";
import useIsTablet from "hooks/useIsTablet";
import { useState } from "react";
import useModalStore, { defaultUserInfo } from "store/modalStore";

interface IReferralBonusRateModalProps {
  handleBonusUpdate: () => void;
}

export default function ReferralBonusRateModal({ handleBonusUpdate }: IReferralBonusRateModalProps) {
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const [inputError, setInputError] = useState<string>("");
  const { isReferralModalOpen, userInfo, changeUserInfo, closeModal } = useModalStore();
  const cautionDeleteUserText = `(User id: ${userInfo.id}${userInfo?.telegram || userInfo?.telegramUsername ? ` / Telegram: ${userInfo?.telegram || userInfo?.telegramUsername}` : ""}${userInfo?.email && ` / E-mail: ${userInfo?.email}`})`;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (Number(value) > 100) {
      setInputError("refferalModalError");
    } else {
      setInputError("");
    }

    if (name === "depositBonus") {
      changeUserInfo({ referralDepositBonusRate: Number(value) });
    } else {
      changeUserInfo({ referralInstallBonusRate: Number(value) });
    }
  };

  const handleSubmit = () => {
    if (inputError) {
      return;
    }
    handleBonusUpdate();
    handleModalClose();
  };

  const handleModalClose = () => {
    changeUserInfo(defaultUserInfo);
    closeModal();
  };

  return (
    <Modal
      open={isReferralModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: "32px 24px 24px 24px",
          bgcolor: "#FFFFFF",
          boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
          width: "90%",
          maxWidth: "520px",
          height: "auto",
          maxHeight: "90dvh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16, width: 32, height: 32 }}
          aria-label="modal close button"
          onClick={handleModalClose}
        >
          <CloseButtonIcon />
        </IconButton>
        <Typography
          id="modal-title"
          variant="h2"
          sx={{
            marginBottom: "14px",
            fontSize: "24px",
            fontWeight: 400,
            color: "#2E263D",
          }}
        >
          {t("referralModalTitle")}
        </Typography>
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "14px",
            lineHeight: "1.35",
            fontWeight: 400,
            color: "#2E263D",
          }}
        >
          {t("referralModalDescription")}
        </Typography>

        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "12px",
            lineHeight: "1.35",
            fontWeight: 500,
            color: "#2E263D",
          }}
        >
          {cautionDeleteUserText}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "24px" }}>
          <CustomInput
            required
            fullWidth
            id="depositBonus"
            value={userInfo?.referralDepositBonusRate}
            placeholder=""
            name="depositBonus"
            height="40px"
            label={t("depositBonusRate")}
            customBgColor="#F6F7FB"
            customFontSize="12px"
            customLablePadding="0"
            helperText={t(inputError)}
            error={inputError ? true : false}
            onChange={handleInputChange}
            onlyNumbersValues={true}
          />
          <CustomInput
            required
            fullWidth
            id="installBonus"
            value={userInfo?.referralInstallBonusRate}
            placeholder=""
            name="installBonus"
            height="40px"
            label={t("installBonusRate")}
            customBgColor="#F6F7FB"
            customFontSize="12px"
            customLablePadding="0"
            helperText={t(inputError)}
            error={inputError ? true : false}
            onChange={handleInputChange}
            onlyNumbersValues={true}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: isTablet ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          <CustomButton
            width="100%"
            height={40}
            textButton={t("cancel")}
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleModalClose}
          />
          <CustomButton
            width="100%"
            textButton={t("save")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Modal>
  );
}
