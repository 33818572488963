import { Box, Typography, List, ListItem, Link, Button } from "@mui/material";
import Lock from "assets/icons/Lock";
import Person from "assets/icons/Person";
import AttachMoney from "assets/icons/AttachMoney";
import Notification from "assets/icons/Notification";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import CookieIcon from "@mui/icons-material/Cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

// import NotificationSettings from "./components/NotificationSettings";
// import Faq from "@/assets/icons/Faq";
// import PasswordChange from "./components/PasswordChange";
// import { withAuth } from "HOC/withAuth";

// type Tab = "/profile" | "/profile/notifications" | "/profile/tariffs" | "/profile/changepsw" | "/profile/faq";

const getItemStyles = (isActive: boolean) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "4px",
  padding: "6px 12px",
  borderRadius: "8px",
  backgroundColor: isActive ? "rgba(244, 112, 27, 0.08)" : "transparent",
  cursor: "pointer",
});

const getIconStyles = (isActive: boolean) => ({
  minWidth: "36px",
  width: "36px",
  height: "36px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  marginRight: "8px",
  background: isActive ? "#F4701B" : "#F6F7FB",
});

const getItemTextStyles = () => ({ fontFamily: "var(--cygreRegular), sans-serif", fontSize: "16px", color: "#6d6777" });

const getIconColor = (isActive: boolean) => (isActive ? "#fff" : "#6D6777");

export const ProfileSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isActiveItem = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Box
      sx={{
        // padding: "0 20px",
        paddingRight: { xs: "16", md: "24px" },
        width: { xs: "100%", md: 300 },
        marginBottom: { xs: "16px", md: 0 },
        border: { xs: "1px solid #A5A3AE4D", md: "none" },
        borderRadius: "8px",
      }}
    >
      <List sx={{ padding: { xs: "16px 16px 0px 16px", md: "0px" } }}>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile"))}
          onClick={() => {
            navigate("profile");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile"))}>
            <Person color={getIconColor(isActiveItem("/profile"))} />
          </Box>
          <Typography sx={getItemTextStyles()} variant="body2">
            {t("profile")}
          </Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/notifications"))}
          onClick={() => {
            navigate("/profile/notifications");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/notifications"))}>
            <Notification color={getIconColor(isActiveItem("/profile/notifications"))} />
          </Box>
          <Typography sx={getItemTextStyles()} variant="body2">
            {t("settingnotification")}
          </Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/tariffs"))}
          onClick={() => {
            navigate("/profile/tariffs");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/tariffs"))}>
            <AttachMoney color={getIconColor(isActiveItem("/profile/tariffs"))} />
          </Box>
          <Typography sx={getItemTextStyles()} variant="body2">
            {t("tariffs")}
          </Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/changepsw"))}
          onClick={() => {
            navigate("/profile/changepsw");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/changepsw"))}>
            <Lock color={getIconColor(isActiveItem("/profile/changepsw"))} />
          </Box>
          <Typography sx={getItemTextStyles()} variant="body2">
            {t("changepsw")}
          </Typography>
        </ListItem>
        <ListItem
          sx={getItemStyles(isActiveItem("/profile/faq"))}
          onClick={() => {
            navigate("/profile/faq");
          }}
        >
          <Box sx={getIconStyles(isActiveItem("/profile/faq"))}>
            <QuizOutlinedIcon
              sx={{ width: "20px", height: "20px", color: getIconColor(isActiveItem("/profile/faq")) }}
            />
          </Box>
          <Typography sx={getItemTextStyles()} variant="body2">
            FAQ
          </Typography>
        </ListItem>
      </List>
      <Button
        className="cky-banner-element"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          padding: { xs: "6px 28px", md: "6px 12px" },
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "16px",
          color: "#6d6777",
          textTransform: "none",
          borderRadius: "8px",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "36px",
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            marginRight: "8px",
            background: "#F6F7FB",
          }}
        >
          <CookieIcon sx={{ width: "20px", height: "20px" }} />
        </Box>
        {t("cookieSettings")}
      </Button>
    </Box>
  );
};
