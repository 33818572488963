import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import GeoMultiSelect from "components/GeoMultiSelect";
import { usePushStore } from "store/pushStore";

export default function NewsletterSegmentation() {
  const { t } = useTranslation();
  const savePushData = usePushStore((state) => state.savePushData);
  const pushData = usePushStore((state) => state.pushData);

  const handleGeoChange = (selected: any) => {
    const geo = selected.map((country: any) => country.value);
    savePushData({ geo });
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        "&::before": {
          content: "none",
        },
        "&.Mui-expanded": { margin: 0 },
      }}
    >
      <AccordionSummary
        sx={{ padding: { xs: "0px 16px", md: "0px 20px" }, height: { xs: "56px", md: "64px" } }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          sx={{ lineHeight: "32px", fontSize: "16px", color: "#2E263D", fontFamily: "var(--cygreMedium), sans-serif" }}
        >
          {t("segmentation")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          padding: { xs: "0px 16px 24x 16px", md: "0px 20px 32px 20px" },
        }}
      >
        <GeoMultiSelect onChange={handleGeoChange} geo={pushData.geo} />
      </AccordionDetails>
    </Accordion>
  );
}
