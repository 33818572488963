import { useTranslation } from "react-i18next";
import useMediaByValue from "hooks/useMediaByValue";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IListedDomain } from "interfaces/domains";
import { formatDateDDMMYYYYPlus3days } from "utils/formateDate";
import DomainIcon from "@mui/icons-material/Domain";

interface IDomainsStatsProps {
  domainsArr: IListedDomain[];
}

const DomainsStats = ({ domainsArr }: IDomainsStatsProps) => {
  const { t } = useTranslation();
  const isMediumeViewPort = useMediaByValue("(min-width:920px)");

  const bouhgtDomains = domainsArr.filter((domain) => {
    return domain.status !== "free";
  });

  const freeDomains = domainsArr.filter((domain) => {
    return domain.status === "free";
  });

  const dateNow = new Date();
  const domainsEndData = new Date();
  domainsEndData.setDate(dateNow.getDate() + 7);

  const willBySoonFreeDomains = domainsArr.filter((domain) => {
    const expiredDate = new Date(domain.expiredAt);
    return domain.status !== "free" && expiredDate >= dateNow && expiredDate <= domainsEndData;
  });

  return (
    <Grid size={isMediumeViewPort ? 6 : 12} sx={{ padding: "26px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "6px", marginBottom: "8px" }}>
        <Box
          sx={{
            width: "26px",
            height: "26px",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(72, 61, 139, 0.16)",
          }}
        >
          <DomainIcon sx={{ width: "16px", height: "16px", fill: "#4B0082" }} />
        </Box>
        <Typography
          sx={{
            color: "#6D6777",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          {t("domains")}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: "#2E263D",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontWeight: "500",
          fontSize: "14px",
        }}
      >
        {`${t("totalBought")} ${bouhgtDomains?.length || 0}`}
      </Typography>
      <Typography
        sx={{
          color: "#2E263D",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontWeight: "500",
          fontSize: "14px",
          marginBottom: "8px",
        }}
      >
        {`${t("freeLeft")} ${freeDomains?.length || 0}`}
      </Typography>
      <Box
        sx={{
          padding: "16px 16px",
          borderRadius: "8px",
          boxShadow:
            "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "12px",
            textAlign: "center",
            color: "#2E263D",
            marginBottom: "8px",
          }}
        >
          {t("freeDomainsListTitle")}
        </Typography>
        {willBySoonFreeDomains.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "230px",
              maxHeight: "230px",
            }}
          >
            <Typography
              sx={{
                display: "inline-block",
                maxWidth: "200px",
                fontFamily: "var(--cygreRegular), sans-serif",
                fontWeight: "400",
                fontSize: "12px",
                textAlign: "center",
                color: "#2E263D",
              }}
            >
              {t("emptyFreeDomainsList")}
            </Typography>
          </Box>
        ) : (
          <List
            dense
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              padding: 0,
              minHeight: "230px",
              maxHeight: "230px",
              overflowY: "auto",
            }}
          >
            {willBySoonFreeDomains?.map((domain, index) => {
              const { _id, name, expiredAt } = domain;
              const domainExpiredAtDate = formatDateDDMMYYYYPlus3days(expiredAt);

              return (
                <ListItem key={_id} disablePadding>
                  <ListItemIcon sx={{ minWidth: "10px", fontSize: "12px", marginRight: "6px" }}>
                    {index + 1}.
                  </ListItemIcon>
                  <ListItemText
                    id={_id}
                    primary={name}
                    sx={{
                      "& .MuiTypography-root": {
                        paddingRight: "10px",
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  />
                  <ListItemText
                    id={_id}
                    primary={domainExpiredAtDate}
                    sx={{
                      "& .MuiTypography-root": {
                        fontWeight: 400,
                        fontSize: 12,
                        textAlign: "right",
                      },
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </Grid>
  );
};

export default DomainsStats;
