import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "components/Input";
import { useTranslation } from "react-i18next";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IComment } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
import CustomButton from "components/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Avatar, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import { handleDownloadAvatar, handleGenerateRandomAvatar } from "utils/avatarUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px",
  width: "90%",
  maxWidth: "580px",
  // maxHeight: "80vh",
};

interface IGenerateCommentModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  translations: any; //  ?????
  languages: any;
  title: string;
  editComment?: IComment | null;
}

export default function AddCommentModal({ open, setOpen, languages, title, editComment }: IGenerateCommentModal) {
  const { appData, saveAppData } = useAppStore();
  const [errorRating, setErrorRating] = useState("");
  const [date, setDate] = React.useState<Dayjs | null>(null);
  const [formData, setFormData] = useState<IComment>({
    author: "",
    comment: "",
    rating: 0,
    date: "",
    avatar: "",
  });

  const [avatar, setAvatar] = useState("");

  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (editComment) {
      setFormData(editComment);
      setAvatar(editComment.avatar);
      setDate(dayjs(editComment.date));
    }
  }, [editComment]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "rating") {
      setErrorRating("");
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setDateOfComment = (newValue: Dayjs) => {
    const date = dayjs(newValue).format("MM/DD/YYYY");

    setFormData((prevState) => ({
      ...prevState,
      date: date,
    }));

    setDate(newValue);
  };

  const handleSave = () => {
    const { author, comment, rating } = formData;
    if (rating === 0) {
      setErrorRating(t("addratingerror"));
      return;
    }

    const updatedComments = editComment
      ? appData.comments.map((c) => (c.author === editComment.author ? formData : c))
      : [...appData.comments, formData];

    saveAppData({ ...appData, comments: updatedComments });
    handleClose();
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-check-domain-title"
        aria-describedby="modal-check-domain-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "32px",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                textAlign: "left",
                fontSize: "24px",
                lineHeight: "45px",
                fontFamily: "var(--cygreMedium), sans-serif",
                color: "#2E263D",
              }}
            >
              {title}
            </Typography>
            <Box aria-label="close" onClick={handleClose} sx={{ cursor: "pointer" }}>
              <CloseIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              marginBottom: "16px",
            }}
          >
            <Avatar sx={{ width: 48, height: 48 }} src={avatar} />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "8px", md: "12px" },
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ minWidth: { xs: "100%", md: "120px" } }}>
                <CustomButton
                  textButton={t("download")}
                  height={40}
                  width={"100%"}
                  customColor="#FFFFFF"
                  customBgColor="#2E263D"
                  customBorderRadius="8px"
                  customFontSize="16px"
                  customTextTransform="none"
                  onClick={() => document.getElementById("avatarInput")?.click()}
                />
                <input
                  id="avatarInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleDownloadAvatar(e, setAvatar, setFormData)}
                />
              </Box>
              <Box
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  fontFamily: "var(--cygreRegular), sans-serif",
                  color: "#6D6777",
                }}
              >
                {t("or")}
              </Box>
              <CustomButton
                textButton={t("generaterandomly")}
                height={40}
                width={"100%"}
                customColor="#6D6777"
                customBorderColor="#C9D0D8"
                customHoverColor="transparent"
                customBorderRadius="8px"
                customFontSize="16px"
                customTextTransform="none"
                onClick={() => handleGenerateRandomAvatar(setAvatar, setFormData)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px", width: "100%" }}>
              <Box sx={{ width: "100%" }}>
                <CustomInput
                  margin="normal"
                  required
                  fullWidth
                  id="author"
                  placeholder={t("enterauthor")}
                  name="author"
                  label={t("author")}
                  value={formData.author}
                  onChange={handleInputChange}
                  customBgColor="#F6F7F9"
                />
              </Box>
              <Box sx={{ width: "96px" }}>
                <CustomInput
                  margin="normal"
                  required
                  fullWidth
                  id="rating"
                  placeholder={"4.8"}
                  name="rating"
                  label={t("rating")}
                  error={!!errorRating}
                  helperText={errorRating}
                  value={formData.rating}
                  onChange={handleInputChange}
                  customBgColor="#F6F7F9"
                />
              </Box>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "12px",
                  color: "#2E263D",
                  paddingLeft: "5px",
                  fontFamily: "var(--cygreRegular), sans-serif",
                  marginBottom: "-8px",
                }}
              >
                {t("date")}
              </Typography>
              <DatePicker
                sx={{
                  marginTop: "16px",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    marginBottom: "10px",
                    backgroundColor: "#F6F7F9",
                    height: "40px",
                  },
                  "& .MuiInputAdornment-root": {
                    marginRight: "8px",
                  },
                  "& .MuiInputBase-input": {},
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  input: {
                    fontFamily: "var(--cygreRegular), sans-serif",
                    fontSize: "14px",
                    lineHeight: "22px",
                    border: "none",
                    color: "black",
                    "&::placeholder": {
                      fontSize: "12px",
                      textOverflow: "ellipsis !important",
                      color: "black",
                      fontFamily: "var(--cygreRegular), sans-serif",
                    },
                  },
                }}
                value={date}
                onChange={(newValue) => newValue && setDateOfComment(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginBottom: { xs: "24px", md: "32px" } }}>
            <CustomInput
              margin="normal"
              required
              fullWidth
              id="comment"
              name="comment"
              placeholder={t("enterthelastupdatedescription")}
              multiline={true}
              rows={4}
              label={t("comment")}
              value={formData.comment}
              onChange={handleInputChange}
              customBgColor="#F6F7F9"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <CustomButton
              textButton={t("cancel")}
              height={40}
              width={"100%"}
              customBorderRadius="8px"
              customColor="#6D6777"
              customBorderColor="#C9D0D8"
              customHoverColor="transparent"
              customFontSize="14px"
              onClick={handleClose}
            />
            <CustomButton
              textButton={t("add")}
              height={40}
              width={"100%"}
              customColor="#fff"
              customBgColor="#F4701B"
              customBorderColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              onClick={handleSave}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
