import { Outlet } from "react-router";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import Sidebar from "components/DashboardSidebar/Sidebar";
import DashboardHeader from "components/DashboardHeader";
import { useAuthStore } from "store/authStore";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { ITransaction } from "interfaces/stats";
import i18n from "i18n";

export const DashboardLayout = () => {
  const { setUser } = useAuthStore();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [invoices, setInvoices] = useState<ITransaction[]>([]);
  const [unusedPromocode, setUnusedPromocode] = useState<string>("");
  const { updateUserData } = useAuthStore();
  const token = getToken();

  const { triggerFetch } = useFetch({
    url: "profile/user-info",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getAllUserTransactions } = useFetch({
    url: "transaction",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getWallet } = useFetch({
    url: "profile/wallet",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: checkCodeApi, data: checkData } = useFetch({
    url: "promo/check",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const walletRequest = async () => {
    const res = await getWallet();
    if (res?.data) {
      updateUserData(res.data);
    }
  };

  const getUsersInvoices = async () => {
    const transactionsData = await getAllUserTransactions();
    const invoiceTransactions = (transactionsData?.data.items || []).filter((item: ITransaction) => {
      return item.type === "invoice-deposit";
    });

    setInvoices(invoiceTransactions);
  };

  const checkUnusedPromocode = async (promoCode: string) => {
    await checkCodeApi({}, `promo/check/${promoCode}?locale=${i18n.language}`);
  };

  const confirmUnusedPromocode = async () => {
    const promocodeResponse = await checkCodeApi({}, `promo/confirm/${unusedPromocode}?locale=${i18n.language}`);

    if (promocodeResponse.success) {
      localStorage.removeItem("unusedPromocode");
      setUnusedPromocode("");
      await walletRequest();
    }
  };

  useEffect(() => {
    const unusedPromocode = localStorage.getItem("unusedPromocode");

    if (token) {
      void userInfo();
    }

    if (unusedPromocode) {
      setUnusedPromocode(unusedPromocode);
      getUsersInvoices();
      void checkUnusedPromocode(unusedPromocode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!unusedPromocode || invoices.length === 0) {
      return;
    }

    void confirmUnusedPromocode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unusedPromocode, invoices]);

  useEffect(() => {
    if (checkData && !checkData.success && !checkData.unusedPromocodeName) {
      localStorage.removeItem("unusedPromocode");
      setUnusedPromocode("");
    }
  }, [checkData]);

  const userInfo = async () => {
    const res = await triggerFetch();
    if (res.data) {
      setUser(res?.data);
      localStorage.setItem("userData", JSON.stringify(res.data));
    }
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
    setMobileSidebarOpen(false);
  };

  return (
    <>
      <DashboardHeader setMobileSidebarOpen={setMobileSidebarOpen} />
      <main
        style={{
          display: "flex",
        }}
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          isMobileSidebarOpen={isMobileSidebarOpen}
          onSidebarClose={handleSidebarClose}
        />
        <Container
          maxWidth={false}
          sx={{
            padding: { xs: "16px", md: "24px" },
            height: "100dvh",
            maxHeight: { xs: "calc(100dvh - 56px)", md: "calc(100dvh - 70px)" },
            overflowY: "auto",
          }}
        >
          <Outlet />
        </Container>
      </main>
    </>
  );
};
