import { useTranslation } from "react-i18next";
import useMediaByValue from "hooks/useMediaByValue";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ITransaction } from "interfaces/stats";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { DefaultizedPieValueType, pieArcLabelClasses, PieChart } from "@mui/x-charts";

interface IInstallsWidgetProps {
  installsArr: ITransaction[];
}

const InstallsWidget = ({ installsArr }: IInstallsWidgetProps) => {
  const { t } = useTranslation();
  const isMediumViewPort = useMediaByValue("(min-width:920px)");
  const isLargeViewPort = useMediaByValue("(min-width:1120px)");

  const groupedInstallsByPrice = installsArr.reduce((acc: Record<string, number>, transaction) => {
    const { amount } = transaction;

    acc[amount] = (acc[amount] || 0) + 1;
    return acc;
  }, {});

  const mergedInstallsAmountByPrice = Object.entries(groupedInstallsByPrice).map(([price, installs]) => ({
    [price]: installs,
  }));

  const totalInstallsAmount = installsArr.reduce((sum: number, transaction) => sum + transaction.amount, 0).toFixed(2);
  const piarChartColors = ["#FFAB00", "#56CA00", "#5119B7", "#0000FF", "#800000", "#FF00FF", "#00FFFF"];
  const pieEmptyChartData = [{ id: 0, value: 100, color: "#16B1FF" }];

  const pieChartData = mergedInstallsAmountByPrice.map((install, index) => {
    const installKey = Object.keys(install)[0];
    const installValue = install[installKey];

    return { id: index, value: Number(installValue), color: piarChartColors[index], label: `${installKey}$` };
  });

  const totalAmount = pieChartData.map((item) => item.value).reduce((a, b) => a + b, 0);
  const isEmptyPieData = pieChartData.every((item) => item.value === 0);
  const isOnlyOnePieChartValue = pieChartData.filter((item) => item.value > 0).length === 1;

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalAmount;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const getEmptyArcLabel = () => {
    return t("noData");
  };

  return (
    <Grid
      container
      spacing={0}
      size={isLargeViewPort ? 8 : 12}
      sx={{
        padding: "26px",
        flexDirection: isMediumViewPort ? "column" : "row",
        borderRadius: "8px",
        boxShadow:
          "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
      }}
    >
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: "6px", marginBottom: "20px" }}>
        <Box
          sx={{
            width: "26px",
            height: "26px",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(22, 177, 255, 0.16)",
          }}
        >
          <PointOfSaleIcon sx={{ width: "16px", height: "16px", fill: "#16B1FF" }} />
        </Box>
        <Typography
          sx={{
            color: "#6D6777",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          {t("installsAndDeductions")}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid
          size={isMediumViewPort ? 6 : 12}
          sx={{ display: isMediumViewPort ? "block" : "flex", paddingLeft: isMediumViewPort ? "40px" : 0 }}
        >
          <PieChart
            series={[
              {
                arcLabel: isEmptyPieData ? getEmptyArcLabel : getArcLabel,
                arcLabelMinAngle: 35,
                arcLabelRadius: "140%",
                data: isEmptyPieData ? pieEmptyChartData : pieChartData,
                outerRadius: 100,
                cx: "100%",
                innerRadius: 25,
                paddingAngle: isOnlyOnePieChartValue ? 0 : 3,
                cornerRadius: 5,
                highlightScope: { fade: "global", highlight: "item" },
                faded: { innerRadius: 30, additionalRadius: -15, color: "gray" },
              },
            ]}
            width={200}
            height={200}
            slotProps={{
              legend: {
                hidden: true,
              },
              popper: {
                hidden: isEmptyPieData,
              },
            }}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontSize: "12px",
                fill: "#FFFFFF",
              },
            }}
          />
        </Grid>
        <Grid
          size={isMediumViewPort ? 6 : 12}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
        >
          <Box>
            {pieChartData.map((row) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      background: row.color,
                    }}
                  />
                  <Typography sx={{ fontSize: "14px" }}>{`${row.label} — ${row.value} ${t("pcs")}`}</Typography>
                </Box>
              );
            })}
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#2E263D",
                fontFamily: "var(--cygreRegular), sans-serif",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              {`${t("totalInstallsLabel")}: ${totalAmount || 0} ${t("pcs")}`}
            </Typography>
            <Typography
              sx={{
                color: "#2E263D",
                fontFamily: "var(--cygreRegular), sans-serif",
                fontWeight: "500",
                fontSize: "14px",
                marginBottom: "8px",
              }}
            >
              {`${t("totalInstalls")}: ${totalInstallsAmount || 0}$`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InstallsWidget;
