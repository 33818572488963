import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import { Box, Button } from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "components/SearchBar";
import { IPwaComponent } from "interfaces/pwas";
import { PwaListTable } from "components/PwaList/PWATableView";
import { PWACardsView } from "components/PwaList/PWACardsView";
import "./menu.css";
import FilterPwa from "components/Filter/FilterPwa";

export interface IKeyVal {
  [key: string]: string;
}

interface SwitchButtonProps {
  active: boolean;
}

interface IFilterState {
  [key: string]: string[];
}

const PwaPerformance = ({ pwas, getPwas }: IPwaComponent) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const lastView = localStorage.getItem("lastPWAPageView") as "table" | "grid";
  const [view, setView] = useState<"table" | "grid">(lastView || "table");
  const [filter, setFilter] = useState<IFilterState>({ status: [], geo: [], categoryVertical: [] });
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredItems = pwas?.filter((item: any) => {
    const matchesSearch = item.appName.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilters = Object.keys(filter).every((filterType) => {
      if (filterType === "geo") {
        return (
          filter[filterType].length === 0 ||
          item[filterType]?.some((geoItem: string) => filter[filterType].includes(geoItem))
        );
      }
      return filter[filterType].length === 0 || filter[filterType].includes(item[filterType]);
    });

    return matchesSearch && matchesFilters;
  });

  const SwitchButton = styled(Button)<SwitchButtonProps>(({ theme, active }) => {
    return {
      borderRadius: 10,
      backgroundColor: active ? "#ffffff" : "transparent",
      padding: "0px 5px",
      minWidth: "auto",
      color: "#F4701B",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    };
  });

  const ViewSwitch = ({ view, toggleView }: { view: "table" | "grid"; toggleView: () => void }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#F6F7FB",
          padding: "5px",
          borderRadius: "10px",
          width: "74px",
          height: "40px",
        }}
      >
        <SwitchButton
          onClick={() => {
            return view !== "grid" && toggleView();
          }}
          active={view === "grid"}
        >
          {view === "grid" ? <ViewModuleIcon /> : <ViewModuleOutlinedIcon sx={{ color: "#6D6777" }} />}
        </SwitchButton>
        <SwitchButton
          onClick={() => {
            return view !== "table" && toggleView();
          }}
          active={view === "table"}
        >
          {view === "table" ? <MenuIcon /> : <MenuIcon sx={{ color: "#6D6777" }} />}
        </SwitchButton>
      </div>
    );
  };

  const toggleView = () => {
    setView(view === "table" ? "grid" : "table");
    localStorage.setItem("lastPWAPageView", view === "table" ? "grid" : "table");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "normal",
          marginBottom: isMobile ? "15px" : "0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "start",
            alignItems: "normal",
            gap: { xs: "8px", md: "16px" },
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <SearchBar onSearch={handleSearch} placeholder={t("searchname")} />
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "normal", md: "space-between" },
              alignItems: "center",
              gap: { xs: "10px", md: 0 },
              width: "100%",
            }}
          >
            <FilterPwa filter={filter} setFilter={setFilter} />
            <ViewSwitch view={view} toggleView={toggleView} />
          </Box>
        </Box>
      </Box>
      {view === "table" ? (
        <PwaListTable filteredItems={filteredItems} getPwas={getPwas} />
      ) : (
        <PWACardsView filteredItems={filteredItems} getPwas={getPwas} />
      )}
    </>
  );
};

export default PwaPerformance;
