import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppStore } from "store/appStore";
import PhoneMockup from "components/PhoneMockup";
import PwaTabs from "components/PWATabs/Tabs";
import DomainTab from "components/PWATabs/domainTab/DomainTab";
import MainTab from "components/PWATabs/mainTab/MainTab";
import NameApp from "components/PWATabs/mainTab/NameApp";
import TrackingTab from "components/PWATabs/trackingTab/TrackingTab";
import { textsToTranslate } from "utils/en";
import { getLanguages, translateText } from "utils/translationService";
import CreativesTab from "components/PWATabs/CreativesTab/CreativesTab";
import Eye from "assets/icons/Eye";
import PhoneMockupModal from "components/PhoneMockupModal";
import { getToken } from "utils/getToken";
import { useParams } from "react-router";
import PhoneContentPwa from "components/PWATemplate/PhoneContentPwa";
import useFetch from "hooks/useFetch";
import useIdStore from "store/pwaId";

export default function PWAEditPage() {
  const [languages, setLanguages] = useState(null);
  const { appData, saveAppData, intiAppData } = useAppStore();
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const token = getToken();
  const { id: pwaId, lastSelectedPwaId } = useIdStore();

  useEffect(() => {
    if (pwaId !== lastSelectedPwaId) {
      return;
    } else {
      const lastTabValue = Number(localStorage.getItem("lastTabValue"));
      setValue(lastTabValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { triggerFetch: getPwa } = useFetch({
    url: `pwa/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    (async () => {
      if (id) {
        const { data } = await getPwa();
        intiAppData(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const lang = async () => {
      const fetchLanguages: any = await getLanguages();
      setLanguages(fetchLanguages?.languages);
    };
    if (!languages) {
      void lang();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const translate = async () => {
      if (appData.language === "en" || appData.language === "en-GB" || appData.language === "EN") {
        saveAppData({ translations: textsToTranslate });
      } else {
        const translatedValues = await translateText(textsToTranslate, appData.language);
        saveAppData({ translations: translatedValues });
      }
    };
    if (appData.language) {
      void translate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.language]);

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return <MainTab appData={appData} languages={languages} setValue={setValue} />;
      case 1:
        return <CreativesTab setValue={setValue} />;
      case 2:
        return <DomainTab setValue={setValue} />;
      case 3:
        return <TrackingTab />;
      default:
        return null;
    }
  };

  return (
    <>
      <>
        <NameApp />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <PwaTabs setValue={setValue} renderTabContent={renderTabContent} value={value} />
          </Box>
          {value !== 2 && (
            <>
              <Box
                sx={{
                  display: { xs: "none", xl: "block" },
                  marginTop: "136px",
                  marginLeft: "24px",
                  height: "fit-content",
                  position: "sticky",
                  top: "10%",
                  border: "1px solid #EEEEEE",
                  borderRadius: "8px",
                  padding: "40px 65px",
                }}
              >
                <PhoneMockup>
                  <PhoneContentPwa />
                </PhoneMockup>
              </Box>
              <Box
                onClick={() => setOpenModal(true)}
                sx={{
                  display: { xs: "flex", xl: "none" },
                  minWidth: "40px",
                  width: { xs: "40px", md: "48px" },
                  height: { xs: "40px", md: "48px" },
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                  background: "#F4701B",
                  marginTop: { xs: "62px", md: "66px" },
                  marginLeft: { xs: "-24px", md: "-24px" },
                  marginRight: { xs: "-16px", md: "-24px" },
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  position: "sticky",
                  top: "10px",

                  "& svg": { width: { xs: "22px", md: "26px" }, height: { xs: "22px", md: "26px" } },
                }}
              >
                <Eye color={"#fff"} />
              </Box>
            </>
          )}
        </Box>
      </>
      <PhoneMockupModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
