import { create } from "zustand";

interface IUserInfo {
  id?: string;
  email?: string;
  telegram?: string;
  telegramUsername?: string;
  referralDepositBonusRate: number;
  referralInstallBonusRate: number;
}

interface ModalState {
  isOpen: boolean;
  isReferralModalOpen: boolean;
  isUserStatsModalOpen: boolean;
  mode: "login" | "signup" | "forgotPassword" | "" | "passwordRecovery";
  userInfo: IUserInfo;
  changeUserInfo: (newInfo: Partial<IUserInfo>) => void;
  openModal: (mode: "login" | "signup" | "forgotPassword" | "" | "passwordRecovery") => void;
  openReferralModal: () => void;
  openUserStatsModal: () => void;
  closeModal: () => void;
}

export const defaultUserInfo: IUserInfo = {
  id: "",
  email: "",
  telegram: "",
  telegramUsername: "",
  referralDepositBonusRate: 5,
  referralInstallBonusRate: 13,
};

const useModalStore = create<ModalState>((set) => ({
  isOpen: false,
  isReferralModalOpen: false,
  isUserStatsModalOpen: false,
  mode: "login",
  userInfo: { ...defaultUserInfo },
  changeUserInfo: (newInfo) =>
    set((state) => {
      const updatedUserInfo = { ...state.userInfo, ...newInfo };
      return { userInfo: updatedUserInfo };
    }),
  openModal: (mode: "login" | "signup" | "forgotPassword" | "passwordRecovery" | "") => set({ isOpen: true, mode }),
  openReferralModal: () => set({ isReferralModalOpen: true }),
  openUserStatsModal: () => set({ isUserStatsModalOpen: true }),
  closeModal: () => set({ isOpen: false, isReferralModalOpen: false, isUserStatsModalOpen: false, mode: "" }),
}));

export default useModalStore;
