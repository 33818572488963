import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import { ChangeEvent } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";

export default function MoreInfo() {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const { errors, clearError } = useErrorAppStore();
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
    if (value) {
      clearError(field);
    }
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        "&::before": { display: " none" },
      }}
    >
      <AccordionSummary
        sx={{ padding: { xs: "0px 16px", md: "0px 20px" }, height: { xs: "56px", md: "64px" } }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: "16px", fontFamily: "var(--cygreRegular), sans-serif", color: "#2e263d" }}>
          {t("additionalinformation")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          maxWidth: "500px",
          padding: { xs: "0px 16px 16px 16px", md: "0px 20px 20px 20px" },
        }}
      >
        <CustomInput
          required
          fullWidth
          id="developerSite"
          height="40px"
          placeholder={t("website")}
          name="developerSite"
          label={t("site")}
          value={appData.developerSite}
          onChange={(e) => handleInputChange(e, "developerSite")}
          customBgColor="#F6F7F9"
          error={!!errors.developerSite}
          helperText={errors.developerSite}
        />

        <CustomInput
          required
          fullWidth
          id="developerEmail"
          height="40px"
          placeholder={t("enteremail")}
          name="developerEmail"
          label={t("emailCaption")}
          value={appData.developerEmail}
          onChange={(e) => handleInputChange(e, "developerEmail")}
          customBgColor="#F6F7F9"
          error={!!errors.developerEmail}
          helperText={errors.developerEmail}
        />

        <CustomInput
          required
          fullWidth
          id="adressCompany"
          height="40px"
          placeholder={t("address")}
          name="adressCompany"
          label={t("adresscompany")}
          value={appData.adressCompany}
          onChange={(e) => handleInputChange(e, "adressCompany")}
          customBgColor="#F6F7F9"
          error={!!errors.adressCompany}
          helperText={errors.adressCompany}
        />

        <CustomInput
          required
          fullWidth
          id="linkPolicy"
          height="40px"
          placeholder={t("policyLink")}
          name="linkPolicy"
          label={t("linkPolicy")}
          value={appData.linkPolicy}
          onChange={(e) => handleInputChange(e, "linkPolicy")}
          customBgColor="#F6F7F9"
          error={!!errors.linkPolicy}
          helperText={errors.linkPolicy}
        />

        <CustomInput
          required
          fullWidth
          id="dateOfUpdates"
          height="40px"
          placeholder={t("lastUpdateDate")}
          name="dateOfupdates"
          label={t("dateOfupdates")}
          value={appData.dateOfUpdates}
          onChange={(e: any) => handleInputChange(e, "dateOfUpdates")}
          customBgColor="#F6F7F9"
          error={!!errors.dateOfUpdates}
          helperText={errors.dateOfUpdates}
        />

        <CustomInput
          required
          fullWidth
          id="author"
          height="40px"
          placeholder={t("installCount")}
          name="numberOfInst"
          label={t("numberOfinstallations")}
          value={appData.numberOfInst}
          onChange={(e: any) => handleInputChange(e, "numberOfInst")}
          customBgColor="#F6F7F9"
          error={!!errors.numberOfInst}
          helperText={errors.numberOfInst}
        />
      </AccordionDetails>
    </Accordion>
  );
}
