import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Box } from "@mui/material";
import CustomButton from "components/Button";
import PhoneIcon from "assets/icons/Phone";
import CreativeIcon from "assets/icons/Creatives";
import Domain from "assets/icons/Domain";
import Shuffle from "assets/icons/Shuffle";
import CustomTabs from "./CustomTabs";
import GooglePlayLogo from "assets/icons/GooglePlayLogo";
import CopyGPlayModal from "./CreativesTab/CopyGPlayModal";

interface IPwaTabs {
  value: number;
  setValue: (value: number) => void;
  renderTabContent: () => React.ReactNode;
}

export default function PwaTabs({ value, setValue, renderTabContent }: IPwaTabs) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const tabs = [
    {
      icon: <PhoneIcon color={value === 0 ? "#F4701B" : "#6D6777"} />,
      label: "main",
    },
    {
      icon: <CreativeIcon color={value === 1 ? "#F4701B" : "#6D6777"} />,
      label: "creatives",
    },
    {
      icon: <Domain color={value === 2 ? "#F4701B" : "#6D6777"} />,
      label: "domain",
    },
    {
      icon: <Shuffle color={value === 3 ? "#F4701B" : "#6D6777"} />,
      label: "linktracking",
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: { xs: "16px", md: "24px" } }}>
        <Box
          sx={{
            maxWidth: "fit-content",
            marginBottom: { xs: "16px", md: "24px" },
            padding: "0px 8px",
            borderRadius: "8px",
            background: "#F6F7FB",
          }}
        >
          <CustomTabs width={"auto"} value={value} setValue={setValue} tabs={tabs} t={t} />
        </Box>
        <CustomButton
          width="auto"
          height={40}
          customPadding="8px 16px 8px 12px"
          customBorderColor="#C9D0D8"
          customColor="#6D6777"
          customBgColor="transparent"
          customHoverColor="transparent"
          customTextTransform="none"
          onClick={() => setIsModalOpen(true)}
          customBorderRadius="8px"
          customFontSize="16px"
          textButton={t("copygoogleplay")}
          startIconSrc={
            <Box sx={{ marginRight: "7px" }}>
              <GooglePlayLogo />
            </Box>
          }
        />
      </Box>
      <Box
        component="div"
        sx={{
          marginRight: { xs: "0", md: "0" },
          maxWidth: "100%",
        }}
      >
        {renderTabContent()}
      </Box>
      <CopyGPlayModal open={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}
