import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { IPwa } from "interfaces/pwas";

interface Option {
  name: string | number;
  value?: string;
  label?: string;
}

interface CustomSelectComponentProps {
  options: Option[] | any;
  title?: string;
  value: string | number | IPwa[];
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  noBorder?: boolean;
  placeholder?: string;
  backgroundColor?: string;
  customComponentStyles?: Record<string, string>;
  name?: string;
  defaultValue?: string;
}

const CustomFormControl = styled(FormControl)<{ noBorder?: boolean }>(({ theme, noBorder }) => ({
  "& .MuiOutlinedInput-root": {
    height: 40,
    borderRadius: "8px",
    borderColor: noBorder ? "transparent" : undefined,
    "& fieldset": {
      borderColor: noBorder ? "transparent" : undefined,
    },
    "&:hover fieldset": {
      borderColor: noBorder ? "transparent" : "#F4701B",
    },
    "&.Mui-focused fieldset": {
      borderColor: noBorder ? "transparent" : "#F4701B",
    },
  },
}));

const CustomSelect = styled(Select)<{ backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  height: 40,
  backgroundColor: backgroundColor || "inherit",
  "& .MuiSelect-select": {
    fontSize: "14px",
    lineHeight: 1.43,
    fontFamily: "var(--cygreRegular), sans-serif",
    color: "#2E263D",
    padding: "0px 16px",
  },
  "& .MuiSelect-icon": {
    color: "#6D6777",
  },
}));

const CustomSelectComponent: React.FC<CustomSelectComponentProps> = ({
  options,
  title,
  value,
  onChange,
  noBorder = false,
  backgroundColor,
  placeholder,
  customComponentStyles,
  name,
  defaultValue,
}) => {
  return (
    <Box sx={{ minWidth: 120, width: "100%" }}>
      <CustomFormControl fullWidth noBorder={noBorder}>
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "12px",
            lineHeight: 1.22,
            textAlign: "left",
            paddingBottom: customComponentStyles?.paddingBottom ? customComponentStyles.paddingBottom : "10px",
          }}
          id="custom-select-label"
        >
          {title}
        </Typography>
        <CustomSelect
          labelId="custom-select-label"
          name={name}
          id="custom-select"
          value={value}
          onChange={onChange}
          displayEmpty={!!placeholder}
          backgroundColor={backgroundColor}
          defaultValue={defaultValue || ""}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              sx: {
                borderRadius: "8px",
                marginTop: "8px",
                maxHeight: 320,
                "& .MuiMenu-list": {
                  padding: "0px !important",
                  width: "100% !important",
                },
              },
            },
          }}
        >
          {placeholder && (
            <MenuItem sx={{ fontSize: "14px", fontStyle: "italic" }} value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options?.map((option: any) => (
            <MenuItem
              sx={{
                fontFamily: "var(--cygreRegular), sans-serif",
                color: "#000",
                fontSize: 14,
              }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </Box>
  );
};

export default CustomSelectComponent;
