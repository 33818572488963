import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { IPushNotification } from "interfaces/push";

import { formatTime } from "utils/formateDate";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import SuccessLinear from "assets/icons/SuccessLinear";
import CalendarClock from "assets/icons/CalendarClock";
import InProgress from "assets/icons/InProgress";
import PushButtons from "./PushButtons";
import dayjs from "dayjs";

const styleText = {
  fontSize: "14px",
  fontFamily: "var(--cygreMedium), sans-serif",
};

const NotificationCard = ({
  notification,
  getNotificationFetch,
  countryFlags,
}: {
  notification: IPushNotification;
  getNotificationFetch: () => void;
  icon: any;
  countryFlags: string[];
}) => {
  const { t } = useTranslation();
  const now = Math.floor(Date.now() / 1000);
  const { push_stats, status, schedule_date_timestamp, delivery_schedule_status, days_and_times, pushEvent } =
    notification;
  const { total_sent, views, clicks, closes, delivered, failed } = push_stats;

  let displayStatus = null;
  let isShowDelivered = false;

  if (status === "completed") {
    isShowDelivered = true;
    displayStatus = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SuccessLinear width={19} height={19} color={"#16B364"} />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#16B364",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("completed")}
        </Typography>
      </Box>
    );
  } else if (status === "scheduled" || schedule_date_timestamp >= now) {
    displayStatus = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CalendarClock />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#1B84FF",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("scheduled")}
        </Typography>
      </Box>
    );
  } else if (status === "in_progress") {
    displayStatus = (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InProgress />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#16B364",
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
          }}
        >
          {t("inProgress")}
        </Typography>
      </Box>
    );
  }

  // if (notification.canceled) {
  //   status = (
  //     <Box sx={{ display: "flex", alignItems: "center" }}>
  //       <Stopped />
  //       <Typography
  //         sx={{
  //           fontSize: "14px",
  //           fontWeight: "400",
  //           color: "#F4701B",
  //           display: "flex",
  //           alignItems: "center",
  //           paddingLeft: "5px",
  //           fontFamily: "var(--cygreRegular), sans-serif",
  //         }}
  //       >
  //         {t("stopped")}
  //       </Typography>
  //     </Box>
  //   );
  // } else if (notification.completed_at && notification.completed_at <= now) {
  //   status = (
  //     <Box sx={{ display: "flex", alignItems: "center" }}>
  //       <Success width={20} height={20} color={"#16B364"} />
  //       <Typography
  //         sx={{
  //           fontSize: "14px",
  //           fontWeight: "400",
  //           color: "#16B364",
  //           display: "flex",
  //           alignItems: "center",
  //           paddingLeft: "5px",
  //           fontFamily: "var(--cygreRegular), sans-serif",
  //         }}
  //       >
  //         {t("completed")}
  //       </Typography>
  //     </Box>
  //   );
  // } else if (!notification.completed_at && notification.send_after > now) {
  //   status = (
  //     <Box sx={{ display: "flex", alignItems: "center" }}>
  //       <CalendarClock />
  //       <Typography
  //         sx={{
  //           fontSize: "14px",
  //           fontWeight: "400",
  //           color: "#1B84FF",
  //           display: "flex",
  //           alignItems: "center",
  //           paddingLeft: "5px",
  //           fontFamily: "var(--cygreRegular), sans-serif",
  //         }}
  //       >
  //         {t("scheduled")}
  //       </Typography>
  //     </Box>
  //   );
  // }

  const eventKey = {
    install: t("eventInstall"),
    subscription: t("eventSubscriptionOption"),
    sale: t("eventSaleOption"),
    lead: t("eventLeadOption"),
  }[pushEvent || "install"];

  return (
    <Card
      tabIndex={-1}
      sx={{
        height: "100%",
        borderRadius: "8px",
        boxShadow: "none",
        // padding: 0,
        border: "1px solid #A5A3AE4D",
      }}
    >
      <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
        <Box sx={{ borderBottom: "1px solid #A5A3AE4D" }}>
          <Box sx={{ padding: "8px 16px", display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "56px", height: "56px", borderRadius: "50%" }}
              src={notification.icon}
              alt={notification.name}
            />
            <Box sx={{ paddingLeft: "12px" }}>
              <Typography sx={{ ...styleText }}>{notification.name || "-"}</Typography>
              <Typography sx={{ ...styleText, color: "#6D6777" }}>{displayStatus}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ borderBottom: "1px solid #A5A3AE4D", padding: "8px 16px" }}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography sx={{ ...styleText }}>PWA: {notification.name}</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "flex-start", minHeight: "23px" }}>
            {delivery_schedule_status === "interval" ? (
              <Typography sx={{ ...styleText }}>
                {t("intervalCampaign")}:{" "}
                {days_and_times.map((dt) => {
                  return `${t(dt.day_of_week.toLowerCase() + "Srt")}: ${dt.time}, `;
                })}
              </Typography>
            ) : null}
            {schedule_date_timestamp ? (
              <Typography sx={{ ...styleText }}>
                {t("nextPush")}: {dayjs(schedule_date_timestamp).format("DD.MM.YYYY HH:mm")}
              </Typography>
            ) : null}
            {delivery_schedule_status === "event" ? (
              <Typography sx={{ ...styleText }}>
                {t("eventDelivery")}:{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {eventKey}
                </span>
              </Typography>
            ) : null}
          </Box>
        </Box>

        <Grid
          container
          sx={{
            // display: "flex",
            // // flexDirection: "column",
            // justifyContent: "flex-start",
            alignContent: "flex-start",
            minHeight: "86px",
            borderBottom: "1px solid #A5A3AE4D",
            padding: "8px 16px",
          }}
        >
          {delivery_schedule_status !== "event" ? (
            <>
              <Grid size={4}>
                <BootstrapTooltip title={t("description_push_stats_total")} placement="top">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_total")}:</Typography>
                    <Typography sx={{ ...styleText }}>{total_sent || 0}</Typography>
                  </Box>
                </BootstrapTooltip>
              </Grid>
              <Grid size={4}>
                <BootstrapTooltip title={t("description_push_stats_delivered")} placement="top">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_delivered")}:</Typography>
                    <Typography sx={{ ...styleText }}>{isShowDelivered ? delivered : 0}</Typography>
                  </Box>
                </BootstrapTooltip>
              </Grid>
            </>
          ) : null}

          <Grid size={4}>
            <BootstrapTooltip title={t("description_push_stats_failed")} placement="top">
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_failed")}:</Typography>
                <Typography sx={{ ...styleText }}>{failed || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Grid>
          <Grid size={4}>
            <BootstrapTooltip title={t("description_push_stats_viewed")} placement="top">
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_viewed")}:</Typography>
                <Typography sx={{ ...styleText }}>{views || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Grid>
          <Grid size={4}>
            <BootstrapTooltip title={t("description_push_stats_clicked")} placement="top">
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_clicked")}:</Typography>
                <Typography sx={{ ...styleText }}>{clicks || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Grid>
          <Grid size={4}>
            <BootstrapTooltip title={t("description_push_stats_closes")} placement="top">
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_closes")}:</Typography>
                <Typography sx={{ ...styleText }}>{closes || 0}</Typography>
              </Box>
            </BootstrapTooltip>
          </Grid>
          <Grid size={4}>
            <BootstrapTooltip title={t("description_push_stats_ctr")} placement="top">
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography sx={{ ...styleText, paddingRight: "5px" }}>{t("push_stats_ctr")}:</Typography>
                <Typography sx={{ ...styleText }}>
                  {clicks && views ? ((clicks / views) * 100).toFixed(0) : 0}%
                </Typography>
              </Box>
            </BootstrapTooltip>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            padding: "8px 16px",
            borderBottom: "1px solid #A5A3AE4D",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ...styleText }}>{t("datecreatepush")}:</Typography>
            <Typography sx={{ ...styleText, paddingLeft: "4px" }}>{formatTime(notification.created_at)}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ...styleText }}>{t("countries")}:</Typography>
            <Box sx={{ display: "flex", gap: "3px", padding: "2px", flexWrap: "wrap" }}>
              {countryFlags?.length
                ? countryFlags.map((flag, index) => (
                    <>
                      <div key={index}>{flag}</div>
                    </>
                  ))
                : "—"}
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "16px" }}>
          <PushButtons notification={notification} getNotificationFetch={getNotificationFetch} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
