import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { useMessagesStore } from "store/userMessagesStore";
import useModalStore from "store/modalStore";
import useCautionStore from "store/cautionStore";
import { getToken } from "utils/getToken";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SelectPeriod from "components/Charts/SelectPeriod";
import { IListedDomain } from "interfaces/domains";
import { ITransaction } from "interfaces/stats";
import { IUser } from "interfaces/auth";
import AdminStatsWidget from "components/AdminStats/AdminStatsWidget";
import AdminStatsPieChart from "components/AdminStats/AdminStatsPieChart";
import NewUsersStats from "components/AdminStats/AdminNewUsersStats";
import DomainsStats from "components/AdminStats/AdminDomainsStats";
import useMediaByValue from "hooks/useMediaByValue";
import UserStatsModal from "components/AdminUserStatsModal";
import UsersStats from "components/AdminStats/AdminUsersStats";
import ReferralBonusRateModal from "components/AdminStats/AdminUsersStats/ReferralBonusRateModal";
import { toast } from "react-toastify";
import CautionModal from "components/CautionModal";
import { formatMMDDYYYY } from "utils/formateDate";
import InstallsWidget from "components/AdminStats/AdminInstallsWidget";

interface statsData {
  users: IUser[];
  domains: IListedDomain[];
  transactions: {
    installs: ITransaction[];
    deposits: ITransaction[];
    referrals: ITransaction[];
    promocodes: ITransaction[];
  };
}

const AdminPage = () => {
  const { pathname } = useLocation();
  const token = getToken();
  const { t } = useTranslation();
  const { saveUserMessages } = useMessagesStore();
  const { isUserStatsModalOpen, isReferralModalOpen, userInfo } = useModalStore();
  const { isUserDeleteModalOpen, userToDelete, handleDeleteUserChange, closeModal } = useCautionStore();
  const [statsData, setStatsData] = useState<statsData | null>(null);
  const [statsRange, setStatsRange] = useState<string>("7_days_ago");
  const [statsDateRange, setStatsDateRange] = useState<[string | null, string | null]>([null, null]);
  const isMediumViewPort = useMediaByValue("(min-width:920px)");
  const isLargeViewPort = useMediaByValue("(min-width:1120px)");

  const totalDepositsAmount = statsData?.transactions.deposits
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);
  const totalInstallsAmount = statsData?.transactions.installs
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);
  const totalReferralsBonusAmount = statsData?.transactions.referrals
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);
  const totalPromocodesBonusAmount = statsData?.transactions.promocodes
    .reduce((sum: number, transaction) => sum + transaction.amount, 0)
    .toFixed(2);

  const widgetStatsData = {
    deposits: totalDepositsAmount,
    installs: totalInstallsAmount,
    referrals: totalReferralsBonusAmount,
    promocodes: totalPromocodesBonusAmount,
  };

  const { loading: isStatsLoading, triggerFetch: getStats } = useFetch({
    url: `admin/stats`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      range: statsRange === "custom" ? statsDateRange : statsRange,
    },
  });

  const { triggerFetch: getUserMessages } = useFetch({
    url: "admin/messages",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading, triggerFetch: deleteUser } = useFetch({
    url: `admin/users/${userToDelete?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: updateBonusRate } = useFetch({
    url: `admin/users/${userInfo.id}/bonus-rate`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getAllStats = async () => {
    const { data } = await getStats();
    setStatsData(data);
  };

  const getAllUserMesagges = async () => {
    const { messages } = await getUserMessages();
    saveUserMessages(messages ? messages.reverse() : []);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setStatsDateRange([formatMMDDYYYY(dates[0]), formatMMDDYYYY(dates[1])]);
  };

  const handleUpdateUserReferralBonusRate = async () => {
    try {
      const newUserBonusRate = {
        referralDepositBonusRate: Number((userInfo.referralDepositBonusRate / 100).toFixed(2)),
        referralInstallBonusRate: Number((userInfo.referralInstallBonusRate / 100).toFixed(2)),
      };

      const updatedUserBonusRate = await updateBonusRate(newUserBonusRate);

      if (updatedUserBonusRate?.success) {
        getAllStats();
        toast.success(t("successfulReferralBonusRateChange"));
      } else {
        toast.error(t("unsuccessfulReferralBonusRateChange"));
      }
    } catch {
      toast.error(t("unsuccessfulReferralBonusRateChange"));
    }
  };

  const handleUserDelete = async () => {
    try {
      await deleteUser();
      getAllStats();
      handleDeleteUserChange(null);
      closeModal();
      toast.success(t("successfulUserDeletion"));
    } catch {
      toast.error(t("unsuccessfulUserDeletion"));
    }
  };

  useEffect(() => {
    void getAllUserMesagges();
    void getAllStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!statsData) {
      return;
    }

    void getAllStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsRange, statsDateRange]);

  return pathname === "/admin" ? (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}>
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "18px",
            lineHeight: "32px",
          }}
        >
          {t("statistics")}
        </Typography>

        <SelectPeriod
          selectedPeriod={statsRange}
          onChange={setStatsRange}
          onChangeDateRange={handleDateChange}
          isAdminStatsSelect
          customFormControlWidth="180px"
          customStyles={{
            backgroundColor: "#F6F7F9",
            boxShadow:
              "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
            width: "100%",
            "&.MuiInputBase-root": {
              margin: 0,
            },
          }}
        />
      </Box>
      <AdminStatsWidget isStatsLoading={isStatsLoading} statsData={widgetStatsData} />
      <Grid
        container
        spacing={3}
        sx={{ flexDirection: isLargeViewPort ? "row" : "column-reverse", marginBottom: "30px" }}
      >
        <InstallsWidget installsArr={statsData?.transactions.installs || []} />
        <AdminStatsPieChart statsData={widgetStatsData} />
      </Grid>
      <Grid
        container
        spacing={0}
        size={12}
        sx={{
          marginBottom: "50px",
          flexDirection: isMediumViewPort ? "row" : "column",
          borderRadius: "8px",
          boxShadow:
            "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
        }}
      >
        <NewUsersStats usersArr={statsData?.users || []} />
        <DomainsStats domainsArr={statsData?.domains || []} />
      </Grid>
      <UsersStats usersArr={statsData?.users || []} />
      {isUserStatsModalOpen && <UserStatsModal />}
      {isReferralModalOpen && <ReferralBonusRateModal handleBonusUpdate={handleUpdateUserReferralBonusRate} />}
      {isUserDeleteModalOpen && <CautionModal handleConfirm={handleUserDelete} isLoading={loading} />}
    </>
  ) : (
    <Outlet />
  );
};

export default AdminPage;
