import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const ChartLabelsList = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        columnGap: { xs: "12px", md: "20px" },
        rowGap: "12px",
        marginBottom: { xs: "12px", xl: 0 },
        padding: { xs: "0px 16px", md: 0 },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "#8C57FF",
          }}
        />
        <Typography sx={{ fontSize: "14px", lineHeight: 1.43 }}>{t("uniques")}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "#16B1FF",
          }}
        />
        <Typography sx={{ fontSize: "14px", lineHeight: 1.43 }}>{t("installs")}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "#56CA00",
          }}
        />
        <Typography sx={{ fontSize: "14px", lineHeight: 1.43 }}>{t("clicks")}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "#F4701B",
          }}
        />
        <Typography sx={{ fontSize: "14px", lineHeight: 1.43 }}>{t("regs")}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "#f1da06",
          }}
        />
        <Typography sx={{ fontSize: "14px", lineHeight: 1.43 }}>{t("deps")}</Typography>
      </Box>
    </Box>
  );
};

export default ChartLabelsList;
