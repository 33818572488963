import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import StatisticWidget from "components/StatisticWidget";
import AccountsTable from "components/AccountsTable";
import MyBarChart from "components/Charts";
import StatsMap from "components/StatsMap";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { ITransaction } from "interfaces/stats";
import { t } from "i18next";

export interface IPwaStatisticsItem {
  pwaId: string;
  installCount: number;
  totalAmount: number;
  icon: string;
  name: string;
  country: string;
  updatedAt: string;
  geo: string[];
}

const Dashboard = () => {
  const token = getToken();
  const [statistics, setStatistics] = useState<IPwaStatisticsItem[]>([]);
  const [userPwasCampaingIds, setUserPwasCampaingIds] = useState<Array<number>>([]);
  const [userPwasIds, setUserPwasIds] = useState<Array<string>>([]);
  const [installs, setInstalls] = useState<ITransaction[]>([]);

  const { triggerFetch } = useFetch({
    url: "statistics/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getUsersPwas } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getAllUserTransactions } = useFetch({
    url: "transaction",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getUsersCampaingsAndPwasIds = async () => {
    const result = await getUsersPwas();

    const usersPwaIdWithCampaings = result.data?.items
      ?.filter((pwa: any) => !!pwa.campaignId)
      .map((pwa: any) => pwa._id);

    setUserPwasIds(usersPwaIdWithCampaings);

    const campaingsIdArr = (result?.data?.items || [])
      .filter((pwa: any) => !!pwa.campaignId)
      .map((pwa: any) => pwa.campaignId);

    setUserPwasCampaingIds(campaingsIdArr);
  };

  const getUsersInstalls = async () => {
    const transactionsData = await getAllUserTransactions();
    const filteredInstalls = (transactionsData?.data.items || []).filter((item: ITransaction) => {
      return item.type === "pwa-install" && userPwasIds.includes(item.pwaId);
    });

    setInstalls(filteredInstalls);
  };

  async function getAccountsData() {
    const res = await triggerFetch();

    const sortedItems = res?.data?.items?.sort(
      (a: IPwaStatisticsItem, b: IPwaStatisticsItem) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
    setStatistics(sortedItems || []);
  }

  useEffect(() => {
    void getUsersCampaingsAndPwasIds();
    void getAccountsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userPwasIds.length === 0) {
      return;
    }
    void getUsersInstalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPwasIds]);

  return (
    <>
      <Box
        sx={{
          marginBottom: "24px",
        }}
      >
        <Typography
          sx={{
            marginBottom: "16px",
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "16px",
            lineHeight: "32px",
          }}
        >
          {t("weeklyStatistics")}
        </Typography>
        <StatisticWidget pwaIds={userPwasIds} usersCampaingIds={userPwasCampaingIds} installs={installs} />
      </Box>
      <StatsMap />
      <MyBarChart pwaIds={userPwasIds} usersCampaingIds={userPwasCampaingIds} installs={installs} />
      <AccountsTable statistics={statistics} />
    </>
  );
};

export default Dashboard;
