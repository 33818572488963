import { Box } from "@mui/material";
import { useState } from "react";
import Eye from "assets/icons/Eye";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { useTranslation } from "react-i18next";
import PushMockupModal from "components/PushMockupModal";
import { IPushNotification } from "interfaces/push";
import EditIcon from "assets/icons/EditIcon";
import Trash from "assets/icons/Trash";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import ToastMessage from "components/ToastMessage";
import { usePushStore } from "store/pushStore";
import { useNavigate } from "react-router";

interface IPushButtons {
  notification: IPushNotification;
  getNotificationFetch: () => void;
}

export default function PushButtons({ notification, getNotificationFetch }: IPushButtons) {
  const navigate = useNavigate();
  const { delivery_schedule_status, status } = notification;
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const token = getToken();
  const { savePushData } = usePushStore();

  const handlePreview = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenModal(true);
    savePushData(notification);
  };

  const { triggerFetch: deleteMessage } = useFetch({
    url: `push/${notification?._id}/${notification?.pwa_id}/${JSON.stringify(notification?.geo_filter)}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const handleDelete = async () => {
    try {
      const res = await deleteMessage();
      getNotificationFetch && getNotificationFetch();
      if (res.success) {
        ToastMessage({ mode: "info", message: t("stopedPush") });
      } else {
        ToastMessage({ mode: "error", message: t("errorStoppingPush") });
      }
    } catch (error) {
      ToastMessage({ mode: "error", message: t("errorStoppingPush") });
    }
  };

  // const handleStop = async (event: React.MouseEvent<HTMLDivElement>) => {
  //   event.stopPropagation();
  //   // onDeletePush();
  // };
  // const now = Math.floor(Date.now() / 1000);

  let canBeEdited = false;

  if (delivery_schedule_status === "scheduled" && !["completed"].includes(status)) {
    canBeEdited = true;
  }

  if (delivery_schedule_status === "interval" && status === "in_progress") {
    canBeEdited = true;
  }

  if (delivery_schedule_status === "event" && status === "in_progress") {
    canBeEdited = true;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "16px" }}>
        {/*{!notification.canceled && notification.send_after > now && (*/}
        {/*  <BootstrapTooltip title={t("stopPush")} placement="top">*/}
        {/*    <Box*/}
        {/*      sx={{*/}
        {/*        paddingRight: "16px",*/}
        {/*        display: "flex",*/}
        {/*        alignItems: "center",*/}
        {/*        justifyContent: "center",*/}
        {/*        cursor: "pointer",*/}
        {/*      }}*/}
        {/*      onClick={handleStop}*/}
        {/*    >*/}
        {/*      <StopIcon width={19} height={19} color={"#6D6777"} />*/}
        {/*    </Box>*/}
        {/*  </BootstrapTooltip>*/}
        {/*)}*/}

        {canBeEdited && (
          <BootstrapTooltip title={t("edit")} placement="top">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`edit/${notification._id}`);
              }}
            >
              <EditIcon color={"#6D6777"} />
            </Box>
          </BootstrapTooltip>
        )}

        <BootstrapTooltip title={t("gotopreview")} placement="top">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handlePreview}
          >
            <Eye color={"#6D6777"} />
          </Box>
        </BootstrapTooltip>
        <BootstrapTooltip title={t("delete")} placement="top">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleDelete}
          >
            <Trash color={"#6D6777"} />
          </Box>
        </BootstrapTooltip>
      </Box>

      <PushMockupModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
