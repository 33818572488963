import { useCallback, useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReplenishmentTable from "components/ReplenishmentTable";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import useFetch from "hooks/useFetch";
import TelegramIcon from "assets/icons/TelegramIcon";
import { getToken } from "utils/getToken";
import { PaymentStatusModal } from "components/PaymentStatusModal";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { useAuthStore } from "store/authStore";

interface IReplenishment {
  updatedAt: string;
  amount: string;
  type: string;
}

const buttonDollar = {
  padding: "0px 16px",
  background: "#F6F7FB",
  borderRadius: "8px",
  color: "#000",
  fontFamily: "var(--cygreRegular), sans-serif",
  fontSize: "16px",
  border: "2px solid transparent",
};

const selectedButtonStyle = {
  background: "rgba(244, 112, 27, 0.08)",
  border: "2px solid #F4701B",
};

function PaymentPage() {
  const { t, i18n } = useTranslation();
  const [replenishment, setReplenishment] = useState<IReplenishment[]>([]);
  const [selectedAmount, setSelectedAmount] = useState<string>("");
  const [unusedPromocode, setUnusedPromocode] = useState<string>("");
  const [bonus, setBonus] = useState<string>("");
  const token = getToken();
  const unusedPromoFieldText = `${t("unusedPromocode")} ${unusedPromocode})`;
  const { updateUserData } = useAuthStore();

  const handleButtonClick = (amount: string) => {
    setSelectedAmount(amount);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedAmount(value);
  };

  const handleBonusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setBonus(value);
  };

  const { triggerFetch } = useFetch({
    url: "invoice",
    method: "POST",
    body: { amount: parseFloat(selectedAmount), lang: i18n.language },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getWallet } = useFetch({
    url: "profile/wallet",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: loadingTransactions, triggerFetch: getTransactions } = useFetch({
    url: "transaction/deposit",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getReplenishment = async () => {
    const res = await getTransactions();

    if (res && res.data && res.data.items) {
      setReplenishment(res?.data?.items || []);
    }
  };

  const handleReplenishment = async () => {
    if (Number(selectedAmount) < 25) {
      toast.error(t("replenishmentAmounError"));
      return;
    }

    const res = await triggerFetch();

    if (res.success) {
      const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobileDevice) {
        window.location.href = res.data.url;
      } else {
        const url = new URL(res.data.url);
        window.open(url.toString(), "_blank", "noopener,noreferrer");
      }
    } else {
      toast.error(t("replenishmentError"));
    }
  };

  const {
    data: checkData,
    loading: promoCheckLoading,
    triggerFetch: checkCodeApi,
    error,
    setError,
  } = useFetch({
    url: "promo/check",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const checkCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const promoCode = e.target.value;
    if (!promoCode.length) {
      return;
    }
    await checkCodeApi({}, `promo/check/${promoCode}?locale=${i18n.language}`);
  };

  const checkPromoCodeDebounced = useCallback(debounce(checkCode, 500), []);

  const handlePromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleBonusChange(e);
    setError(null);
    checkPromoCodeDebounced(e);
  };

  const walletRequest = async () => {
    const res = await getWallet();
    if (res?.data) {
      updateUserData(res.data);
    }
  };

  const isPromoError = !bonus || Boolean(error);

  const addPromoBonus = debounce(async () => {
    if (isPromoError) {
      return;
    }

    const promocodeResponse = await checkCodeApi({}, `promo/confirm/${bonus}?locale=${i18n.language}`);

    if (promocodeResponse.success) {
      if (bonus === unusedPromocode) {
        localStorage.removeItem("unusedPromocode");
        setUnusedPromocode("");
      }
      setBonus("");
      setError("");
      await walletRequest();
      await getReplenishment();
      toast.success(t("successPromocodeProсessed"));
    } else {
      toast.error(t("unsuccessPromocodeProсessed"));
    }
  }, 500);

  useEffect(() => {
    const unusedPromocode = localStorage.getItem("unusedPromocode");

    if (unusedPromocode) {
      setBonus(unusedPromocode);
      setUnusedPromocode(unusedPromocode);
      void checkCodeApi({}, `promo/check/${unusedPromocode}?locale=${i18n.language}`);
    }

    void getReplenishment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <PaymentStatusModal />
      <Box
        sx={{
          width: { xs: "100%", lg: "58%" },
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          padding: "24px 20px",
        }}
      >
        <Typography
          sx={{
            marginBottom: "16px",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            color: "#2E263D",
          }}
        >
          {t("replenishmentofbalance")}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {["25", "30", "35", "40"].map((amount: string) => (
            <Button
              key={amount}
              sx={{
                ...buttonDollar,
                ...(selectedAmount === amount && selectedButtonStyle),
              }}
              onClick={() => handleButtonClick(amount)}
            >
              ${amount}
            </Button>
          ))}
        </Box>
        <Box>
          <Box
            sx={{
              paddingTop: "24px",
            }}
          >
            <Typography
              sx={{
                marginBottom: "16px",
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                fontWeight: 500,
                color: "#2E263D",
              }}
            >
              {t("replenishmentamount")}
            </Typography>
            <CustomInput
              required
              fullWidth
              id="replenishmentamount"
              height="40px"
              placeholder={t("enteramount")}
              name="replenishmentamount"
              value={selectedAmount}
              onChange={handleAmountChange}
              customBgColor="#F6F7F9"
              iconStart={"$"}
              onlyNumbersValues={true}
            />
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "0.75em",
                color: "black",
                opacity: "0.3",
                fontFamily: "var(--cygreBold)",
                marginLeft: "12px",
              }}
            >
              {t("minimumamount")} $25
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "24px 0 56px" }}>
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Typography
              sx={{
                marginBottom: "16px",
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                fontWeight: 500,
                color: "#2E263D",
              }}
            >
              {t("bonus")}
            </Typography>
            {unusedPromocode && (
              <Typography
                sx={{
                  fontFamily: "var(--cygreMedium), sans-serif",
                  fontSize: "12px",
                  lineHeight: "12px",
                  color: "#56CA00",
                }}
              >
                {unusedPromoFieldText}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ width: "100%", display: "flex" }}>
              <CustomInput
                required
                fullWidth
                id="bonus"
                height="40px"
                placeholder={t("enterpromo")}
                name="bonus"
                value={bonus}
                onChange={handlePromoCode}
                customBgColor="#F6F7F9"
                textTransform="uppercase"
                error={!!error}
                helperText={bonus ? checkData?.promoCode?.description || error || undefined : undefined}
                iconEnd={promoCheckLoading ? <CircularProgress size={24} color={"primary"} /> : null}
                style={{ marginTop: 0 }}
              />
              <Box
                component="button"
                sx={{
                  padding: "10px",
                  borderRadius: "8px",
                  background: !isPromoError ? "#F4701B" : "#F6F6F6",
                  border: 0,
                  cursor: !isPromoError ? "pointer" : "not-allowed",
                  pointerEvents: !isPromoError ? "auto" : "none",
                  outline: 0,
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "15px",
                }}
                disabled={isPromoError}
                onClick={addPromoBonus}
              >
                <TelegramIcon color={bonus ? "#fff" : "#D1D1D1"} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <Typography
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                color: "#2E263D",
              }}
            >
              {t("amount")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                color: "#2E263D",
              }}
            >
              ${`${selectedAmount || 0}`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
            <Typography
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                color: "#2E263D",
              }}
            >
              {t("bonus")}
            </Typography>

            <Typography
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                color: "#2E263D",
              }}
            >{`$${bonus && checkData?.promoCode ? checkData?.promoCode?.amount : 0}`}</Typography>
          </Box>
          <CustomButton
            width={"100%"}
            textButton={t("replenish")}
            height={40}
            customBgColor={"#F4701B"}
            customHoverColor={"#F4701B"}
            customBorderRadius="8px"
            customFontSize="14px"
            onClick={handleReplenishment}
            disabled={!selectedAmount}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", lg: "40%" },
          marginTop: { xs: "20px", lg: "0" },
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          padding: "24px 20px 10px 20px",
        }}
      >
        <Typography
          sx={{
            marginBottom: "24px",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            color: "#2E263D",
          }}
        >
          {t("historyreplenishment")}
        </Typography>
        <ReplenishmentTable isLoading={loadingTransactions} replenishment={replenishment} />
      </Box>
    </Box>
  );
}

export default PaymentPage;
