import React, { useEffect } from "react";
import { Select, MenuItem, FormControl, SelectChangeEvent, alpha, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePickerRange } from "components/DatePickers/DatePickerRange";

interface SelectPeriodProps {
  selectedPeriod: string;
  onChange: (period: string) => void;
  onChangeDateRange?: (dates: any) => void;
  setNeedNewReport?: (value: boolean) => void;
  setError?: (value: boolean) => void;
  error?: boolean;
  customStyles?: any;
  customFormControlWidth?: string;
  isBarChartSelect?: boolean;
  isAdminStatsSelect?: boolean;
  isAdminUserStatsSelect?: boolean;
}

const SelectPeriod: React.FC<SelectPeriodProps> = ({
  // error,
  // setError,
  setNeedNewReport,
  selectedPeriod,
  onChange,
  onChangeDateRange,
  customStyles,
  customFormControlWidth,
  isBarChartSelect = false,
  isAdminStatsSelect = false,
  isAdminUserStatsSelect = false,
}) => {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const hoverColor = "#F4701B";
  const hoverBackgroundColor = alpha(hoverColor, 0.1);

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string);

    if (setNeedNewReport) {
      setNeedNewReport(true);
    }

    if (event.target.value !== "custom") {
      onChangeDateRange?.([null, null]);
    } else {
      const dateNow = new Date();
      onChangeDateRange?.([dateNow, null]);
    }
  };

  const handleDateChange = (dates: any) => {
    onChangeDateRange?.(dates);

    // if (setError) {
    //   setError(!dates[0] || !dates[1]);
    // }
  };

  useEffect(() => {
    setShowDatePicker(selectedPeriod === "custom");
  }, [selectedPeriod]);

  return (
    <FormControl variant="outlined" sx={{ width: customFormControlWidth ? customFormControlWidth : "100%" }}>
      {showDatePicker && <DatePickerRange onChangeCallback={handleDateChange} />}
      <Select
        value={selectedPeriod}
        onChange={handleChange}
        MenuProps={{
          container: document.getElementById("root"),
        }}
        label={t("selectperiod")}
        startAdornment={
          <InputAdornment position="start">
            <CalendarMonthIcon
              sx={{
                width: "19px",
                height: "19px",
                marginRight: "5px",
                color: "#6D6777",
              }}
            />
          </InputAdornment>
        }
        sx={{
          position: "relative",
          backgroundColor: "#F6F7FB",
          color: "#6D6777",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "14px",
          boxShadow: "none",
          textTransform: "none",
          height: 40,
          width: { xs: "100%", md: "160px" },
          borderRadius: "8px",
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover": {
            backgroundColor: hoverBackgroundColor,
            color: hoverColor,
            "& .MuiSvgIcon-root": {
              color: hoverColor,
            },
          },
          ...customStyles,
        }}
      >
        {!isBarChartSelect && <MenuItem value="today">{t("thisday")}</MenuItem>}
        {isAdminUserStatsSelect || isAdminStatsSelect ? <MenuItem value="7_days_ago">{t("week")}</MenuItem> : null}
        <MenuItem value="1_month_ago">{t("thismonth")}</MenuItem>
        {!isBarChartSelect && <MenuItem value="1_year_ago">{t("thisyear")}</MenuItem>}
        {!isAdminUserStatsSelect && <MenuItem value="custom">{t("selectperiod")}</MenuItem>}
      </Select>
    </FormControl>
  );
};

export default SelectPeriod;
