import { useEffect, useState } from "react";
import { Box, IconButton, TablePagination, Typography } from "@mui/material";
import AdminUsersTab from "components/AdminStats/AdminUsersStats/AdminUsersTab";
import SearchBar from "components/SearchBar";
import { IUser } from "interfaces/auth";
import { useTranslation } from "react-i18next";
import useMediaByValue from "hooks/useMediaByValue";
import CustomDatePicker from "./CustomDatePicker";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

type DateRangeArray = [Date | string | null, Date | string | null];

interface IUsersStatsProps {
  usersArr: IUser[];
}

const UsersStats = ({ usersArr }: IUsersStatsProps) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [filter, setFilter] = useState<string>("");
  const [dateRange, setDateRange] = useState<DateRangeArray>([null, null]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const isLargeViewPort = useMediaByValue("(min-width:1120px)");

  const handleSearch = (value: string) => {
    setFilter(value);
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleDateRangeChange = (dates: DateRangeArray) => {
    setDateRange([dates[0], dates[1]]);
  };

  const handleUsersFilter = (usersArr: IUser[], searchFilter: string, dateRange: DateRangeArray) => {
    if (!searchFilter && dateRange[0] === null) {
      return usersArr;
    }

    let startDate: Date | null = null;
    let endDate: Date | null = null;

    const [firstDate, secondDate] = dateRange;

    if ((firstDate && !secondDate) || (firstDate && firstDate === secondDate)) {
      startDate = new Date(firstDate);
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(firstDate);
      endDate.setHours(23, 59, 59, 999);
    } else if (firstDate && secondDate) {
      startDate = new Date(firstDate);
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(secondDate);
      endDate.setHours(23, 59, 59, 999);
    }

    return usersArr.filter((user) => {
      const userCreatedAt = new Date(user.createdAt);

      const isWithinDateRange = startDate ? userCreatedAt >= startDate && userCreatedAt <= endDate! : true;

      const isMatchingSearch = searchFilter
        ? user.telegram?.toLowerCase().includes(searchFilter.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchFilter.toLowerCase())
        : true;

      return isWithinDateRange && isMatchingSearch;
    });
  };

  const handleFilterReset = () => {
    setFilter("");
    setDateRange([null, null]);
  };

  const filtredUsers = handleUsersFilter(users, filter, dateRange);
  const currentUsersRows = filtredUsers.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  useEffect(() => {
    setUsers(usersArr);
  }, [usersArr]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isLargeViewPort ? "row" : "column",
          justifyContent: "space-between",
          alignItems: isLargeViewPort ? "center" : "flex-start",
          marginBottom: "30px",
        }}
      >
        <Typography
          sx={{
            marginBottom: isLargeViewPort ? 0 : "30px",
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "18px",
            lineHeight: "32px",
          }}
        >
          {t("users")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: isLargeViewPort ? "auto" : "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "6px" }}>
            <SearchBar
              useSearchValue={true}
              searchValue={filter}
              onSearch={handleSearch}
              placeholder={t("search")}
              customStyles={{
                fontSize: 14,
                boxShadow:
                  "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
                "& svg": { width: "20px", height: "20px" },
              }}
            />
            <CustomDatePicker pageChange={setPage} onChangeCallback={handleDateRangeChange} />
            <BootstrapTooltip title={t("resetFilter")} placement="top">
              <IconButton
                onClick={handleFilterReset}
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "8px",
                  backgroundColor: "#F6F7FB",
                  boxShadow:
                    "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
                }}
              >
                <RestartAltIcon sx={{ color: "#6D6777" }} />
              </IconButton>
            </BootstrapTooltip>
          </Box>
          {!isLargeViewPort && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={filtredUsers.length}
              rowsPerPage={20}
              page={page}
              labelRowsPerPage={null}
              labelDisplayedRows={() => ""}
              onPageChange={handleChangePage}
              sx={{
                overflow: "hidden",
                "& .MuiTablePagination-spacer": {
                  display: "none",
                },
                "& .MuiTablePagination-toolbar": {
                  padding: 0,
                  justifyContent: "flex-start",
                },
                "& .MuiTablePagination-actions": {
                  margin: "0px !important",
                },
              }}
            />
          )}
        </Box>
        {isLargeViewPort && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={filtredUsers.length}
            rowsPerPage={20}
            page={page}
            labelRowsPerPage={null}
            labelDisplayedRows={() => ""}
            onPageChange={handleChangePage}
            sx={{
              "& .MuiTablePagination-spacer": {
                display: "none",
              },
              "& .MuiTablePagination-toolbar": {
                padding: 0,
                justifyContent: "flex-start",
              },
            }}
          />
        )}
      </Box>
      <AdminUsersTab
        totalUsers={filtredUsers.length}
        usersArr={currentUsersRows}
        page={page}
        handleChangePage={handleChangePage}
      />
    </>
  );
};

export default UsersStats;
