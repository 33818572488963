import CustomTable, { IColumn } from "components/CustomTable";

export interface IAccount {
  totalinstalls: number;
  country: string;
}

export interface IMapCountryTable {
  rows: IAccount[];
}

export default function MapCountryTable({ rows }: IMapCountryTable) {
  const filteredRows = rows.filter((row) => row.country.trim() !== "");

  const columns: IColumn[] = [
    {
      id: "country",
      label: "country",
      align: "center",
    },
    {
      id: "totalinstalls",
      label: "installs",
      align: "center",
    },
  ];

  return <CustomTable columns={columns} rows={filteredRows} />;
}
