import { useTranslation } from "react-i18next";
import { useAppStore } from "store/appStore";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CustomButton from "components/Button";
import { IconButton, SelectChangeEvent, Typography } from "@mui/material";
import CustomInput from "components/Input";
import CloseButtonIcon from "assets/icons/Close";
import useIsTablet from "hooks/useIsTablet";
import { useState } from "react";
import CustomSelectComponent from "components/Select";

interface IModalCheckDomain {
  open: boolean;
  setIsModalOpen: (value: boolean) => void;
}

interface SelectOption {
  name: string;
  value: string;
  label: string;
}

export default function CustomPostbackModal({ open, setIsModalOpen }: IModalCheckDomain) {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const [inputValue, setInputValue] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const [selectedMethod, setSelectedMethod] = useState<string>("GET");
  const [selectedStatus, setSelectedStatus] = useState<string>("lead");

  const methodSelectOptions: SelectOption[] = [
    { name: "get", value: "GET", label: "GET" },
    { name: "post", value: "POST", label: "POST" },
  ];

  const statusSelectOptions: SelectOption[] = [
    { name: "lead", value: "lead", label: "lead (registration)" },
    { name: "sale", value: "sale", label: "sale (deposit)" },
    { name: "rejected", value: "rejected", label: "rejected" },
    { name: "rebill", value: "rebill", label: "rebill" },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    const name = event.target.name as string;

    if (name === "method") {
      setSelectedMethod(value);
    } else {
      setSelectedStatus(value);
    }
  };

  const handleSubmit = () => {
    if (!inputValue) {
      setInputError(t("thisfieldisrequired"));
      return;
    }

    const newCustomPostback = {
      campaign_id: appData.campaignId || null,
      method: selectedMethod,
      statuses: [selectedStatus],
      url: inputValue,
    };

    if (!appData.customPostbacks || appData.customPostbacks.length === 0) {
      saveAppData({ customPostbacks: [newCustomPostback] });
    } else {
      const isPostbackRepeats = appData.customPostbacks.find((postback) => {
        return postback.url === inputValue;
      });

      if (isPostbackRepeats) {
        setInputError(t("repeatPostbackError"));
        return;
      }

      saveAppData({ customPostbacks: [...appData.customPostbacks, newCustomPostback] });
    }
    handleModalClose();
  };

  const handleModalClose = () => {
    setInputError("");
    setInputValue("");
    setSelectedMethod("GET");
    setSelectedStatus("lead");
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: "32px 24px 24px 24px",
          bgcolor: "#FFFFFF",
          boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
          width: "90%",
          maxWidth: "520px",
          height: "auto",
          maxHeight: "90dvh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16, width: 32, height: 32 }}
          aria-label="modal close button"
          onClick={handleModalClose}
        >
          <CloseButtonIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h2"
          sx={{
            marginBottom: "14px",
            fontSize: "24px",
            fontWeight: 400,
            color: "#2E263D",
          }}
        >
          {t("addCustomPostback")}
        </Typography>
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "14px",
            lineHeight: "1.35",
            fontWeight: 400,
            color: "#2E263D",
          }}
        >
          {t("custumPostbackModalDescription")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "24px" }}>
          <CustomInput
            required
            fullWidth
            id="linkapp"
            value={inputValue}
            placeholder=""
            name="linkapp"
            height="40px"
            label={t("postbackLink")}
            autoFocus={isTablet ? false : true}
            customBgColor="#F6F7FB"
            customFontSize="12px"
            helperText={t(inputError)}
            error={inputError ? true : false}
            onChange={handleInputChange}
          />

          <CustomSelectComponent
            title={t("requestMethod")}
            name="method"
            options={methodSelectOptions}
            value={selectedMethod}
            onChange={handleChange}
          />

          <CustomSelectComponent
            title={t("status")}
            name="status"
            options={statusSelectOptions}
            value={selectedStatus}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: isTablet ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          <CustomButton
            width="100%"
            height={40}
            textButton={t("cancel")}
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleModalClose}
          />
          <CustomButton
            width="100%"
            textButton={t("add")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Modal>
  );
}
