import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useVerticals from "mokcup/verticals";
import CustomButton from "components/Button";
import { IconButton, Typography } from "@mui/material";
import CloseButtonIcon from "assets/icons/Close";
import { useEffect, useState } from "react";
import CustomSelectComponent from "components/Select";
import PushTemplatesList from "./PushTemplatesList";
import { pushTemplates } from "./PushTemplates";
import { IPwa } from "interfaces/pwas";
import { usePushStore } from "store/pushStore";
import { IPushTemplate, IPushTemplateContent } from "interfaces/push";
import Preloader from "components/GlobalPreloader/Preloader";
import useMediaByValue from "hooks/useMediaByValue";

interface IPushModalProps {
  open: boolean;
  selectedPwa: IPwa | null;
  setIsModalOpen: (value: boolean) => void;
}

export default function PushTemplatesModal({ open, setIsModalOpen, selectedPwa }: IPushModalProps) {
  const { t } = useTranslation();
  const verticals = useVerticals();
  const { savePushData } = usePushStore();
  const isTablet = useMediaByValue("(min-width:768px)");
  const [filteredTemplates, setFilteredTemplates] = useState<IPushTemplate[]>([]);
  const [currentVertical, setCurrentVertical] = useState<string>("");
  const [currentPushEvent, setCurrentPushEvent] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const emptyTemplate = { title: "", text: "", index: null };
  const [selectedTemplateContent, setSelectedTemplateContent] = useState<IPushTemplateContent>(emptyTemplate);

  const categorySelectOptions = [{ value: "all", label: t("all"), id: "all" }, ...verticals];

  const eventsSelectOptions = [
    { value: "all", label: t("all"), id: "all" },
    { value: "lead", label: t("eventLeadOption"), id: "lead" },
    { value: "sale", label: t("eventSaleOption"), id: "sale" },
    { value: "subscription", label: t("eventSubscriptionOption"), id: "subscription" },
    { value: "install", label: t("eventInstall"), id: "install" },
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentVertical(selectedPwa?.categoryVertical || "");
    setCurrentPushEvent("");
    setSelectedTemplateContent(emptyTemplate);
  };

  const handlePushTemplateSave = () => {
    savePushData({ headings: selectedTemplateContent.title, contents: selectedTemplateContent.text });
    handleModalClose();
  };

  useEffect(() => {
    setCurrentVertical(selectedPwa?.categoryVertical || "");
  }, [selectedPwa]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      const filetredPushTemplates = pushTemplates.filter(
        (template: IPushTemplate) =>
          (currentVertical === "" || currentVertical === "all" || template.vertical === currentVertical) &&
          (currentPushEvent === "" || currentPushEvent === "all" || template.event === currentPushEvent)
      );
      setFilteredTemplates(filetredPushTemplates);
      setIsLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, [currentVertical, currentPushEvent]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-push-template-title"
      aria-describedby="modal-push-template-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: "24px",
          bgcolor: "#FFFFFF",
          boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
          width: "90%",
          maxWidth: "900px",
          height: "auto",
          maxHeight: "90dvh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16, width: 32, height: 32 }}
          aria-label="modal-close-button"
          onClick={handleModalClose}
        >
          <CloseButtonIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          sx={{
            marginBottom: { xs: "24px", md: "32px" },
            paddingRight: "14px",
            fontSize: { xs: "20px", md: "24px" },
            fontWeight: 400,
            lineHeight: 1.22,
            color: "#2E263D",
          }}
        >
          {t("pushTemlatesModalTitle")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "24px", md: "16px" },
            marginBottom: "24px",
          }}
        >
          <CustomSelectComponent
            title={t("category")}
            options={categorySelectOptions}
            placeholder={t("entercategory")}
            backgroundColor="#F6F7F9"
            noBorder={true}
            value={currentVertical}
            onChange={(event: any) => {
              setCurrentVertical(event.target.value);
              setSelectedTemplateContent(emptyTemplate);
            }}
          />
          <CustomSelectComponent
            title={t("events")}
            options={eventsSelectOptions}
            backgroundColor="#F6F7F9"
            noBorder={true}
            value={currentPushEvent}
            onChange={(event: any) => {
              setCurrentPushEvent(event.target.value);
              setSelectedTemplateContent(emptyTemplate);
            }}
            placeholder={t("selectEvent")}
          />
        </Box>
        {isLoading ? (
          <Preloader customStyles={{ border: "none", width: "100%", height: isTablet ? "540px" : "400px" }} />
        ) : (
          <PushTemplatesList
            templatesList={filteredTemplates}
            selectedPwa={selectedPwa}
            setTemplateContent={setSelectedTemplateContent}
            selectedTemplateIndex={selectedTemplateContent.index}
          />
        )}
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: { xs: "column-reverse", sm: "row" },
            alignItems: "center",
            marginTop: { xs: "24px", md: "32px" },
          }}
        >
          <CustomButton
            width="100%"
            height={40}
            textButton={t("cancel")}
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleModalClose}
          />
          <CustomButton
            width="100%"
            textButton={t("add")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handlePushTemplateSave}
          />
        </Box>
      </Box>
    </Modal>
  );
}
