import { ICreativeGroupItem } from "interfaces/creatives";
import { create } from "zustand";

interface IUserToDelet {
  id: string;
  email: string;
  telegram: string;
  telegramUsername: string;
}

interface IDomainInfo {
  name: string;
  id: string;
}

interface ModalState {
  isOpen: boolean;
  isDomainModalOpen: boolean;
  isDomainDeleteModalOpen: boolean;
  isUserDeleteModalOpen: boolean;
  isPromocodeDeleteModalOpen: boolean;
  isMessageDeleteModalOpen: boolean;
  isCreativeGroupDeleteModalOpen: boolean;
  pagePath: string | null;
  userToDelete: IUserToDelet | null;
  promocodeToDelete: string | null;
  domainToDelete: IDomainInfo | null;
  messageToDelete: string | null;
  creativeGroupToDelete: ICreativeGroupItem | null;
  openModal: () => void;
  closeModal: () => void;
  openDomainModal: () => void;
  openDomainDeleteModal: () => void;
  openUserDeleteModal: () => void;
  openPromocodeDeleteModal: () => void;
  openMessageDeleteModal: () => void;
  openCreativesGroupDeleteModal: () => void;
  handlePathChange: (path: string | null) => void;
  handleDeleteUserChange: (user: IUserToDelet | null) => void;
  handleDeletePromocodeChange: (promocode: string | null) => void;
  handleDomainToDeleteChange: (domainInfo: IDomainInfo | null) => void;
  handleMessageToDeleteChange: (messageId: string | null) => void;
  handleCreativeToDeleteChange: (creativeGroup: ICreativeGroupItem | null) => void;
}

const useCautionStore = create<ModalState>((set) => ({
  isOpen: false,
  isDomainModalOpen: false,
  isDomainDeleteModalOpen: false,
  isUserDeleteModalOpen: false,
  isPromocodeDeleteModalOpen: false,
  isMessageDeleteModalOpen: false,
  isCreativeGroupDeleteModalOpen: false,
  pagePath: null,
  userToDelete: null,
  promocodeToDelete: null,
  domainToDelete: null,
  messageToDelete: null,
  creativeGroupToDelete: null,
  openModal: () => set({ isOpen: true }),
  openDomainModal: () => set({ isDomainModalOpen: true }),
  openDomainDeleteModal: () => set({ isDomainDeleteModalOpen: true }),
  openUserDeleteModal: () => set({ isUserDeleteModalOpen: true }),
  openPromocodeDeleteModal: () => set({ isPromocodeDeleteModalOpen: true }),
  openMessageDeleteModal: () => set({ isMessageDeleteModalOpen: true }),
  openCreativesGroupDeleteModal: () => set({ isCreativeGroupDeleteModalOpen: true }),
  closeModal: () =>
    set({
      isOpen: false,
      isDomainModalOpen: false,
      isUserDeleteModalOpen: false,
      isPromocodeDeleteModalOpen: false,
      isDomainDeleteModalOpen: false,
      isMessageDeleteModalOpen: false,
      isCreativeGroupDeleteModalOpen: false,
    }),
  handlePathChange: (path) => set({ pagePath: path }),
  handleDeleteUserChange: (user) => set({ userToDelete: user }),
  handleDeletePromocodeChange: (promocode) => set({ promocodeToDelete: promocode }),
  handleDomainToDeleteChange: (domainInfo) => set({ domainToDelete: domainInfo }),
  handleMessageToDeleteChange: (messageId) => set({ messageToDelete: messageId }),
  handleCreativeToDeleteChange: (creativeGroup) => set({ creativeGroupToDelete: creativeGroup }),
}));

export default useCautionStore;
