import { ChangeEvent, useCallback, useState } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
import { Box, Button, Modal, Typography } from "@mui/material";
import CustomInput from "components/Input";
import { useTranslation } from "react-i18next";
import CustomSelectComponent from "components/Select";
import GeoMultiSelect from "components/GeoMultiSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomButton from "components/Button";
import useMediaByValue from "hooks/useMediaByValue";
import CustomPostbackModal from "./CustomPostbackModal";
import PostbacksList from "./PostbacksList";

const ModalTrack = () => {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const whites = [
    { value: "empty", label: t("setWhite") },
    { value: "white", label: "White page" },
    { value: "nutra", label: t("nutra") },
    { value: "betting", label: t("betting") },
    { value: "dating", label: t("dating") },
    { value: "gambling", label: t("gambling") },
    { value: "trading", label: t("trading") },
  ];

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const matchesWidth = useMediaByValue("(max-width:767px)");
  const [isPostbackModalOpen, setIsPostbackModalOpen] = useState<boolean>(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof AppDataMockup) => {
      const { value } = event.target;
      saveAppData({ [field]: value });
    },
    [saveAppData]
  );
  const offerLinkValue = appData.offerLink;

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>, field: keyof AppDataMockup) => {
    const { value } = event.target;
    saveAppData({ [field]: value as string });
  };

  const handleGeoChange = (selected: any) => {
    const geo = selected.map((country: any) => country.value);
    saveAppData({ geo });
  };
  return (
    <Box
      sx={{
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: { xs: "16px", md: "20px" },
      }}
    >
      <Typography
        sx={{
          marginBottom: "24px",
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "32px",
        }}
      >
        {t("tracking")}
      </Typography>
      <Box
        sx={{
          padding: "0px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <GeoMultiSelect onChange={handleGeoChange} geo={appData.geo} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px", width: "100%" }}>
          <CustomInput
            required
            fullWidth
            id="offerLink"
            height="40px"
            placeholder={t("enterLink")}
            name="offerLink"
            label={t("offerLink")}
            value={offerLinkValue}
            onChange={(e: any) => handleInputChange(e, "offerLink")}
            customBgColor="#F6F7F9"
          />
          <InfoOutlinedIcon sx={{ marginTop: "25px", cursor: "pointer", color: "#6d6777" }} onClick={handleOpenPopup} />
        </Box>
      </Box>
      <Box
        sx={{ margin: "10px 0px 24px 0px", fontStyle: "italic", fontSize: "12px", lineHeight: "1.24" }}
        dangerouslySetInnerHTML={{ __html: t("trackingShortDesc") }}
      />
      <CustomInput
        required
        fullWidth
        id="fbPixel"
        height="40px"
        placeholder={t("enterFbPixel")}
        name="fbPixel"
        label={t("fbPixel")}
        value={appData.fbpixel}
        onChange={(e: any) => handleInputChange(e, "fbpixel")}
        customBgColor="#F6F7F9"
      />
      <Box
        sx={{
          margin: "10px 0px 24px 0px",
          fontStyle: "italic",
          fontSize: "12px",
          lineHeight: "1.24",
        }}
        dangerouslySetInnerHTML={{ __html: t("facebookPixelShortDesc") }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <CustomInput
          required
          fullWidth
          id="fbToken"
          height="40px"
          placeholder={t("enterFbToken")}
          name="fbToken"
          label={t("fbToken")}
          value={appData.fbtoken}
          onChange={(e: any) => handleInputChange(e, "fbtoken")}
          customBgColor="#F6F7F9"
        />
        <CustomInput
          required
          fullWidth
          id="inappropriateTraffic"
          height="40px"
          placeholder={t("setInappropriateTraffic")}
          name="inappropriateTraffic"
          label={t("inappropriateTraffic")}
          value={appData.inappropriateTraffic}
          onChange={(e: any) => handleInputChange(e, "inappropriateTraffic")}
          customBgColor="#F6F7F9"
        />
        <CustomSelectComponent
          title={t("wightSource")}
          options={whites}
          backgroundColor="#F6F7F9"
          placeholder={t("selectWightSource")}
          noBorder={true}
          value={appData.wightSource}
          onChange={(e: any) => handleSelectChange(e, "wightSource")}
        />
        <CustomInput
          required
          fullWidth
          id="trafficBack"
          height="40px"
          placeholder={t("setTrafficBack")}
          name="trafficBack"
          label={t("trafficBack")}
          value={appData.trafficBack}
          onChange={(e: any) => handleInputChange(e, "trafficBack")}
          customBgColor="#F6F7F9"
        />

        <CustomButton
          textButton={t("addCustomPostback")}
          height={40}
          width={matchesWidth ? "100%" : "50%"}
          customBgColor="#F4701B"
          customHoverColor="#F4701B"
          customBorderRadius="8px"
          customFontSize="14px"
          customTextTransform="none"
          onClick={() => {
            setIsPostbackModalOpen(true);
          }}
        />
      </Box>

      {appData.customPostbacks.length !== 0 && <PostbacksList />}
      <Modal
        open={isPopupOpen}
        onClose={handleClosePopup}
        aria-labelledby="popup-title"
        aria-describedby="popup-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "5px",
            border: "1px solid",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="popup-title"
            variant="h6"
            component="h2"
            sx={{ fontFamily: "var(--cygreMedium), sans-serif", fontSize: "16px" }}
          >
            {t("addMacros")}:
          </Typography>
          <Typography
            id="popup-description"
            sx={{ mt: 2, fontFamily: "var(--cygreMedium), sans-serif", fontSize: "14px" }}
          >
            {`country={country}&city={city}&os={os}&os_version={os_version}&browser={browser}`}
          </Typography>
          <Button variant="contained" onClick={handleClosePopup} sx={{ mt: 2 }}>
            {t("close")}
          </Button>
        </Box>
      </Modal>
      <CustomPostbackModal open={isPostbackModalOpen} setIsModalOpen={setIsPostbackModalOpen} />
    </Box>
  );
};

export default ModalTrack;
