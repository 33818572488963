import Grid from "@mui/material/Grid2";
import Installs from "assets/icons/Installs";
import Opens from "assets/icons/Opens";
import Regs from "assets/icons/Regs";
import Ring from "assets/icons/Ring";
import Unique from "assets/icons/Unique";
import Preloader from "components/GlobalPreloader/Preloader";
import WidgetCard from "components/WidgetCard";
import useFetch from "hooks/useFetch";
import { useEffect } from "react";
import Cancel from "assets/icons/Cancel";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const StatisticPushWidget = () => {
  const token = getToken();
  const {
    triggerFetch: getOverallStats,
    data: stats,
    loading,
  } = useFetch({
    url: "push/stats/overall",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    void getOverallStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalCTR =
    stats?.totalClicks && stats?.totalDelivered ? ((stats?.totalClicks / stats?.totalDelivered) * 100).toFixed(0) : 0;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: { xs: "none", sm: "flex" },
        position: "relative",
        marginBottom: "24px",
      }}
    >
      {loading && (
        <Preloader
          customStyles={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            padding: "0",
            margin: "0",
            border: 0,
            background: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
      <Grid size={{ sm: 12 / 3, lg: 12 / 4, xl: 12 / 5 }}>
        <WidgetCard title={"pushOverallStatsTotalTitle"} value={stats?.totalSent || 0} icon={<Unique />} />
      </Grid>
      <Grid size={{ sm: 12 / 3, lg: 12 / 4, xl: 12 / 5 }}>
        <WidgetCard title={"pushOverallStatsDeliveredTitle"} value={stats?.totalDelivered || 0} icon={<Ring />} />
      </Grid>

      <Grid size={{ sm: 12 / 3, lg: 12 / 4, xl: 12 / 5 }}>
        <WidgetCard title={"pushOverallStatsViewedTitle"} value={stats?.totalViews || 0} icon={<Opens />} />
      </Grid>
      <Grid size={{ sm: 12 / 3, lg: 12 / 4, xl: 12 / 5 }}>
        <WidgetCard title={"pushOverallStatsClickedTitle"} value={stats?.totalClicks || 0} icon={<Installs />} />
      </Grid>
      <Grid size={{ sm: 12 / 3, lg: 12 / 4, xl: 12 / 5 }}>
        <WidgetCard
          title={"pushOverallStatsClosesTitle"}
          value={stats?.totalCloses || 0}
          icon={
            <div
              style={{
                width: "56px",
                height: "56px",
                backgroundColor: "rgba(255, 228, 225)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <Cancel color={"#A52A2A"} width={"40px"} height={"40px"} />
            </div>
          }
        />
      </Grid>
      <Grid size={{ sm: 12 / 3, lg: 12 / 4, xl: 12 / 5 }}>
        <WidgetCard title={"pushOverallStatsCTRTitle"} value={`${totalCTR}%`} icon={<Regs />} />
      </Grid>
    </Grid>
  );
};

export default StatisticPushWidget;
