import useMediaByValue from "hooks/useMediaByValue";
import React from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

interface IPhoneMockup {
  children: React.ReactNode;
}

const PhoneMockup: React.FC<IPhoneMockup> = ({ children }) => {
  const isMobile = useMediaByValue("(max-width:767px)");
  return (
    // @ts-ignore
    <DeviceFrameset device="Samsung Galaxy S5" color="black" width={isMobile ? 300 : 320} height={isMobile ? 470 : 568}>
      {children}
    </DeviceFrameset>
  );
};

export default PhoneMockup;
