import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useTranslation } from "react-i18next";
import SearchBar from "components/SearchBar";
import { fetchCountries } from "utils/fetchCountries";
// import FilterButton from "components/Filter/FilterButton";
// import { IAppData, ICountry, INotification, IPushTableProps } from "interfaces/push";
import NotificationsList from "./NotificationsList";
// import { AppDataMockup } from "interfaces/mockupApp";
import { styled } from "@mui/material/styles";
import { TablePagination, TablePaginationProps } from "@mui/material";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import Preloader from "components/GlobalPreloader/Preloader";
import { debounce } from "utils/debounce";

// const menuData = [
//   {
//     title: "status",
//     codeTop: "status",
//     subItems: [
//       { title: "stopped", id: "stopped", code: "stopped" },
//       { title: "completed", id: "completed", code: "completed" },
//       { title: "scheduled", id: "scheduled", code: "scheduled" },
//     ],
//   },
//   {
//     title: "vertical",
//     codeTop: "categoryVertical",
//     subItems: [
//       { title: "nutra", id: "3.1", code: "nutra" },
//       { title: "betting", id: "3.2", code: "betting" },
//       { title: "dating", id: "3.3", code: "dating" },
//       { title: "gambling", id: "3.4", code: "gambling" },
//       { title: "trading", id: "3.4", code: "trading" },
//     ],
//   },
//   { title: "country", codeTop: "country" },
//   { title: "date", codeTop: "date" },
//   { title: "conversion", codeTop: "conversion" },
// ];

const PushTable = () => {
  // const [dataPushes, setDataPushes] = useState<any>([]);
  // const [filteredPushes, setFilteredPushes] = useState<any>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  // const [statusFilter, setStatusFilter] = useState<string[]>([]); // Состояние для хранения выбранных фильтров по статусу
  // const [categoryVerticalFilter, setCategoryVerticalFilter] = useState<string[]>([]); // Состояние для хранения выбранных фильтров по categoryVertical
  const { t } = useTranslation();
  const countries = fetchCountries();
  const token = getToken();
  const LIMIT = 9;
  const [page, setPage] = useState(0);

  const {
    triggerFetch: getNotifications,
    loading,
    data: pushCampaigns,
  } = useFetch({
    url: `push/stats?limit=${LIMIT}&page=${page}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    void getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getNotificationFetch = useCallback((query?: string) => {
    const localPage = query && query.length ? 0 : page;
    let defaultString = `push/stats?limit=${LIMIT}&page=${localPage}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    if (query && query.length) {
      defaultString += `&searchQuery=${query}`;
    }
    void getNotifications(null, defaultString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("data", pushCampaigns);

  // useEffect(() => {
  //   setDataPushes(pushes);
  //   setFilteredPushes(pushes); // Обновление фильтрованных пушей вместе с основными
  // }, [pushes]);

  // useEffect(() => {
  //   let filtered = dataPushes;
  //
  //   // Поиск по названию
  //   if (searchQuery !== "") {
  //     filtered = filtered.map((item: { notifications: INotification[]; appData: IAppData }) => ({
  //       ...item,
  //       notifications: item?.notifications?.filter((notification: INotification) =>
  //         notification?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  //       ),
  //     }));
  //   }
  //
  //   // Фильтрация по статусу
  //   if (statusFilter.length > 0) {
  //     filtered = filtered.map((item: { notifications: INotification[]; appData: IAppData }) => ({
  //       ...item,
  //       notifications: item.notifications.filter((notification: INotification) => {
  //         if (statusFilter.includes("stopped") && notification.canceled) {
  //           return true;
  //         }
  //         if (statusFilter.includes("completed") && notification.completed_at) {
  //           return true;
  //         }
  //         if (statusFilter.includes("scheduled") && notification.send_after > Math.floor(Date.now() / 1000)) {
  //           return true;
  //         }
  //         return false;
  //       }),
  //     }));
  //   }
  //
  //   // Фильтрация по categoryVertical
  //   if (categoryVerticalFilter.length > 0) {
  //     filtered = filtered.filter((item: { notifications: INotification[]; appData: AppDataMockup }) => {
  //       console.log("appData structure:", item.appData);
  //       return categoryVerticalFilter.includes(item.appData.categoryVertical?.toLowerCase().trim());
  //     });
  //   }
  //
  //   setFilteredPushes(filtered);
  // }, [searchQuery, statusFilter, categoryVerticalFilter, dataPushes]);

  const handleSearch = debounce(async (query: string) => {
    getNotificationFetch(query);
  }, 500);

  // const handleApplyFilter = (filter: any) => {
  //   // Обновляем выбранные статусы фильтров
  //   if (filter?.status?.length > 0) {
  //     setStatusFilter(filter.status);
  //   } else {
  //     setStatusFilter([]);
  //   }
  //
  //   // Обновляем выбранные фильтры по categoryVertical
  //   if (filter?.categoryVertical?.length > 0) {
  //     setCategoryVerticalFilter(filter.categoryVertical.map((v: string) => v.toLowerCase().trim()));
  //   } else {
  //     setCategoryVerticalFilter([]);
  //   }
  // };

  // const displayPushes = filteredPushes.filter(
  //   (item: { notifications: INotification[]; appData: IAppData }) => item.notifications && item.notifications.length > 0
  // );

  const onPageChange = (_: any, newPage: any) => {
    setPage(newPage);
  };

  const Pagination = () => (
    <StyledTablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={pushCampaigns?.totalRecords || 0}
      rowsPerPage={LIMIT}
      page={pushCampaigns?.currentPage || 0}
      labelRowsPerPage={null}
      labelDisplayedRows={() => ""}
      onPageChange={onPageChange}
      sx={{
        "& .MuiTablePagination-spacer": {
          display: "none",
        },
        "& .MuiTablePagination-toolbar": {
          padding: 0,
          justifyContent: "flex-start",
        },
        "& .MuiTablePagination-actions": {
          marginLeft: "0px !important",
        },
      }}
    />
  );

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {loading && (
        <Preloader
          customStyles={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 2,
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            margin: 0,
            padding: 0,
          }}
        />
      )}
      <Grid
        size={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "normal", sm: "center" },
            width: "100%",
            marginBottom: { xs: "16px", lg: "20px" },
          }}
        >
          <SearchBar onSearch={handleSearch} placeholder={t("searchname")} />
          {/*<FilterButton*/}
          {/*  menuData={menuData}*/}
          {/*  filter={{ status: statusFilter, categoryVertical: categoryVerticalFilter }}*/}
          {/*  handleApply={() => {}}*/}
          {/*/>*/}
          {pushCampaigns?.data && pushCampaigns?.data?.length > 0 && (
            <Box sx={{ display: "flex", marginLeft: "auto" }}>
              <Pagination />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid container spacing={2}>
        {pushCampaigns?.data && pushCampaigns?.data?.length > 0 ? (
          <NotificationsList
            getNotificationFetch={getNotificationFetch}
            notifications={pushCampaigns?.data}
            countries={countries}
          />
        ) : (
          <Grid size={12}>
            <Typography variant="subtitle2" fontWeight={400}>
              {t("noitems")}
            </Typography>
          </Grid>
        )}
      </Grid>
      {pushCampaigns?.data && pushCampaigns?.data?.length > 0 && (
        <Box sx={{ display: "flex", marginTop: "16px" }}>
          <Pagination />
        </Box>
      )}
    </Box>
  );
};

const StyledTablePagination = styled(TablePagination)<TablePaginationProps>(({ theme }) => ({
  width: "100%",
  "& .MuiTablePagination-toolbar": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
    fontFamily: "var(--cygreMedium), sans-serif",
  },
  "& .MuiTablePagination-select": {
    fontFamily: "var(--cygreMedium), sans-serif",
    paddingRight: theme.spacing(2),
  },
  "& .MuiTablePagination-actions": {
    display: "flex",
    alignItems: "center",
  },
}));

export default PushTable;
