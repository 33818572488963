import Grid from "@mui/material/Grid2";
import Preloader from "components/GlobalPreloader/Preloader";
import AdminWidgetCard from "./AdminWidgetCard";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Deposits from "assets/icons/Deposits";

interface IAdminStatsWidgetProps {
  statsData: {
    deposits: any;
    installs: any;
    referrals: any;
    promocodes: any;
  };
  isStatsLoading: boolean;
}

const AdminStatsWidget = ({ statsData, isStatsLoading }: IAdminStatsWidgetProps) => {
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          position: "relative",
          marginBottom: "30px",
        }}
      >
        {isStatsLoading && (
          <Preloader
            customStyles={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              padding: "0",
              margin: "0",
              border: 0,
              background: "rgba(255, 255, 255, 0.6)",
            }}
          />
        )}
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <AdminWidgetCard
            title={"deposits"}
            value={statsData.deposits || 0}
            icon={<Deposits />}
            iconBoxBackground="rgba(86, 202, 0, 0.16)"
            iconColor="#56CA00"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <AdminWidgetCard
            title={"promocodes"}
            value={statsData.promocodes || 0}
            icon={<CardGiftcardIcon />}
            iconBoxBackground="#8C57FF29"
            iconColor="#8C57FF"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <AdminWidgetCard
            title={"referralBonuses"}
            value={statsData.referrals || 0}
            icon={<SelfImprovementIcon />}
            iconBoxBackground="rgba(255, 208, 0, 0.274)"
            iconColor="#FFBB00"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <AdminWidgetCard
            title={"installs"}
            value={statsData.installs || 0}
            icon={<PointOfSaleIcon />}
            iconBoxBackground="rgba(22, 177, 255, 0.16)"
            iconColor="#16B1FF"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdminStatsWidget;
