import { PriceCard } from "components/Price/Price";
import useCountriesForFirstRegion from "mokcup/regionFirst";
import useCountriesForSecondRegion from "mokcup/regionSecond";
import useCountriesForThirdRegion from "mokcup/regionThird";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

const ProfileTariffsPage = () => {
  const { t } = useTranslation();
  const countriesForFirstRegion = useCountriesForFirstRegion();
  const countriesForSecondRegion = useCountriesForSecondRegion();
  const countriesForThirdRegion = useCountriesForThirdRegion();

  const priceData = [
    { region: 1, price: "0,09", countries: countriesForFirstRegion },
    { region: 2, price: "0,07", countries: countriesForSecondRegion },
    { region: 3, price: "0,03", countries: countriesForThirdRegion },
  ];

  return (
    <Box>
      <Typography
        sx={{
          marginTop: { xs: "0px", md: "14px" },
          marginBottom: "24px",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "20px",
          fontWeight: 500,
          color: "#2E263D",
        }}
      >
        {t("tariffs")}
      </Typography>
      <Grid container spacing={2}>
        {priceData.map((item, index) => (
          <Grid size={{ xs: 12, lg: 12 / 2, xl: 12 / 3 }} key={index}>
            <PriceCard
              isLayout={false}
              region={item.region}
              price={item.price}
              countries={item.countries}
              showButton={false}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfileTariffsPage;
