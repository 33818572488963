import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import styled from "@emotion/styled";
import { IPwa } from "interfaces/pwas";
import showPreviewIcon from "utils/showPreviewImage";
import { RenderPWAStatus } from "components/PwaList/RenderPWAStatus";
import ActionButton from "components/ActionButton";
import MenuTableButton from "components/Table/MenuTableButton";
import { useTranslation } from "react-i18next";
import ImageIcon from "assets/icons/ImageIcon";
import { useNavigate } from "react-router";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import useIdStore from "store/pwaId";
import { useAppStore } from "store/appStore";

interface PwaListTableProps {
  filteredItems: IPwa[];
  getPwas: () => void;
}

export const PwaListTable = ({ filteredItems, getPwas }: PwaListTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = getToken();
  const { setId } = useIdStore();
  const { saveAppData } = useAppStore();
  const { triggerFetch: getPwa } = useFetch({
    url: "",
    method: "GET",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const handleEdit = async (id: string) => {
    const res = await getPwa(null, `pwa/${id}`);
    setId(res.data._id);
    saveAppData(res.data);
    navigate(`edit-pwa/${id}`);
  };

  return (
    <Box sx={{ width: "100%", overflowY: "auto" }}>
      <Table aria-label="simple table">
        <TableHead
          sx={{
            backgroundColor: "#F6F7FB",
          }}
        >
          <TableRow
            sx={{
              backgroundColor: "#F6F7FB",
              th: {
                borderBottom: "none",
                height: "52px",
              },
            }}
          >
            <TableCell sx={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
              <TypographyStyled variant="subtitle2">{t("pwa")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled variant="subtitle2">{t("status")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled variant="subtitle2">{t("domain")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled variant="subtitle2">{t("vertical")}</TypographyStyled>
            </TableCell>
            <TableCell>
              <TypographyStyled variant="subtitle2">{t("tracker")}</TypographyStyled>
            </TableCell>
            <TableCell sx={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
              <TypographyStyled variant="subtitle2" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Typography variant="subtitle2">{t("noitems")}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            filteredItems?.map(
              ({ _id, appName, creatives, categoryTracking, categoryVertical, domain, status, metaTags }: IPwa) => {
                return (
                  <TableRow key={_id} role="checkbox" tabIndex={-1}>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEdit(_id)}
                    >
                      {creatives.icon ? (
                        <img
                          src={showPreviewIcon(creatives.icon)}
                          alt="icon"
                          style={{
                            width: 40,
                            height: 40,
                            marginRight: "8px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            marginRight: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "& svg": { width: "34px", height: "34px" },
                          }}
                        >
                          <ImageIcon />
                        </Box>
                      )}

                      <Typography
                        sx={{
                          maxWidth: "170px",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: 1.43,
                          fontFamily: "var(--cygreRegular), sans-serif",
                          color: "#2e263d",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {appName}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {status && <RenderPWAStatus pwaStatus={status} />}
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: 1.43,
                          fontFamily: "var(--cygreRegular), sans-serif",
                          color: "#2e263d",
                        }}
                      >
                        {domain?.name || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                          lineHeight: 1.43,
                          color: "#2e263d",
                        }}
                      >
                        {categoryVertical || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                          lineHeight: 1.43,
                          color: "#2e263d",
                        }}
                      >
                        {categoryTracking || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ActionButton domain={domain?.name} getPwas={getPwas} status={status} id={_id} />
                        <MenuTableButton getPwas={getPwas} id={_id} pwaMeta={metaTags} />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            )
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

const TypographyStyled = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "var(--cygreRegular), sans-serif",
  textAlign: "left",
  color: "#6D6777",
});
