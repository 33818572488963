import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IWidgetCard {
  title: string;
  value: number | string;
  icon: any;
}

const WidgetCard = ({ title, value, icon }: IWidgetCard) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: "124px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3)",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography
          sx={{
            color: "#6D6777",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "15px",
            marginBottom: "8px",
          }}
        >
          {t(title)}
        </Typography>
        <Typography sx={{ color: "#2E263D", fontFamily: "var(--cygreRegular), sans-serif", fontSize: "32px" }}>
          {value}
        </Typography>
      </Box>
      <Box>{icon}</Box>
    </Box>
  );
};

export default WidgetCard;
