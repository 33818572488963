import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomTable, { IColumn } from "components/CustomTable";
import Preloader from "components/GlobalPreloader/Preloader";

interface IReplenishment {
  updatedAt: string;
  amount: string;
  type: string;
}

interface IReplenishmentTableProps {
  isLoading: boolean;
  replenishment: IReplenishment[];
}

const ReplenishmentTable = ({ isLoading, replenishment }: IReplenishmentTableProps) => {
  const { t } = useTranslation();

  const columns: IColumn[] = [
    {
      id: "dateofentry",
      label: t("dateofentry"),
      align: "left",
    },
    {
      id: "amount",
      label: t("amount"),
      align: "right",
    },
  ];

  const rows = replenishment
    .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
    .map((item) => ({
      dateofentry: moment(item.updatedAt).format("DD.MM.YYYY HH:mm:ss"),
      isPromoBonus: item.type === "promo-code-deposit" || item.type === "promo-code-register",
      amount: `$${item.amount}`,
    }));

  if (isLoading) {
    return <Preloader />;
  }

  if (replenishment.length === 0) {
    return (
      <Typography
        sx={{
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "14px",
          color: "#2E263D",
        }}
      >
        {t("noreplenishments")}
      </Typography>
    );
  }

  return <CustomTable enablePagination={true} columns={columns} rows={rows} />;
};

export default ReplenishmentTable;
