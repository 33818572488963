import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import ImageIcon from "assets/icons/ImageIcon";
import showPreviewIcon from "utils/showPreviewImage";
import { IPwa } from "interfaces/pwas";
import { IPushTemplate, IPushTemplateContent } from "interfaces/push";

interface IPushTemplatesListProps {
  templatesList: IPushTemplate[];
  selectedPwa: IPwa | null;
  selectedTemplateIndex: number | null;
  setTemplateContent: (tempalte: IPushTemplateContent) => void;
}

const PushTemplatesList = ({
  templatesList,
  selectedPwa,
  setTemplateContent,
  selectedTemplateIndex,
}: IPushTemplatesListProps) => {
  const { t } = useTranslation();

  const handleTemplateCardClick = (title: string, text: string, index: number) => {
    setTemplateContent({ title, text, index });
  };

  return (
    <Grid container spacing={2} sx={{ padding: "2px", height: { xs: "400px", md: "540px" }, overflowY: "auto" }}>
      {templatesList.length === 0 ? (
        <Grid size={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <Typography variant="subtitle2" fontWeight={400}>
            {t("noitems")}
          </Typography>
        </Grid>
      ) : (
        templatesList.map((template, index) => {
          const { title, text } = template;
          return (
            <Grid key={index} size={{ xs: 12, md: 6 }}>
              <Card
                sx={{
                  minHeight: "120px",
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3)",
                }}
              >
                <CardActionArea
                  onClick={() => handleTemplateCardClick(title, text, index)}
                  data-active={selectedTemplateIndex === index ? "" : undefined}
                  sx={{
                    height: "100%",
                    "& .MuiTouchRipple-root": {
                      color: "rgba(244, 112, 27, 0.1)",
                    },
                    "&[data-active]": {
                      backgroundColor: "rgba(244, 112, 27, 0.1)",
                      "&:hover": {
                        backgroundColor: "action.selectedHover",
                      },
                    },
                  }}
                >
                  <CardContent sx={{ display: "flex", gap: "8px", alignItems: "flex-start", height: "100%" }}>
                    {selectedPwa?.creatives.icon ? (
                      <img
                        src={showPreviewIcon(selectedPwa?.creatives.icon)}
                        alt="pwa-icon"
                        style={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          flex: "0 0 auto",
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 32,
                          height: 32,
                          background: "#F6F7FB",
                          borderRadius: "50%",
                          flex: "0 0 auto",
                        }}
                      >
                        <ImageIcon />
                      </Box>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "4px" }}>
                      <Typography sx={{ fontSize: "14px", lineHeight: 1.24, fontWeight: 500, color: "#2E263D" }}>
                        {title}
                      </Typography>
                      <Typography sx={{ fontSize: "14px", lineHeight: 1.24, color: "#2E263D" }}>{text}</Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default PushTemplatesList;
