import React, { useState, FormEvent, useCallback, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import imgSrc from "assets/imageAuth.png";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { saveToken, validateForm } from "utils";
import Email from "assets/icons/Email";
import Lock from "assets/icons/Lock";
import TelegramIcon from "@mui/icons-material/Telegram";
import GroupItems from "assets/icons/GroupItems";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ShowFalse from "assets/icons/ShowFalse";
import ShowTrue from "assets/icons/ShowYes";
import ToastMessage from "components/ToastMessage";
import globalLoaderStore from "store/globalLoaderStore";
import { useNavigate } from "react-router";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";

interface LoginProps {
  onClose: (value: boolean) => void;
  toggleMode: (newMode: string) => void;
}

interface IFormData {
  email: string;
  password: string;
  telegram: string;
  team: string;
  promoCode: string | undefined;
  referralCode: string;
  keitaro_subid: string | null;
}

export function Signup({ onClose, toggleMode }: LoginProps) {
  const setIsLoading = globalLoaderStore((state) => state.setIsGlobalLoading);
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const referralCode = searchparams.get("ref");
  const promoCode = searchparams.get("promo");
  const { t, i18n } = useTranslation();
  const keitaro_subid = localStorage.getItem("keitaro_subid");

  const [formData, setFormData] = useState<IFormData>({
    email: "",
    password: "",
    telegram: "",
    team: "",
    promoCode: undefined,
    referralCode: referralCode || "",
    keitaro_subid,
  });

  const [formDataError, setFormDataError] = useState({ email: "", password: "" });
  const [showPsw, setShowPsw] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPsw(!showPsw);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { loading, triggerFetch } = useFetch({
    url: "auth/register",
    method: "POST",
    body: {
      ...formData,
      locale: i18n.language,
    },
    context: { no401Redirect: true },
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const errors = validateForm(formData);
    if (errors.email || errors.password) {
      setFormDataError(errors);
      return;
    }
    setIsLoading(true);
    const result = await triggerFetch();
    if (result && result.success) {
      const token = result.data.token;
      const unusedPromocode = result.data.unusedPromocode;
      saveToken(token);

      if (unusedPromocode) {
        localStorage.setItem("unusedPromocode", unusedPromocode);
      }

      if (checkData?.success && checkData.promoCode.unusedPromocodeName) {
        localStorage.setItem("unusedPromocode", checkData.promoCode.unusedPromocodeName);
      }

      navigate("/dashboard");
      onClose(false);
      setIsLoading(false);
    } else {
      ToastMessage({ mode: "error", message: result?.message });
      setIsLoading(false);
    }
  };

  const {
    data: checkData,
    loading: promoCheckLoading,
    triggerFetch: checkCodeApi,
    error,
    setError,
  } = useFetch({
    url: "promo/check",
    method: "GET",
    context: { no401Redirect: true },
  });

  const checkCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const promoCode = e.target.value;
    if (!promoCode.length) {
      return;
    }
    await checkCodeApi({}, `promo/check/${promoCode}?locale=${i18n.language}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkPromoCodeDebounced = useCallback(debounce(checkCode, 500), []);

  const handlePromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e);
    setError(null);
    checkPromoCodeDebounced(e);
  };

  useEffect(() => {
    if (promoCode) {
      setFormData((prevState) => ({
        ...prevState,
        promoCode,
      }));

      void checkCodeApi({}, `promo/check/${promoCode}?locale=${i18n.language}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row-reverse" },
        alignItems: "center",
        textAlign: { xs: "center", md: "left" },
        width: "100%",
        maxWidth: { xs: "auto", md: "720px" },
        margin: "auto",
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center", mb: { xs: 2, md: 0 } }}>
        <img src={imgSrc} alt="signup-image" style={{ maxWidth: "250px", height: "auto" }} />
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ flex: 1, width: { xs: "100%", md: "auto" } }}>
        <Typography
          variant="subtitle1"
          style={{
            textAlign: "center",
          }}
        >
          {t("welcome")}
        </Typography>
        <Typography variant="subtitle2" sx={{ marginBottom: "16px" }}>
          {t("joininandcreateyourowncoolPWA")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "22px", marginBottom: "15px" }}>
          <CustomInput
            required
            fullWidth
            id="email"
            customFontSize="18px"
            placeholder={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={handleInputChange}
            error={!!formDataError.email}
            helperText={formDataError.email}
            iconStart={<Email />}
            customBgColor="#F6F7F9"
          />
          <CustomInput
            required
            customFontSize="18px"
            fullWidth
            id="password"
            placeholder={t("password")}
            name="password"
            type={showPsw ? "text" : "password"}
            autoComplete="current-password"
            value={formData.password}
            onChange={handleInputChange}
            error={!!formDataError.password}
            helperText={formDataError.password}
            iconStart={<Lock color="#000" width={19} height={20} />}
            iconEnd={
              <Box sx={{ cursor: "pointer", paddingTop: "10px" }} onClick={togglePasswordVisibility}>
                {showPsw ? <ShowTrue /> : <ShowFalse />}
              </Box>
            }
            customBgColor="#F6F7F9"
          />
          <CustomInput
            fullWidth
            id="telegram"
            placeholder={t("yourtelegram")}
            name="telegram"
            isRequired={false}
            customFontSize="18px"
            value={formData.telegram}
            onChange={handleInputChange}
            iconStart={<TelegramIcon sx={{ color: "#000" }} />}
            customBgColor="#F6F7F9"
          />
          <CustomInput
            fullWidth
            customFontSize="18px"
            id="team"
            isRequired={false}
            placeholder={t("nameofyourteam")}
            name="team"
            value={formData.team}
            onChange={handleInputChange}
            iconStart={<GroupItems />}
            customBgColor="#F6F7F9"
          />
          <CustomInput
            fullWidth
            customFontSize="18px"
            id="promoCode"
            isRequired={false}
            placeholder={t("promocodePlaceholderReg")}
            name="promoCode"
            value={formData.promoCode}
            onChange={handlePromoCode}
            error={!!error}
            helperText={formData?.promoCode ? checkData?.promoCode?.description || error || undefined : undefined}
            textTransform="uppercase"
            iconStart={
              <MonetizationOnOutlinedIcon
                style={{
                  color: "#000",
                }}
              />
            }
            iconEnd={promoCheckLoading ? <CircularProgress size={24} color={"primary"} /> : null}
            customBgColor="#F6F7F9"
          />
        </Box>
        <CustomButton
          loading={loading}
          customFontSize="18px"
          height={57}
          width="100%"
          loginDisabled={loading}
          textButton={t("signup")}
          customBgColor="#000"
          customHoverColor="#F4701B"
          lineHeight={2}
        />
        <Box sx={{ marginTop: "10px", cursor: "pointer" }} onClick={() => toggleMode("login")}>
          <Typography variant="subtitle2">{t("areyouwithusyetlogin")}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
