import { useTranslation } from "react-i18next";
import { useAppStore } from "store/appStore";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useFetch from "hooks/useFetch";
import CustomButton from "components/Button";
import { IconButton, Typography } from "@mui/material";
import CustomInput from "components/Input";
import { getToken } from "utils/getToken";
import CloseButtonIcon from "assets/icons/Close";
import useIsTablet from "hooks/useIsTablet";
import { useState } from "react";
import { toast } from "react-toastify";

interface IModalCheckDomain {
  open: boolean;
  setIsModalOpen: (value: boolean) => void;
}

export default function CopyGPlayModal({ open, setIsModalOpen }: IModalCheckDomain) {
  const token = getToken();
  const { saveAppData } = useAppStore();
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const [inputValue, setInputValue] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const appLocalStorageData = localStorage.getItem("pwa-tmp-storage") as string;
  const appData = JSON.parse(appLocalStorageData);
  const pwaId = appData?.state?.appData?._id;
  const googlePlaylinkRegex = /^https:\/\/play\.google\.com\/store\/apps\/details\?id=[a-zA-Z0-9._\-]+(&[\w=&]*)?$/;

  const { loading: loadingContentParse, triggerFetch: parseGooglePlayContent } = useFetch({
    url: "parse/google-play",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      pwaId: pwaId || null,
      googlePlayLink: inputValue,
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleSubmit = async () => {
    if (!inputValue) {
      setInputError("thisfieldisrequired");
      return;
    }

    if (!googlePlaylinkRegex.test(inputValue)) {
      setInputError("invalidTrafficLink");
      return;
    }

    const updatedAppContent = await parseGooglePlayContent();

    if (updatedAppContent.success) {
      saveAppData(updatedAppContent.data);
      handleModalClose();
      toast.success(t("successfulGoogleAppParse"));
    } else {
      toast.error(t("unsuccessfulGoogleAppParse"));
    }
  };

  const handleModalClose = () => {
    setInputError("");
    setInputValue("");
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-check-domain-title"
      aria-describedby="modal-check-domain-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: "32px 24px 24px 24px",
          bgcolor: "#FFFFFF",
          boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
          width: "90%",
          maxWidth: "520px",
          height: "auto",
          maxHeight: "90dvh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16, width: 32, height: 32 }}
          aria-label="modal close button"
          onClick={handleModalClose}
        >
          <CloseButtonIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h2"
          sx={{
            marginBottom: "14px",
            fontSize: "24px",
            fontWeight: 400,
            color: "#2E263D",
          }}
        >
          {t("сopyfromGooglePlay")}
        </Typography>
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "14px",
            lineHeight: "1.35",
            fontWeight: 400,
            color: "#2E263D",
          }}
        >
          {t("Insertthelink")}
        </Typography>
        <Box sx={{ marginBottom: "24px" }}>
          <CustomInput
            required
            fullWidth
            id="linkapp"
            value={inputValue}
            placeholder=""
            name="linkapp"
            height="40px"
            label={t("linkonapp")}
            autoFocus={isTablet ? false : true}
            customBgColor="#F6F7FB"
            customFontSize="12px"
            helperText={t(inputError)}
            error={inputError ? true : false}
            onChange={handleInputChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: isTablet ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          <CustomButton
            width="100%"
            height={40}
            textButton={t("cancel")}
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleModalClose}
          />
          <CustomButton
            width="100%"
            textButton={t("сopyimages")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleSubmit}
            loading={loadingContentParse}
          />
        </Box>
      </Box>
    </Modal>
  );
}
