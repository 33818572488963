import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";

interface ITab {
  icon: React.ReactNode;
  label: string;
}

interface ICustomTabs {
  value: number;
  setValue: (value: number) => void;
  tabs: ITab[];
  t: (key: string) => string;
  width: string;
  customTabWidth?: string;
}

const CustomTabs: React.FC<ICustomTabs> = ({ value, setValue, tabs, t, width, customTabWidth }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    localStorage.setItem("lastTabValue", newValue.toString());
    setValue(newValue);
  };

  const styleText = {
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: "14px",
    lineHeight: 1.43,
    textTransform: "none",
  };

  const tabStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    padding: "6px 12px 6px 8px",
    borderRadius: "8px",
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="icon position tabs example"
      variant="scrollable"
      scrollButtons={false}
      sx={{
        "& .MuiTabs-flexContainer": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "4px",
          height: "48px",
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          sx={{ width: customTabWidth || "auto", padding: 0 }}
          icon={
            <Box
              sx={{
                ...tabStyles,
                width: width,
                background: value === index ? "#fff" : "none",
                boxShadow: value === index ? "0 0 4px 0 rgba(165, 163, 174, 0.3)" : "none",
                minWidth: "fit-content",
              }}
            >
              {tab.icon}
              <Box
                sx={{
                  ...styleText,
                  color: value === index ? "#F4701B" : "#6d6777",
                }}
              >
                {t(tab.label)}
              </Box>
            </Box>
          }
          iconPosition="start"
          label=""
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
