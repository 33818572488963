import CustomButton from "components/Button";
import { Box, SelectChangeEvent, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Domain from "assets/icons/Domain";
import CustomSelectComponent from "components/Select";
import DomainTable from "./DomainsTable";
import useFetch from "hooks/useFetch";
import ModalBindDomain from "./ModalBindDomain";
import useSetupStore from "store/setupSettingsStore";
import ToastMessage from "components/ToastMessage";
import "styles/toastStyles.css";
import { useAuthStore } from "store/authStore";
import { getToken } from "utils/getToken";
import { useParams } from "react-router";
import { useAppStore } from "store/appStore";
import { IPwa } from "interfaces/pwas";

interface IFreeDomain {
  label: string;
  value: string;
  id: string;
  userId: string;
}

interface IDomain {
  name: string;
  listedDomain: string;
  createdAt: string;
  nsFirst: string;
  nsSecond: string;
  status: string;
  _id: string;
  pwa?: string;
}

export default function DomainTab({ setValue }: { setValue: (value: number) => void }) {
  const [listDomains, setListDomains] = useState<IDomain[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<string>("");
  const [freeDomains, setFreeDomains] = useState<IFreeDomain[]>();
  const [selectedFreeDomain, setSelectedFreeDomain] = useState<IFreeDomain>();
  const [pwas, setPwas] = useState<Array<IPwa>>([]);

  const { appData } = useAppStore();
  const { id: linkId } = useParams();
  const id = linkId || appData?._id;

  const showModal = () => {
    setOpenModal(true);
  };

  const setStepStatus = useSetupStore((state) => state.setStepStatus);
  useEffect(() => {
    setStepStatus("domain", "inprogress");
    return () => {
      setStepStatus("domain", "ready");
    };
  }, [setStepStatus]);
  const token = getToken();

  const { triggerFetch: buyAndAttachDomain } = useFetch({
    url: `list-domain/${selectedFreeDomain?.id}/buy-and-attach-to-pwa/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: buyDomain } = useFetch({
    url: `list-domain/${selectedFreeDomain?.id}/buy`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading, triggerFetch } = useFetch({
    url: "domain",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { triggerFetch: getFreeDomains } = useFetch({
    url: `list-domain/free`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getUserPwas } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getPwas = async () => {
    const result = await getUserPwas();
    setPwas(result?.data?.items || null);
  };

  async function getDomains() {
    const result = await triggerFetch();
    setListDomains(result?.data.items || []);

    const res = await getFreeDomains();
    const transformedFreeDomains = res.data.map((domain: any) => ({
      label: domain.name,
      value: `link.${domain.name}`,
      id: domain._id,
      userId: domain.userId,
    }));

    setFreeDomains(transformedFreeDomains);

    return result?.data.items;
  }

  useEffect(() => {
    void getDomains();
    void getPwas();
  }, []);

  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    const value = event.target.value as string;

    const selectedDomain = freeDomains?.find((domain) => domain.value === value);

    if (selectedDomain) {
      setSelectedFreeDomain(selectedDomain);
    }
    setSelectedDomain(`${value}`);
  };

  const { triggerFetch: getUserInfo } = useFetch({
    url: "profile/user-info",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { setUser } = useAuthStore();
  const userInfo = async () => {
    const res = await getUserInfo();
    if (res.data) {
      localStorage.setItem("userData", JSON.stringify(res.data));
      setUser(res.data);
    }
  };

  const handleSaveDomain = async () => {
    const requests = !id ? buyDomain : buyAndAttachDomain;
    if (!id && selectedFreeDomain?.id) {
      localStorage.setItem("tmpDomainID", selectedFreeDomain?.id);
    }
    const result = await requests();
    await userInfo();
    if (result) {
      const res = await setDomain();
      if (res.success) {
        const message = t("domainadded");
        ToastMessage({ mode: "info", message });
      } else {
        const message = t("domainerror");
        ToastMessage({ mode: "error", message });
      }
    } else {
      const message = result.message;
      ToastMessage({ mode: "error", message });
    }
    void getDomains();
  };

  const { triggerFetch: setDomain } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/domains",
      requestBody: {
        name: selectedDomain,
      },
    },
  });

  async function handleNext() {
    setValue(3);
    localStorage.setItem("lastTabValue", "3");
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: { xs: "column", xl: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", xl: "50%" },
            padding: { xs: "16px", md: "24px" },
            display: "flex",
            border: "1px solid #EEEEEE",
            borderRadius: "8px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "var(--cygreMedium), sans-serif",
                fontSize: "16px",
                lineHeight: "32px",
                marginBottom: { sx: "12px", md: "16px" },
              }}
            >
              {t("buyreadydomain")}
            </Typography>
            <Typography
              sx={{
                maxWidth: "320px",
                fontFamily: "var(--cygreRegular), sans-serif",
                color: "#6D6777",
                fontSize: "14px",
                lineHeight: "19px",
              }}
            >
              {t("pwadomainregistrationinfo")}
            </Typography>
            <Box sx={{ margin: "24px 0px 16px 0px" }}>
              <CustomSelectComponent
                title={t("domain")}
                placeholder={t("selectdomain")}
                options={freeDomains}
                value={selectedDomain}
                noBorder
                backgroundColor="#F6F7F9"
                onChange={handleChange}
              />
            </Box>
            <CustomButton
              textButton={t("buydomain")}
              customBgColor="#F4701B"
              customHoverColor="#f0670b"
              customBorderRadius="8px"
              height={40}
              width="100%"
              customFontSize="16px"
              onClick={handleSaveDomain}
              lineHeight="1.4"
              customTextTransform="none"
            />
          </Box>
          <Box sx={{ paddingLeft: "56px", paddingTop: "10px" }}>
            <Typography
              sx={{
                fontSize: { xs: "28px", md: "32px" },
                fontFamily: "var(--cygreRegular), sans-serif",
                paddingRight: "8px",
              }}
              component="span"
            >
              $1
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--cygreRegular), sans-serif",
                fontSize: { xs: "14px", md: "16px" },
                color: "#6d6777",
              }}
              component="span"
            >
              / 3 {t("months")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", xl: "50%" },
            padding: { xs: "16px", md: "24px" },
            display: "flex",
            flexDirection: "column",
            border: "1px solid #EEEEEE",
            borderRadius: "8px",
            marginLeft: { xs: 0, xl: "24px" },
            marginTop: { xs: "15px", xl: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              gap: { xs: "12px", md: "0px" },
              alignItems: { xs: "flex-start", md: "center" },
              paddingTop: "16px",
              paddingBottom: "32px",
            }}
          >
            <Typography
              sx={{
                color: "#2E263D",
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "var(--cygreMedium), sans-serif",
              }}
            >
              {t("yourdomainslist")}
            </Typography>
            <Box sx={{ width: { xs: "100%", md: "240px" }, height: "40px" }}>
              <CustomButton
                textButton={t("linkyourdomain")}
                customBgColor="inhirit"
                height={40}
                width="100%"
                customColor="#6D6777"
                customBorderColor="#C9D0D8"
                customHoverColor="transparent"
                customBorderRadius="8px"
                customFontSize="16px"
                customTextTransform="none"
                onClick={showModal}
              />
            </Box>
          </Box>

          <Box>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress
                  sx={{
                    color: "#F4701B",
                  }}
                />
              </Box>
            ) : listDomains.length > 0 ? (
              <DomainTable
                setOpenModal={setOpenModal}
                openModal={openModal}
                pwas={pwas}
                domains={listDomains}
                getDomains={getDomains}
                setDomains={setListDomains}
                buyTMPDomainId={!id ? selectedFreeDomain?.id : undefined}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <Domain color="#D1D1D1" width={44} height={44} />
                <Typography
                  sx={{
                    fontFamily: "var(--cygreMedium), sans-serif",
                    color: "#B4B1B9",
                    paddingTop: "16px",
                  }}
                >
                  {t("emptydomainslist")}
                </Typography>
              </Box>
            )}

            <ModalBindDomain open={openModal} setOpen={setOpenModal} getDomains={getDomains} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "160px", marginTop: "16px" }}>
        <CustomButton
          textButton={t("next")}
          height={40}
          customBgColor="#F4701B"
          customHoverColor="#F0670B"
          customBorderRadius="8px"
          customFontSize="14px"
          onClick={handleNext}
        />
      </Box>
    </>
  );
}
