import { usePromocodesStore } from "store/promocodesStore";
import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import { Collapse } from "@mui/material";
import PromocodeDataPicker from "components/AdminPromocodeCreateSection/PromocodeDataPicker";
import CustomButton from "components/Button";
import CustomInput from "components/Input";
import CustomSelectComponent from "components/Select";
import { amountSelectOptions, statusSelectOptions, typeSelectOptions } from "./PromocodesConstatns";
import { t } from "i18next";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction } from "react";
import Grid from "@mui/material/Grid2";

interface IPromocodeFormProps {
  isPromocodeFormOpen: boolean;
  handleIsNewPromocode: Dispatch<SetStateAction<boolean>>;
}

const PromocodeCreationForm = ({ isPromocodeFormOpen, handleIsNewPromocode }: IPromocodeFormProps) => {
  const token = getToken();
  const { promocodeData, savePromocodeData, clearPromocodeData } = usePromocodesStore();
  const { name, amount, type, status } = promocodeData;

  const { loading, triggerFetch: createPromocode } = useFetch({
    url: "admin/promocodes",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleFormChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "promocodeName") {
      const promocodeUpperCaseName = value.toUpperCase();
      savePromocodeData({ name: promocodeUpperCaseName });
    } else if (name === "amount") {
      savePromocodeData({
        amount: Number(value),
        description: {
          en: `Promo code entered correctly, +${value}$ available`,
          ru: `Промокод введён верно, доступно +${value}$`,
          ua: `Промокод введено правильно, доступно +${value}$`,
        },
      });
    } else if (name === "type") {
      savePromocodeData({ type: value });
    } else {
      savePromocodeData({ status: value });
    }
  };

  const handleSubmit = async () => {
    const newPromocode = await createPromocode(promocodeData);
    if (newPromocode.success) {
      handleIsNewPromocode(true);
      clearPromocodeData();
      toast.success(t("successfulPromocodeCreation"));
    } else {
      toast.error(t("unsuccessfulPromocodeCreation"));
    }
  };

  return (
    <Collapse in={isPromocodeFormOpen}>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        sx={{ marginBottom: "20px", width: { xs: "100%", md: "50%" }, alignItems: "flex-end" }}
      >
        <Grid size={{ xs: 12, lg: 6 }}>
          <CustomInput
            required
            fullWidth
            id="promocodeName"
            height="40px"
            placeholder={t("promocodeNameInputPlaceholder")}
            value={name}
            name="promocodeName"
            label={t("promocodename")}
            onChange={(event) => {
              handleFormChange(event);
            }}
            customBgColor="#F6F7F9"
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <CustomSelectComponent
            options={typeSelectOptions}
            name="type"
            placeholder={t("promocodeTypeSelectPalceholder")}
            title={t("type")}
            value={type}
            onChange={(event: any) => handleFormChange(event)}
            noBorder
            backgroundColor="#F6F7F9"
          />
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <CustomSelectComponent
            options={amountSelectOptions}
            name="amount"
            placeholder={t("promocodeAmountSelectPalceholder")}
            title={t("amount")}
            value={amount}
            onChange={(event: any) => handleFormChange(event)}
            noBorder
            backgroundColor="#F6F7F9"
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <CustomSelectComponent
            options={statusSelectOptions}
            name="status"
            placeholder={t("promocodeStatusSelectPalceholder")}
            title={t("status")}
            value={status}
            onChange={(event: any) => handleFormChange(event)}
            noBorder
            backgroundColor="#F6F7F9"
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <PromocodeDataPicker />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <CustomButton
            textButton={t("create")}
            height={40}
            width={"100%"}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="12px"
            customPadding="0"
            loading={loading}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default PromocodeCreationForm;
