import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: "none",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordion-root": {
    marginBottom: theme.spacing(1),
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "orange",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "19px",
  margin: "20px",
  padding: "0 16px",
  borderLeft: "2px solid #F4701B",
  h1: {
    color: "#000",
    fontWeight: "bold",
    marginBottom: "10px",
    lineHeight: "24px",
  },
  h2: {
    color: "#000",
    fontWeight: "bold",
    marginBottom: "10px",
    lineHeight: "22px",
  },
  h3: {
    color: "#000",
    fontWeight: "bold",
    marginBottom: "10px",
    lineHeight: "20px",
  },
  h4: {
    color: "#000",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  h5: {
    fontSize: 16,
    color: "#000",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  p: {
    marginBottom: "10px",
  },
  strong: {
    fontWeight: "bold",
  },
  ol: {
    listStyleType: "initial",
    paddingLeft: "14px",
    marginBottom: "10px",
  },
  li: {
    listStyleType: "initial",
    paddingLeft: "14px",
    marginBottom: "10px",
  },
}));

export default function ProfileFAQPage() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const activeTab = new URLSearchParams(search).get("tab") || false;
  const [expanded, setExpanded] = React.useState<string | false>(activeTab);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const titles = [t("faqTrackingTitle"), t("faqDomainTitle"), t("faqPushTitle")];
  const contents = [t("faqTrackingDescription"), t("faqDomains")];

  return (
    <>
      <Typography
        sx={{
          marginTop: { xs: "0px", md: "14px" },
          marginBottom: "24px",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "20px",
          fontWeight: 500,
          color: "#2E263D",
        }}
        variant="h5"
      >
        FAQ
      </Typography>
      <Box>
        {titles.map((title, index) => (
          <CustomAccordion
            key={`panel${index + 1}`}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <CustomAccordionSummary
              expandIcon={
                expanded === `panel${index + 1}` ? (
                  <RemoveIcon sx={{ color: "#F4701B" }} />
                ) : (
                  <AddIcon sx={{ color: "#F4701B" }} />
                )
              }
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography
                sx={{ paddingRight: "20px", fontSize: 18, fontFamily: "var(--cygreMedium), sans-serif", color: "#000" }}
              >
                {title}
              </Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails
              dangerouslySetInnerHTML={{
                __html: contents[index],
              }}
            ></CustomAccordionDetails>
          </CustomAccordion>
        ))}
      </Box>
    </>
  );
}
