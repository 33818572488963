"use client";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Eye from "assets/icons/Eye";
import PhonePushContent from "components/PhonePush";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PushIcon from "components/PushIcon";
import NewsletterContent from "components/NewsletterContent";
import PushButtons from "components/PushButtons";
import MainSection from "components/MainSection";
import Optional from "components/Optional";
import NewsletterSegmentation from "components/NewsletterSegmentation";
import { getToken } from "components/Stats";
import useFetch from "hooks/useFetch";
import PhoneMockup from "components/PhoneMockup";
import { usePushStore } from "store/pushStore";
import { useNavigate } from "react-router-dom";
import PushMockupModal from "components/PushMockupModal";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useParams } from "react-router";
import Preloader from "../../components/GlobalPreloader/Preloader";
import { transformBackendDataToPushData } from "../../utils/transformBackendDataToPushData";
import { DayKey } from "../../components/IntervalSets";
import { IPwa } from "interfaces/pwas";

function PushUpdatePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pushData, savePushData, clearPushData } = usePushStore();
  const [selectedPwa, setSelectedPwa] = useState<IPwa | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const token = getToken();
  const [isLoading, setIsLoading] = useState(false);

  const { triggerFetch } = useFetch({
    url: `push/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getPushData, error } = useFetch({
    url: `push/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (error) {
    navigate("/push");
  }
  async function fetchAndFillPushData() {
    setIsLoading(true);
    const response = await getPushData();
    if (!response.success) {
      navigate("/push");
    } else {
      const transformedData = transformBackendDataToPushData(response.pushNotification);
      savePushData(transformedData);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    void fetchAndFillPushData();
    return () => {
      clearPushData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartUp = async () => {
    const { pushName, headings, contents, pwaId, standartDate, standartTime } = pushData;
    let errorMessage = "";
    if (!pushName) {
      errorMessage = "Please select a Campaign name!";
    } else if (!headings) {
      errorMessage = "Please enter a title!";
    } else if (!contents) {
      errorMessage = "Please enter a message!";
    } else if (!pwaId) {
      errorMessage = "Please select PWA!";
    }

    if (errorMessage.length) {
      toast.error(errorMessage);
      return;
    }

    const pushToSend: any = pushData;

    const date = dayjs(standartDate);
    const isDateValid = date.isValid();

    if (isDateValid) {
      let hours = 0;
      let minutes = 0;

      if (standartTime !== "") {
        [hours, minutes] = standartTime.split(":").map(Number);
      }
      const scheduledDate = date.hour(hours).minute(minutes).second(0).millisecond(0);

      // Check if scheduled date is valid and in the future
      if (scheduledDate.isValid() && scheduledDate.isAfter(dayjs())) {
        pushToSend.scheduledDate = scheduledDate.valueOf();
      } else {
        console.error("Invalid or past scheduled date/time");
      }
    }
    const response = await triggerFetch(pushToSend);
    if (response?.success) {
      toast.success("Push notification updated successfully");
      navigate("/push");
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isLoading && (
          <Preloader
            customStyles={{
              position: "absolute",
              background: "rgba(255, 255, 255, 0.9)",
              width: "100%",
              height: "100%",
              zIndex: 2,
              top: "-15px",
              border: "none",
            }}
          />
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <PushIcon />
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <PushButtons useAltStyles={false} handleStartUp={handleStartUp} />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              paddingRight: { xs: "32px", md: "48px", xl: 0 },
            }}
          >
            <MainSection
              setSelectedPwa={setSelectedPwa}
              pwaID={pushData.pwaId}
              delivery={pushData.delivery}
              intervalDates={pushData.intervalDates as Record<DayKey, string | null>}
              scheduledTimestamp={pushData.schedule_date_timestamp}
              useUserTimezone={pushData.useUserTimezone}
            />
            <NewsletterContent selectedPwa={selectedPwa} />
            <Optional
              options={{
                notifyAgain: pushData.notifyAgain,
                awaitResponse: pushData.awaitResponse,
                silentMode: pushData.silentMode,
              }}
            />
            <NewsletterSegmentation />
            <PushButtons useAltStyles={true} handleStartUp={handleStartUp} />
          </Box>

          <Box
            sx={{
              display: { xs: "none", xl: "block" },
              position: "sticky",
              top: 0,
              marginLeft: "26px",
              padding: "50px",
              height: "fit-content",
              border: "1px solid #EEEEEE",
              borderRadius: "8px",
            }}
          >
            <PhoneMockup>
              <PhonePushContent isModal={false} />
            </PhoneMockup>
          </Box>

          <Box
            onClick={() => setOpenModal(true)}
            sx={{
              display: { xs: "flex", xl: "none" },
              minWidth: { xs: "40px", md: "48px" },
              height: { xs: "40px", md: "48px" },
              borderBottomLeftRadius: "8px",
              borderTopLeftRadius: "8px",
              background: "#F4701B",
              marginLeft: { xs: "-24px", md: "-24px" },
              marginRight: { xs: "-16px", md: "-24px" },
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "sticky",
              top: 0,

              "& svg": { width: { xs: "22px", md: "26px" }, height: { xs: "22px", md: "26px" } },
            }}
          >
            <Eye color={"#fff"} />
          </Box>
        </Box>

        <PushMockupModal openModal={openModal} setOpenModal={setOpenModal} />
      </LocalizationProvider>
    </div>
  );
}
export default PushUpdatePage;
