import React from "react";
import { TextField, TextFieldProps, InputAdornment, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface CustomInputProps extends Omit<TextFieldProps, "variant"> {
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  customBgColor?: string;
  customHoverColor?: string;
  customFontSize?: string;
  isRequired?: boolean;
  label?: string;
  height?: string;
  multiline?: boolean;
  rows?: number;
  textTransform?: string;
  helperText?: string;
  onlyNumbersValues?: boolean;
  customWidth?: string;
  customLablePadding?: string;
  customLableMargin?: string;
  error?: boolean;
  isReadOnly?: boolean;
  minHeight?: string;
}

const StyledTextField = styled(TextField)<{
  customBgColor?: string;
  customHoverColor?: string;
  customFontSize?: string;
  customWidth?: string;
  height?: string;
  minHeight?: string;
  textTransform?: string;
}>(({ customBgColor, customFontSize, customWidth, height, textTransform, minHeight }) => ({
  "& .MuiFormControl-root": {
    width: customWidth && "100%",
    // margin: "0px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    backgroundColor: customBgColor || "inherit",
    height: height || "auto",
    minHeight: minHeight || "40px",
  },
  "& .MuiInputAdornment-root": {
    marginRight: "8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "input, textarea": {
    padding: "0 16px",
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: customFontSize || "14px",
    lineHeight: 1.43,
    textTransform: textTransform || "none",
    color: "#2E263D",
    border: "none",
    "&::placeholder": {
      fontSize: "14px",
      lineHeight: 1.43,
      fontFamily: "var(--cygreRegular), sans-serif",
      textOverflow: "ellipsis !important",
      color: "#b4b1b9",
      opacity: 1,
    },
  },

  textarea: { padding: 0 },
}));

const CustomInput: React.FC<CustomInputProps> = ({
  iconStart,
  iconEnd,
  customBgColor,
  customHoverColor,
  customFontSize,
  height,
  isRequired = true,
  label,
  multiline = false,
  rows,
  textTransform,
  helperText,
  onlyNumbersValues,
  customWidth,
  customLablePadding,
  customLableMargin,
  error,
  isReadOnly,
  minHeight,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.key;

    const controlKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
    if (controlKeys.includes(keyCode)) {
      return;
    }

    if (!/^\d$/.test(keyCode)) {
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {label && (
        <Typography
          sx={{
            marginBottom: customLableMargin || "10px",
            paddingLeft: customLablePadding || "0px",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "12px",
            lineHeight: 1.22,
            textAlign: "left",
            color: "#2E263D",
          }}
        >
          {label}
        </Typography>
      )}
      {/*@ts-ignore*/}
      <StyledTextField
        slotProps={{
          input: {
            startAdornment: iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null,
            endAdornment: iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null,
            readOnly: isReadOnly ? true : false,
          },
        }}
        customBgColor={customBgColor}
        customFontSize={customFontSize}
        customWidth={customWidth}
        height={height}
        multiline={multiline}
        rows={multiline ? rows : undefined}
        textTransform={textTransform}
        {...rest}
        helperText={helperText}
        onKeyDown={onlyNumbersValues ? handleKeyPress : undefined}
        error={error}
        minHeight={minHeight}
      />
      {!isRequired && (
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "0.75em",
            color: "black",
            opacity: "0.3",
            paddingLeft: "18px",
            fontFamily: "var(--cygreBold)",
          }}
        >
          {t("notrequired")}
        </Typography>
      )}
    </Box>
  );
};

export default CustomInput;
