import { ChangeEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import useCategories from "mokcup/category";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";
import CustomSelectComponent from "components/Select";
import MultiSelect, { CountryOption } from "components/MultiSelect";
import { MultiValue } from "react-select";
import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import { toast } from "react-toastify";

interface IDescription {
  languages: any;
}

export interface ILang {
  name: string;
  code: string;
}

interface ITextToTranslateObject {
  description?: string;
  descriptionPC: string;
  adressCompany: string;
  category?: string[];
  comments?: string[];
}

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(() => ({
  color: "#C9D0D8",
  "&.Mui-checked": {
    color: "#F4701B",
  },
  "&:hover": {
    backgroundColor: "rgba(244, 112, 27, 0.08)",
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  "&.MuiFormControlLabel-root": { margin: "8px 0px 0px 0px" },
  "& .MuiFormControlLabel-label": {
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: "14px",
    color: "#2E263D",
  },
}));

export default function Description({ languages }: IDescription) {
  const token = getToken();
  const { t } = useTranslation();
  const { appData, saveAppData } = useAppStore();
  const { errors } = useErrorAppStore();
  const categories = useCategories();

  const { triggerFetch: translateAppContent } = useFetch({
    url: "deepl/translate-pwa-content",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  //TODO NEED TO BE DELETED ???
  // const getRandomDescription = async (selectedCategories?: string[] | string) => {
  //   const currentLanguage = i18n.language as "en" | "ru" | "uk";
  //   const categoryMap = categoryKeys[currentLanguage] || categoryKeys.en;

  //   const category = selectedCategories && selectedCategories.length ? selectedCategories : appData.category;
  //   const selectedCategoryDisplay = Array.isArray(category) ? (category.length > 0 ? category[0] : "") : category;

  //   // Преобразуем отображаемое значение в ключ с учетом текущего языка
  //   const selectedCategoryKey = categoryMap[selectedCategoryDisplay as keyof typeof categoryMap] || "";

  //   if (selectedCategoryKey && descriptions[selectedCategoryKey]) {
  //     const descrByCategory = descriptions[selectedCategoryKey];
  //     const translateKey = `randomDescr_${selectedCategoryKey}`;
  //     const translatedText = await translateText({ [translateKey]: descrByCategory }, appData.language as string);
  //     setDescription(translatedText[translateKey] || descrByCategory);
  //   } else {
  //     console.warn(`Description for category '${selectedCategoryDisplay}' not found`);
  //     setDescription("content is empty");
  //   }
  // };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  const handleLanguageChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    try {
      const textToTranslate: ITextToTranslateObject = {
        descriptionPC: appData.descriptionPC,
        adressCompany: appData.adressCompany,
      };

      if (appData.description.length) {
        textToTranslate.description = appData.description;
      }

      const commentsArr = appData.comments.map((comment) => {
        return comment.comment;
      });

      const categoryArr = appData.category;

      if (commentsArr.length !== 0) {
        textToTranslate.comments = commentsArr;
      }

      if (categoryArr.length !== 0) {
        textToTranslate.category = typeof appData.category === "string" ? [appData.category] : appData.category;
      }

      const response = await translateAppContent({ textToTranslate, targetLang: value });

      if (response.success) {
        const { translated } = response;
        const translatedComentsTextArr = translated.comments;
        const translatedcomments = appData.comments.map((comment, index) => {
          comment.comment = translatedComentsTextArr[index];
          return comment;
        });

        translated.category = typeof translated.category === "string" ? [translated.category] : translated.category;
        translated.comments = translatedcomments;

        saveAppData({ ...translated, language: value as string });
      } else {
        toast.error(t("unsuccessfulTranslated"));
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const handleCheckboxChange = (event: any, field: keyof AppDataMockup) => {
    const { checked } = event.target;
    saveAppData({ [field]: checked });
  };

  const handleMultiSelectChange = (selected: MultiValue<CountryOption>, field: keyof AppDataMockup) => {
    const selectedCategories = selected.map((option) => option.value);
    saveAppData({ [field]: selectedCategories as string[] });
    //TODO NEED TO BE DELETED ???
    // if (field === "category") {
    //   getRandomDescription(selectedCategories);
    // }
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        "&::before": { display: " none" },
      }}
    >
      <AccordionSummary
        sx={{ padding: { xs: "0px 16px", md: "0px 20px" }, height: { xs: "56px", md: "64px" } }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: "16px", fontFamily: "var(--cygreRegular), sans-serif", color: "#2e263d" }}>
          {t("main")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          maxWidth: "500px",
          padding: { xs: "0px 16px 16px 16px", md: "0px 20px 20px 20px" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <CustomInput
            required
            isRequired
            fullWidth
            id="author"
            height="40px"
            placeholder={t("developer")}
            name="author"
            label={t("author")}
            value={appData.author}
            onChange={(e) => handleInputChange(e, "author")}
            customBgColor="#F6F7F9"
            error={!!errors.author}
            helperText={errors.author}
          />

          <CustomSelectComponent
            options={
              languages &&
              languages.map((lang: ILang) => ({
                value: lang.code,
                label: lang.name,
              }))
            }
            name="language"
            placeholder={t("selectlanguage")}
            title={t("language")}
            value={appData.language}
            onChange={(e: any) => handleLanguageChange(e)}
            noBorder
            backgroundColor="#F6F7F9"
          />
          <MultiSelect
            options={categories}
            placeholder={t("entercategory")}
            title={t("category")}
            value={
              appData?.category
                ? appData?.category?.map((category) => ({
                    value: category,
                    label: category,
                  }))
                : []
            }
            onChange={(e) => handleMultiSelectChange(e, "category")}
            noBorder
            backgroundColor="#F6F7F9"
          />
          <CustomInput
            required
            fullWidth
            id="size"
            height="40px"
            placeholder={t("entersize")}
            name="size"
            label={t("size")}
            value={appData.size}
            error={!!errors.size}
            helperText={errors.size}
            onChange={(e) => handleInputChange(e, "size")}
            customBgColor="#F6F7F9"
            iconEnd={<Typography sx={{ color: "#B4B1B9", fontSize: "14px" }}>{t("mb")}</Typography>}
          />
          <CustomInput
            required
            fullWidth
            id="description"
            placeholder={t("enterdescription")}
            multiline
            rows={4}
            label={t("description")}
            value={appData.description}
            onChange={(e) => handleInputChange(e, "description")}
            customBgColor="#F6F7F9"
          />
        </Box>
        <CustomFormControlLabel
          checked={appData.ad}
          onChange={(e) => handleCheckboxChange(e, "ad")}
          control={<CustomCheckbox />}
          label={t("thereisanad")}
        />
      </AccordionDetails>
    </Accordion>
  );
}
