import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DrawIcon from "@mui/icons-material/Draw";
import useFetch from "hooks/useFetch";
import useCautionStore from "store/cautionStore";
import { getToken } from "utils/getToken";
import { ChangeEvent, useEffect, useState } from "react";
import CreativesList from "components/AdminCreativesList";
import { ICreativeGroupItem } from "interfaces/creatives";
import CustomInput from "components/Input";
import Search from "assets/icons/Search";
import CustomSelectComponent from "components/Select";
import useVerticals from "mokcup/verticals";
import CustomButton from "components/Button";
import CreativesCreateAndEditModal from "components/AdminCreativesModal";
import { toast } from "react-toastify";
import CautionModal from "components/CautionModal";
import Preloader from "components/GlobalPreloader/Preloader";

const CreativesPage = () => {
  const token = getToken();
  const { t } = useTranslation();
  const verticals = useVerticals();
  const { creativeGroupToDelete, isCreativeGroupDeleteModalOpen, closeModal, handleCreativeToDeleteChange } =
    useCautionStore();
  const verticalsSelectOptions = [{ value: "all", label: t("all"), id: "all" }, ...verticals];
  const [creativesGroups, setCreativesGroups] = useState<ICreativeGroupItem[]>([]);
  const [selectedVertical, setSelectedVertical] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [isCreativesModalOpen, setIsCreativesModalOpen] = useState<boolean>(false);
  const [creativeGroupeForEdit, setCreativeGroupeForEdit] = useState<ICreativeGroupItem | null>(null);

  const filteredCreativesGroupsByVertical = creativesGroups.filter((creativesGroup) => {
    if (selectedVertical === "" || selectedVertical === "all") {
      return true;
    }

    return selectedVertical === creativesGroup.group_type;
  });

  const filteredCreativeGroupsBySearchQuery = filteredCreativesGroupsByVertical.filter((creativesGroup) => {
    if (searchValue === "") {
      return true;
    }

    return creativesGroup.group_tags.some((tag) => {
      return tag.toLowerCase().includes(searchValue);
    });
  });

  const { loading: isCreativesGroupsLoading, triggerFetch: getCreativesGroups } = useFetch({
    url: "creatives/groups",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: creativesDeleteLoading, triggerFetch: creativeGroupeDelete } = useFetch({
    url: `admin/creatives/${creativeGroupToDelete?._id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getAllCreativesGroups = async () => {
    const { data } = await getCreativesGroups();
    setCreativesGroups(data ? data : []);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSelectedVertical(value);
  };

  const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
  };

  const handleCreativeGroupDelete = async () => {
    if (!creativeGroupToDelete) return;

    try {
      const { icon, previews } = creativeGroupToDelete.images;
      const creativesArrayToDelete = [...(icon ? [icon] : []), ...(Array.isArray(previews) ? previews : [])];

      await creativeGroupeDelete({ creativesArray: JSON.stringify(creativesArrayToDelete) });
      getAllCreativesGroups();
      handleCreativeToDeleteChange(null);
      closeModal();
      toast.success(t("successCreativeGropeDeletion"));
    } catch {
      toast.error(t("unsuccessCreativeGroupDeletion"));
    }
  };

  useEffect(() => {
    void getAllCreativesGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
        <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
          <DrawIcon sx={{ width: "36px", height: "36px", color: "#6D6777" }} />
          <Typography
            sx={{
              fontFamily: "var(--cygreRegular), sans-serif",
              fontSize: "20px",
              fontWeight: 500,
              color: "#2E263D",
            }}
          >
            {t("creatives")}
          </Typography>
        </Box>
        <CustomButton
          textButton={t("add")}
          height={40}
          width={"180px"}
          customBgColor="#F4701B"
          customHoverColor="#F4701B"
          customBorderRadius="8px"
          customFontSize="14px"
          customPadding="0"
          customTextTransform="none"
          onClick={() => setIsCreativesModalOpen(true)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "flex-end",
          gap: "12px",
          marginBottom: "24px",
        }}
      >
        <CustomSelectComponent
          title={t("category")}
          options={verticalsSelectOptions}
          placeholder={t("entercategory")}
          backgroundColor="#F6F7F9"
          noBorder={true}
          value={selectedVertical}
          onChange={(event: any) => handleSelectChange(event)}
        />
        <CustomInput
          required
          fullWidth
          id="search"
          height="40px"
          placeholder={t("contentSearch")}
          name="search"
          value={searchValue}
          onChange={(event: any) => {
            handleSearchValueChange(event);
          }}
          customBgColor="#F6F7F9"
          iconEnd={<Search />}
        />
      </Box>
      {isCreativesGroupsLoading ? (
        <Preloader customStyles={{ height: "auto", border: "none" }} />
      ) : (
        <CreativesList
          creativesArr={filteredCreativeGroupsBySearchQuery || []}
          setIsModalOpen={setIsCreativesModalOpen}
          setCreativesGroupForEdit={setCreativeGroupeForEdit}
        />
      )}
      {isCreativesModalOpen && (
        <CreativesCreateAndEditModal
          open={isCreativesModalOpen}
          setIsModalOpen={setIsCreativesModalOpen}
          setCreativesGroups={setCreativesGroups}
          creativeGroupeForEdit={creativeGroupeForEdit}
          setCreativesGroupForEdit={setCreativeGroupeForEdit}
        />
      )}
      {isCreativeGroupDeleteModalOpen && (
        <CautionModal handleConfirm={handleCreativeGroupDelete} isLoading={creativesDeleteLoading} />
      )}
    </>
  );
};

export default CreativesPage;
