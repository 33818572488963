import { Box } from "@mui/material";
import Preloader from "components/GlobalPreloader/Preloader";
import React, { useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

const Page: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const unusedPromocode = searchparams.get("unusedPromocode")?.toUpperCase();

  useEffect(() => {
    if (typeof token === "string") {
      localStorage.setItem("authToken", token);
      window.location.href = "/dashboard";

      if (unusedPromocode) {
        localStorage.setItem("unusedPromocode", unusedPromocode);
      }
    } else {
      navigate("/error");
    }
  }, [token, navigate]);

  return (
    <Box sx={{ height: "100vh" }}>
      <Preloader />
    </Box>
  );
};

export default Page;
