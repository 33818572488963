import { Box, Button, Typography } from "@mui/material";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { IPwa } from "interfaces/pwas";
import { useTranslation } from "react-i18next";
import PwaPerformance from "components/Table";
import useSetupStore from "store/setupSettingsStore";
import { useNavigate } from "react-router";
import { getToken } from "utils/getToken";

export default function PWAPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pwas, setPwas] = useState<Array<IPwa>>([]);
  const token = getToken();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);

  const { loading, triggerFetch } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    void getPwas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPwas() {
    const result = await triggerFetch();
    setPwas(result?.data?.items || null);
  }

  function CreatePWA() {
    navigate("/pwa/create");
    setSettingProcess(true);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: { xs: "24px", xl: "32px" },
        }}
      >
        <Typography variant="h4" sx={{ fontSize: "24px" }}>
          PWA
        </Typography>
        <Button
          sx={{
            color: "#fff",
            textTransform: "uppercase",
            backgroundColor: "#F4701B",
            "&:hover": {
              backgroundColor: "#F0670B",
            },
          }}
          className="create_btn"
          variant="contained"
          aria-label="create pwa"
          size="large"
          onClick={CreatePWA}
        >
          {t("createpwa")}
        </Button>
      </Box>

      <PwaPerformance getPwas={getPwas} loading={loading} pwas={pwas!} />
    </>
  );
}
