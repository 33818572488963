export const pushTemplates = [
  //Install event
  {
    title: "💎Welcome бонус казино 100%💎",
    text: "Регистрируйтесь, чтобы получить бонус на депозит 👇",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "😎 ЕЖЕДНЕВНЫЕ ТУРНИРЫ",
    text: "Ваш шанс получить выйгрыши 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎰250 ФРИСПИНОВ уже ждут!🎰",
    text: "Регестрируйтесь и заберайте подарки на депозит!🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "⚡Попробуй Gates of Olympus 1000",
    text: "💸Зевс умножает выйгрыши до х15 000!💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "⚽СПОРТИВНЫЙ БОНУС!⚽",
    text: "💵Получите до 30 000р для ставок на спорт💵",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Установка прошла успешно, теперь... 😊",
    text: "Регестрируйся и напиши свою собственную историю успеха! 🤑💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💸 Получите настоящие деньги! 🤑",
    text: "Поспеши, не пропусти свой шанс!🍀",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💪Alex287 ВЫИГРАЛ €15 000 с минимального депозита 🎁 ",
    text: "Это совсем не сложно! регестрируйся и забирай свой ДЖЕКПОТ! 😉",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "😱Секретная информация❗❗❗ ",
    text: "...в течение следующих двух часов в этом приложении джекпот может выиграть каждый второй игрок! Не упусти свой шанс!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎰Испытай удачу!🏹",
    text: "💸 Игры с крупными выигрышами ждут тебя!  💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "❗ВНИМАНИЕ❗ Места орграничены🏆",
    text: "Готов к эпической победе?💵 Присоединяйся к турнирам 😉👇🏻",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎰 Сегодня ты можешь соравть ДЖЕКПОТ🎰",
    text: "💰Регистрируйся, наслаждайся игрой и зарабатывай!🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🌟😱Ощути атмосферу настоящего казино!🌟",
    text: "🔥Нажимай и играй в Live-казино с непревзойденными дилерами!👇🏻🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "😎Logan073 выиграл 18 000 EURO c первого депозита!🔥",
    text: "🎫Регестрируйся и побеждай вместе с нами!!!🎫",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "😎Бонусы саме себя не заберут🤩",
    text: "Начинай свою выигрышную серию ставок! Бонусы повышают твои шансы на победу ⤴️😉",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Не упусти шанс сделать ставку сегодня...",
    text: "🎰Сегодня 185 игроков уже одержали победу!🏆",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎲Колесо фартуны улыбается тебе🎰",
    text: "❗️Каждая следующая ставка может быть выигрышной❗️ЗАХОДИ👇🏻",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Приложение для уверенных в себе людей!😎",
    text: "Лучшие условия от онлайн казино - только для пользователей этого приложении!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💵 Хорошая тема для заработка! 💵",
    text: "Регестрируйся и начинай зарабатывать!💰Заходи сейчас🤑",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Победа ждет тебя! 💰💰💰",
    text: "Сегодня победили 672 игрока! Ты следующий 🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Новый день, новые победы! 💰💰💰",
    text: "Это невероятно! Сегодня выигрывают 8 из 10 игроков! 🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "👑 !!! НЕТ ДЕПОЗИТА - НЕТ ВЕЧЕРИНКИ !!! 👑",
    text: "Регистрируйся и выиграй 🎰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💰 Разбогатей за день! 💰",
    text: "Интересный способ заработать деньги! 😄",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💰HIGHER VS LOWER - Быстрые деньги!",
    text: "Всего 1 кнопка отделяет тебя от победы!!!🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Из каждых 4 вращений 1 — выигрышный!😱",
    text: "Как можно упустить такую ​​возможность разбогатеть?💰💵💰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "ПОРА ИГРАТЬ⏱",
    text: "🍀 У нас новый победитель каждые 10 минут!🎉",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🤑👇🏻 Один шаг — и ты в игре...",
    text: "💸 Регистрируйся и получай доступ к ТОП-играм и слотам! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💵👇🏻 Те, кто зарегистрировался вчера, уже выигрывают сегодня!",
    text: "💸 Твое имя должно быть среди ПОБЕДИТЕЛЕЙ! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💶👇🏻 467 983 EUR! Здесь👇🏻 💶",
    text: "💸 ОГРОМНЫЕ шансы сорвать ДЖЕКПОТ для зарегистрированных пользователей! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎳 Удача на твоей стороне!",
    text: "💸 Много джекпотов на СУМАСШЕДШИЕ СУММЫ!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💜 Используй свой шанс на ПОБЕДУ!",
    text: "💎 РЕАЛЬНЫЕ ДЕНЬГИ, БЫСТРЫЕ ВЫПЛАТЫ! 💎",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💡 Приятное и прибыльное времяпрепровождение 🤑",
    text: "🎉 ЗАХОДИ В ИГРУ!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎰 Боишься проиграть и не заработать?",
    text: "🔥 Здесь выигрывают реальные деньги, ПРОВЕРЬ САМ!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "✌🏼 Думаешь, тебя уже не удивить слотами?",
    text: "💥 Кликни и убедись, что столько захватывающих игр ты еще не видел! 😱😱",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💙 72 часа без РЕГИСТРАЦИИ",
    text: "💎 Джекпот почти в твоих руках, РЕГИСТРИРУЙСЯ в 2 клика!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💰 Добро пожаловать в мир азарта и больших выигрышей! 🎉",
    text: "🎁 Лови мгновенный бонус при первом пополнении 👇",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "👉🏻 Максимальное погружение и бонусы ждут тебя! 👈🏻",
    text: "Выбирай среди множества игр и наслаждайся каждой ставкой 😉👇🏻",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "❗️ Это твой момент сорвать куш!",
    text: "Попробуй супер-хитовые слоты и увеличь свой выигрыш прямо сейчас! 🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💎 Фортуна улыбается именно тебе! 💎",
    text: "Начни игру и позволь удаче изменить твою жизнь навсегда! 🎰💰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "⚠️ Фриспины, бонусы и невероятные призы ждут тебя! ⚠️",
    text: "❗️ Все для твоего удовольствия 😉💸 Начни играть прямо сейчас👇🏻",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🥳 Создай праздник прямо сейчас!",
    text: "Окунись в азарт и почувствуй вкус настоящих побед 😊",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Зарегистрируйтесь и начните делать ставки! 🎯",
    text: "Все просто: регистрируйся, пополняй и начинай играть! 🎰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Регистрируйтесь и получайте удовольствие! 🤑",
    text: "Регистрируйтесь, вносите депозит и удваивайте деньги! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Зарегистрируйтесь и получите полный опыт! 🎮",
    text: "1 регистрация — бонус. Регистрируйся сейчас! 🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Регистрация занимает всего две минуты ⏳",
    text: "Все, что вам нужно сделать, это зарегистрироваться! 💥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Пройдите простую регистрацию сейчас! 📝",
    text: "Пора зарегистрироваться, чтобы получить бонус! 🎉",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Пройди регистрацию и выиграй немедленно! 🏆",
    text: "Зарегистрируйтесь здесь и начните выигрывать! 💎",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Регистрируйся, чтобы забрать бонус! 💰",
    text: "Все, что вам нужно сделать, это зарегистрироваться! 🏅",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Зарегистрируйтесь здесь! 🔑",
    text: "Мы ждем тебя, не упусти свой бонус! 🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Всем новичкам бонус! 🎉",
    text: "Зарегистрируйся и получи свой бонус прямо сейчас! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Добро пожаловать! 🎉",
    text: "Регистрируйтесь, получайте бонус и выигрывайте! 💰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💥 МЕГА-ДЖЕКПОТ — один спин! 🎰",
    text: "Присоединяйся и осуществи мечты в нашем КАЗИНО! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Каждая минута без регистрации — это потерянный шанс!",
    text: "Зарегистрируйся прямо сейчас и получи 81,000 EGP и 210 бесплатных спинов!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Хочешь потерять 210 бесплатных спинов?",
    text: "Зарегистрируйся сейчас, пока не поздно, и наслаждайся приветственным пакетом!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Крупные выигрыши ждут тебя!",
    text: "Не упусти шанс — зарегистрируйся сейчас и выиграй до 81,000 EGP!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Поторопись! Лучшее время для начала! 🏃‍♀️",
    text: "Последний шанс получить свой бонус в игре! 🤑",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Есть бонус! 💰",
    text: "Включено!!! Время испытать свою удачу! 🍀",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Выиграйте с нашим приветственным пакетом! 🎉",
    text: "Получи приветственный бонус на депозит, заходи и играй! 💰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Бонус на за регистрацию прямо сейчас! 💸",
    text: "Получите свой приветственный % бонус прямо сейчас! 🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Забери свой бонус после регистрации! 🎯",
    text: "Не забудьте получить бонус после регистрации! 🏆",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Получите свой бонус для новичков! 🎉",
    text: "Дарим 50 бездепозитных вращений за регистрацию! 🎰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Получите приветственный бонус! 💥",
    text: "Вы все еще не открыли свой приветственный пакет? 🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Получите бонус за регистрацию! 💎",
    text: "Получите 40 бесплатных вращений в игровом автомате! 🎰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Получите бесплатные вращения с бонусом! 🎰",
    text: "120 бесплатных вращений при регистрации сейчас! 🤑",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Ваши бесплатные вращения все еще ждут! ⏳",
    text: "Получите свой приветственный % бонус прямо сейчас! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "При регистрации полагается БОНУС! 💰",
    text: "Хотели бы вы получить 5% бонус за регистрацию? 🎁",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Бонус за регистрацию 200%! 🏅",
    text: "Получи приветственный бонус на депозит, заходи и играй! 💥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Зарегистрируйтесь и получите 225% бонус! 🎉",
    text: "Получите свой бонус для новичков и начните выигрывать! 💰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Что может быть лучше бесплатных вращений? 🎰",
    text: "Начните прямо сейчас и получите 100 бесплатных вращений! 💥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Регистрацию пройди — 50 фриспинов получи! 🎉",
    text: "Вы все еще не получили свои бесплатные вращения! 🤑",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Сегодня день бесплатных вращений! 🔥",
    text: "В игровом автомате вас ждут бесплатные вращения! 🎰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Получите свои БЕСПЛАТНЫЕ ВРАЩЕНИЯ! 💸",
    text: "Зарегистрируйтесь и сыграйте 1 раз за наш счет! 🎯",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Забери свой пакет новичка СЕЙЧАС!! 🎁",
    text: "Начните прямо сейчас и получите 100 бесплатных вращений! 💥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Начните играть в казино прямо сейчас! 🎰",
    text: "В нашем казино выигрывают самые большие джекпоты! 💰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Начните выигрывать прямо сейчас! 🏆",
    text: "Улучшите свой опыт с помощью нашего казино! 🎮",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Начните играть прямо сейчас! 🎯",
    text: "У нас есть более 2000 игр для вас в радость! 🎉",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Это игра, которую вы должны проверить! 🔥",
    text: "В нашем казино выигрывают самые большие джекпоты! 💥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Наступила новая эра слотов! 🎰",
    text: "Улучшите свой опыт с помощью нашего казино! 💎",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Джекпот для каждого игрока! 💰",
    text: "У нас есть более 2000 игр для вас в радость! 🤑",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Схема: Закинул → Поднял → Вывел! 💸",
    text: "В нашем казино выигрывают самые большие джекпоты! 🏅",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Выгодный приветственный пакет ждет вас! 🎁",
    text: "Казино предоставляет вам лучший вариант вывода средств! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "У вас еще есть время получить бонус! ⏳",
    text: "Удвойте свои деньги прямо сейчас! 🤑",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "За депозит даём бонусы! 💰",
    text: "Казино предоставляет вам лучший вариант вывода средств! 💳",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Начните прямо сейчас! 🎯",
    text: "Зарегистрируйтесь и начните выигрывать уже сегодня! 💥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Бонусы нужны? Заходи! 💰",
    text: "Заходи и оторвись по полной с новыми бонусами! 🎉",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Жду. После регистрации (Хочешь подарок? Заходи) 🎁",
    text: "Зарегистрируйтесь и начните выигрывать уже сегодня! 🏆",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Готов к игре? Поиграем? 🎮",
    text: "Заходи и оторвись по полной с новыми бонусами! 💸",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Играем? 🎯",
    text: "Зарегистрируйтесь и начните выигрывать уже сегодня! 🏅",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Играйте СЕЙЧАС! 🚀",
    text: "Заходи и оторвись по полной с новыми бонусами! 🎰",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "The installation was done. Now... 😊",
    text: "... register and rocket your profits 💸🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Win is one click closer! 🌈",
    text: "and let chance light up your day! ✨",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💥 BIG NEWS💥 Half our players win daily!",
    text: "Register and dominate the gaming world 💲👇🏻",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Victory is one tap closer! 🌟",
    text: "👑 Register quickly! Legal cash never been easier 💸🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "🎁 Unlock your first reward!!! 🚀",
    text: "Register and get access to all bonuses and promotions 😃",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Victory is one click closer to you! 🌈",
    text: "🎰 Register and maximize your income!",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "Victory is one tap closer! 🌟",
    text: "👑 Register quickly! Legal cash never been easier 💸🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "💰 One click to a million! 🚀",
    text: "Seize your opportunity at effortless cash! 🔥",
    vertical: "gambling",
    event: "install",
  },
  {
    title: "The installation was done. Now... 😊",
    text: "... register and rocket your profits 💸🔥 ",
    vertical: "gambling",
    event: "install",
  },

  //Register (lead) event

  {
    title: "⚡ЭКСПЕРСС ДНЯ!⚡",
    text: "Умножаем итоговый коэффициент на 1.15🚀",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "НЕ ПРОПУСТИ!😱",
    text: "Сегодня разыгрывается ДЖЕКПОТ!🔥",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "ВРЕМЯ ЗАРАБАТЫВАТЬ ДЕНЬГИ!🤑",
    text: "Сделайте ДЕПОЗИТ и получите БОНУСЫ🎁",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Хочешь много денег?💰",
    text: "Выигрывай и исполни свою мечту!🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "УДАЧА ЛЮБИТ СМЕЛЫХ!💪",
    text: "Пока ты думаешь, другие выигрывают деньги!💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "⚡Попробуй Gates of Olympus 1000",
    text: "💸Зевс умножает выйгрыши до х15 000!💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "❗МНОЖЕТЕЛЬ ВЫЙГРЫША Х25 000❗",
    text: "🎊Сумашедшие выйгрыши в Crazy Time!🎊",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎БОНУС НА ПЕРВЫЙ ДЕПОЗИТ!💎",
    text: "Внесите первый депозит и забирайте бонусы!🎁",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "САМОЕ ЩЕДРОЕ КАЗИНО!🤩",
    text: "Играйте и выинрывайте БОЛЬШИЕ ДЕНЬГИ💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😎Приветствуем в мир азарта и богатства!🤑",
    text: "Мгновенные бонусы за первый депозит. Поторопись!🎁",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Все будут в шоке от твоих побед!😱",
    text: "Крупные денежные выйгрыши ждут тебя!👇💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🔝 Будь в числе первых! 💪🏻",
    text: "⚽Играй и выигрывай в наших ежедневных турнирах!💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Исполни свою мечту в нашем КАЗИНО 💹",
    text: "💰Реальные выйгроши крупных призов, только в наших турнирах!🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Фортуна, удача и успех! 🔥",
    text: "👉Все это ждет тебя в нашей игре!!! Вступай! 👇🏻",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵Легких денег не бывает❓❓❓ 😛",
    text: "🤩Пока ты думаешь, остальные выигрывают реальные деньги 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎲Фортуна любит смелых!💪",
    text: "😎Стань хозяеном своей жизни, с помощью нашего КАЗИНО!💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🔥Розыгрыш, который перешел все границы!😱",
    text: "Вноси депозит до конца этого дня!Повышенный коэффициент только сегодня 😉💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🕹Лучшие игры в лучшем КАЗИНО🕹",
    text: "💪Большие победы и море эмоций, только в наших турнирах!!! 🎉",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Не упусти свой ШАНС❗❗❗",
    text: "🎰Прямо сейчас удвоенные выграши в наших горячих слотах!🔥",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😱 НЕПРИЛИЧНО БОЛЬШОЙ КУШ 😱",
    text: "Скорее заходи 👇🏻, именно ты можешь сорвать ДЖЕКПОТ💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰💥ВА-БАНК!💥💵",
    text: "😎Историю пишут победители! Стань одним из них прямо сейчас!💪",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💪 Игрок только что унес €40 000💥",
    text: "😎Пока ты сомневаешься, наши игроки срываю КУШ🤩",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😍 НОВЫЕ ИГРОКИ! Увеличенный % побед 🏆",
    text: "🤑 Внесите свой первый депозит и выиграйте",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💥Крупные игроки входят по субботам!",
    text: "📲После депозита можно больше не работать!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "👉 ВЫ: внесите депозит в размере 10 евро или более 💳",
    text: "👉 МЫ: Мы удвоим твой депозит 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Приветственный бонус 100% 💰 от 100$  🎰",
    text: "Не ждите, сделайте депозит сейчас 🔥",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Постоянный Кэшбэк 30% для тебя...🔥",
    text: "💰Пополните счет сегодня до полуночи и получите бонус",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Каждые 10 минут у нас новый победитель! 🎉",
    text: "Почему бы и нет?😎 Давайте, внесите депозит прямо сейчас!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😍 НОВЫЕ ИГРОКИ! Процент побед увеличился 🏆",
    text: "🤑 Сделайте свой первый депозит и выиграйте",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "✅ Ты зарегистрировался, а значит… 😊",
    text: "…пора внести первый депозит и получить крутой бонус! 🕹️",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😎 Игрок DERK24 сорвал $123,875 с первого пополнения!",
    text: "🎫 Пополняй счет сейчас и увеличивай свой доход многократно! 🎫",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 ЭТО ВАЖНО! 💵",
    text: "😊😊😊 Сегодня невероятная удача у игроков из твоего города, не упусти шанс! 😊😊😊",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "⏳ Твои первые 24 часа после регистрации... 🤜🏻🤛🏻",
    text: "А ты уже попробовал крутые слоты? Тут реально горячо! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🤫 Сегодня даже минимальный депозит может принести ФОРТУНУ! 💰",
    text: "Погружайся в атмосферу и наслаждайся графикой лучших слотов 🎫🎫🎫",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🔥 Сегодня удача на твоей стороне! 💪🏼",
    text: "Лови джекпоты и увеличивай свой выигрыш! 😎😎😎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🏆 Не поверишь, но сегодня выигрывает КАЖДЫЙ ВТОРОЙ!",
    text: "Твой крупный выигрыш уже рядом 🎰🎰🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 СМЕШНО БОЛЬШОЙ ДЖЕКПОТ 🎰 😱",
    text: "Ты должен его забрать! 🥳🥳🥳",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😱😱😱 Внимание! Твой аккаунт под угрозой!",
    text: "Пополни баланс прямо сейчас и продолжай выигрывать!👇🏻👇🏻👇🏻",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Ваш самый большой успех совсем рядом!💪🏼",
    text: "🔥 Сделайте депозит, играйте, и награды посыплются!🔥",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰Наслаждайся игрой в нашем КАЗИНО🎉",
    text: "🚀Пополняй баланс, участвуй в турнирах и побеждай! Все это тут👇",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵Зарабатывай весело и легко в нашем казино! 🎉💰",
    text: "Пополните счет и начните путь к успеху 🚀💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🚀 Стремись к вершине успеха!",
    text: "Пополняй счет, покоряй слоты и взрывай огромные выигрыши с каждым вращением! 💸🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "✨ Удача буквально кружит вокруг вас ✨",
    text: "Вносите депозит и погружайтесь в мир захватывающих приключений с большими выигрышами! 🚀💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💫🔥 Увеличь свои шансы на победу благодаря высоким коэффициентам! 😊",
    text: "Вноси депозит, играйте и завоевывайте внушительные выигрыши вместе с нашим казино! 🎰❤️",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "😱 Таких огромных выигрышей ты еще не видел!",
    text: "Пополни счет и дай шанс своей удаче приумножить капитал 😉💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Casino разрывает все математические законы! 🤯",
    text: "💰 Минимальный депозит – максимальные выигрыши! 🎁🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "⚡️ Не откладывай – пополни баланс и начни побеждать! 🔋",
    text: "🤑 Сыграй и измени свою жизнь к лучшему! Вход здесь👇🏻",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Получи бонус к депозиту",
    text: "Разнообразие игр и бонусов для всех новичков! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Бонус до 100% на депозит!",
    text: "Специально для тебя у нас есть бонусы! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 За депозит даём бонусы",
    text: "Выгодный приветственный пакет ждет вас! 💲",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "ЧТОБЫ ПОЛУЧИТЬ БОНУС ПОПОЛНИТЕ СЧЕТ! 💰",
    text: "Сделайте депозит и получите огромные бонусы! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Внесите депозит и вернитесь в игру! 🎰",
    text: "Вноси депозит и забирай бонус прямо сейчас! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Делай депозит и получи бонус! 💲",
    text: "Внесите свой депозит и получите свой бонус! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Пополняй счет, получай бонусы! 💰",
    text: "Хотите приумножить деньги? Внесите депозит! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Удвой свой баланс с депозитом 💎",
    text: "Ваш бонус на депозит ждет вас в приложении! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "С тебя депозит – с нас бонусы! 💵",
    text: "Пополняй баланс, получи бонус на депозит. 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Сделайте депозит. Получите больше! 💲",
    text: "Выбери свой бонус к следующему депозиту! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "ВНЕСИТЕ ДЕПОЗИТ ПРЯМО СЕЙЧАС! 💰",
    text: "Внесите депозит и получите свой подарок! 🎁",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Твой бонус уже в приложении! 🎰",
    text: "Пополни баланс. Хватит сидеть без дела! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Бонус на ваш депозит 💰",
    text: "Получите бонус на депозит прямо сейчас! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Удвоим твой депозит 💎",
    text: "Если вы хотите больше, внесите депозит! 💲",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "ЧТОБЫ ПОЛУЧИТЬ БОНУС ПОПОЛНИТЕ СЧЕТ! 💰",
    text: "Вноси депозит и получи горячие бонусы! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Внесите депозит и вернитесь в игру! 🎰",
    text: "Чем больше депозит, тем больше бонусы! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Делай депозит и получи бонус! 💲",
    text: "Пополните свой счет и получите бонусы! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Пополняй счет, получай бонусы! 💰",
    text: "Внесите депозит и получите свой подарок! 🎁",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Удвой свой баланс с депозитом 💎",
    text: "Вноси депозит и забирай бонус прямо сейчас! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "ОГРОМНЫЕ бонусы на первый депозит! 💰",
    text: "Внесите первый депозит и получите бонус! 🎉",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Огромный Приветственный бонус! 🎁",
    text: "Получите % бонус на первый депозит! 🏅",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Получите приветственный бонус! 💥",
    text: "Получите свой бонус на первый депозит прямо сейчас! 💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Приветственный пакет СПЕЦИАЛЬНО ДЛЯ ВАС! 🎉",
    text: "БОНУС В 100% ПРИ ПЕРВОМ ПОПОЛНЕНИИ! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Мегабонусы на первый депозит! 💵",
    text: "Приветственный бонус всем новичкам! 🎁",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "ПРИВЕТСТВЕННЫЙ БОНУС на пополнение! 💸",
    text: "Выгодный приветственный пакет ждет вас! 🏆",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💥 Джекпот лихорадка! Тысячи выиграли — а ты? 🏆💸",
    text: "Не упусти шанс! Пополни счет и крути слоты!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎁 Крутите слоты БЕСПЛАТНО и побеждайте! 🎡",
    text: "Получите дополнительные награды и денежные призы! Пополните баланс сейчас и сорвите куш! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🔥 Встречайте азартные победы! 💸",
    text: "Пополни баланс и начни приключение в нашем КАЗИНО! 🌟",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💥 Огромные победы поджидают вас на каждом шагу 🎉",
    text: "Спеши пополнить счет и выиграть крупную сумму! 💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰Казино, Слоты, Акции, Турниры и многое другое! 💥",
    text: "Внеси депозит и получай большие выигрыши в самых крутых игровых автоматах! 🎰💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💸 Множители x100 ждут ТЕБЯ! 🎰",
    text: "Пополни баланс, крути барабаны и смотри, как растут твои выигрыши! 🚀",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "⏳ Последний шанс поймать удачу! 💎",
    text: "Пополни баланс и открой азартные приключения! 🌠",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💥 Увеличь шансы и побеждай! 🎰",
    text: "Пополни баланс, крути слоты и получай космические выигрыши! 💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🌟 Новые топ-игры добавлены! 🎭 Твой джекпот ждет в свежих слотах! 💸",
    text: "⏳ Удача любит смелых! 💳 Пополни счет — сегодня твой день! 🍀",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🌟🔥 Новые топ-игры в КАЗИНО! 🚀",
    text: "Только самые азартные слоты и джекпоты. 💸 Пополни баланс и почувствуй драйв! 😎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Чего ты ждёшь?",
    text: "Пополни счёт от 250 EGP и получи 250% бонус! Начни играть сегодня!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Время уходит!",
    text: "Пополни счёт сейчас, чтобы получить 210 бесплатных спинов и 50% бонус — не упусти возможность!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Ты знаешь, что бонусы достигают 81,000 EGP?",
    text: "Сделай депозит сейчас и не упусти этот золотой шанс!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Ты слишком близок к победе, чтобы остановиться!",
    text: "Пополни счёт и получи ещё 50% бонуса! Не останавливайся, призы ждут тебя!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Каждое промедление может стоить тебе миллионов!",
    text: "Пополни счёт на 200 EGP и получи ещё 210 бесплатных спинов!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Ты на пути к миллионам — не останавливайся!",
    text: "Пополни счёт и получи 250% бонуса! Крупные выигрыши ждут!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Сейчас время для депозита!",
    text: "Пополни счёт и получи 50% бонус! Чего ты ждёшь?",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Приготовься удвоить свой баланс!",
    text: "Получи 50% бонус на депозит сегодня - призы ждут тебя!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Забирай ФРИСПИНЫ и денежный бонус! 💰",
    text: "Вот секретный подарок для самых удачливых! 🎁 Не упусти шанс выиграть больше! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Делай депозит и побеждай! 🎯",
    text: "Войдите в систему и начните день с бонусов! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Сделай депозит и забери подарок! 🎁",
    text: "Сегодняшний выигрыш 20-50 Фриспинов! 🎰 Твой бонус уже ждёт тебя! 💸",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Нажмите здесь и получите деньги! 💸",
    text: "Мы ждем тебя! Заходи и забери свой бонус! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Сделай депозит и забери подарок! 🎁",
    text: "Заходи крутить колесо и получать бонусы! 🎉",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Удвой свой депозит и сорви Джекпот! 💎",
    text: "Получите бонус к депозиту на любимые игры 💲",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Сделай депозит и забери подарок! 🤑",
    text: "Внесите депозит и выиграйте деньги! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 Внесите депозит — умножьте деньги! 💰",
    text: "Чем больше депозит, тем больше бонусы! 💲",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Внесите депозит и начните играть! 💵",
    text: "🤑 Просто внесите депозит в первый раз и получите бонус! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "За депозит даём бонусы! 💎",
    text: "Пополняй счёт — получай бонусы! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "С тебя депозит — с нас бонусы! 💰",
    text: "Сделайте депозит. Приумножьте деньги! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Внесите депозит — получите больше! 💎",
    text: "Пополни баланс и начни зарабатывать! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Удвой свой баланс с депозитом!",
    text: "Внесите деньги сейчас и удвойте их! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Получите 100% бонус на депозит! 💰",
    text: "💵 Просто внесите депозит в первый раз! 🤑",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Сделайте депозит и получите бонус!",
    text: "💰 Внесите депозит и выиграйте деньги! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Пополняйте счёт — получайте бонусы! 💰",
    text: "Сделайте депозит и умножьте свой баланс! 💲",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Депозит — огромные бонусы! 🤑",
    text: "Внесите депозит и начните выигрывать! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💲 Делай депозит и получи бонус! 🎰",
    text: "💰 Пополни баланс. Хватит сидеть без дела!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Мы за депозит тебе подарим бонус",
    text: "Что может быть лучше бесплатных вращений? 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Забери свой пакет новичка СЕЙЧАС",
    text: "Ваши бесплатные вращения все еще ждут! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Получите свой бонус для новичков",
    text: "САМОЕ ВРЕМЯ ПРОТЕСТИРОВАТЬ НОВЫЕ ИГРЫ 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 Фантастический бонус для игрока",
    text: "Раскручивайте свою удачу по максимуму! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Бонус на первый депозит!",
    text: "Что может быть лучше бесплатных вращений? 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Мы за депозит тебе подарим бонус",
    text: "САМОЕ ВРЕМЯ ПРОТЕСТИРОВАТЬ НОВЫЕ ИГРЫ 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Забери свой пакет новичка СЕЙЧАС",
    text: "Раскручивайте свою удачу по максимуму! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Получите свой бонус для новичков",
    text: "Ваши бесплатные вращения все еще ждут! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Бонус на первый депозит!",
    text: "САМОЕ ВРЕМЯ ПРОТЕСТИРОВАТЬ НОВЫЕ ИГРЫ 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Успей забрать сегодняшние бонусы!",
    text: "Поторопитесь! Специальный бонус для вас! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Не забудьте получить 100% бонус",
    text: "Твои бонусы сгорают с каждой минутой! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Тебе ПОДАРОК! Забирай сегодня",
    text: "Вы все еще не активировали свой бонус? 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 Срок действия бонуса истекает",
    text: "Поторопись забрать сегодняшние бонусы! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 СЕГОДНЯ выигрывают ТОЛЬКО все!",
    text: "Поторопись! Лучшее время для начала! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Почему ты ещё не действуешь?!",
    text: "У вас еще есть время получить бонус! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 У нас есть кое-что для вас",
    text: "Твои бонусы сгорают с каждой минутой! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Получите ЭКСКЛЮЗИВНЫЙ бонус!",
    text: "Специально для тебя у нас есть бонусы! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Сочные бонусы на депозит!",
    text: "Твой бонус на депозит ждет в приложении! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Секретный подарок внутри!",
    text: "Супер-бонус для проверенных игроков! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Получи шикарный бонус!",
    text: "Специально для тебя у нас есть бонусы! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Стань VIP игроком! 💎",
    text: "Твой бонус на депозит ждет в приложении!",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Ты получил бонус за депозит?",
    text: "Специально для тебя у нас есть бонусы! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Твой бонус уже в приложении!",
    text: "Заходи и получай дополнительные награды! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Даем бонусы после депозита",
    text: "В нашем казино для вас подготовлены уникальные подарки! 💵",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Получи бонус к депозиту!",
    text: "Супер-бонус для проверенных игроков! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 У нас для тебя Сюрприз!",
    text: "Получи эксклюзивный бонус прямо сейчас! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Ты получил бонус за депозит?",
    text: "Дополнительные акции для наших игроков уже доступны! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Даем бонусы после депозита",
    text: "Твой бонус на депозит ждет в приложении! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💰 Привет! Ты тут?",
    text: "Хочешь джекпот прямо сейчас? Заходи! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "🎰 Привет, друг! Играем?",
    text: "Пополните свой счет (внесите депозит)! 💎",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💎 Привет! Ты тут?",
    text: "ЧТОБЫ ПОЛУЧИТЬ БОНУС, ПОПОЛНИТЕ СЧЕТ! 💰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "💵 Привет, друг! Играем?",
    text: "Хочешь джекпот прямо сейчас? Заходи! 🎰",
    vertical: "gambling",
    event: "lead",
  },
  {
    title: "Larries192 smashed another 3,261€ win!😱",
    text: "🎫 Make a deposit NOW and crush this record! Ready? 💥",
    vertical: "gambling",
    event: "lead",
  },

  //Deposit (sale) event

  {
    title: "😱 Самое щедрое КАЗИНО♥️",
    text: "🎰Лучшие слоты, турниры и акции, огромные выигрыши и МНОГОЕ ДРУГОЕ💥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌟🚀 Готовы к потрясающим выигрышам? 🚀🌟",
    text: "Включайся в лучшие слоты и выигрывай большие деньги 💰🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Откройте для себя лучшие игры ведущего казино вашей страны! ⭐",
    text: "✨ Потрясающая графика, удачные комбинации и заманчивые бонусы — получайте удовольствие прямо сейчас! 👇",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "😱 Не пропусти возможность победить! 😎",
    text: "Приходи и открой для себя путь к финансовой независимости! 🚀👑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🤑 Gall4902 выиграл 27000$ 💰",
    text: "Погружайтесь в игру, ставьте и зарабатывайте еще больше 💰💥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎯 Каждая новая ставка — это шанс на победу! 🎯",
    text: "Приходите и почувствуйте силу везения в ваших любимых играх! 💫",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Страсть к адреналину и крупным суммам? 💰",
    text: "Наше онлайн-казино готово встретить именно тебя! 😎 Присоединяйся... 👇",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Воплотить свои мечты в жизнь уже сейчас!🚀",
    text: "Играйте и поднимайте свои доходы на новые вершины! 📈",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🔥Это кардинально преобразит твои впечатления от игр!🔥",
    text: "💥 Играйте и наслаждайтесь триумфом с нашими гигантскими множителями! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Первый, второй, третий депозит - не важно💰",
    text: "🔥Дарим бонус на каждый депозит🔥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вас ждет победа! 💰💰💰",
    text: "672 игрока выиграли сегодня! Ты следующий 🔥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵 Всегда оставайся в ПЛЮСЕ !!! 💵",
    text: "Бонус на второй депозит 50% 😍",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🔥 ВАУ! ВАУ! ВАУ! Alexx81 выиграл 1760 ЕВРО!",
    text: "Щелкните! ТЫ можешь быть следующим!💯 Играй и выигрывай!!!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Пришло время присоединиться к ним!⏰💵",
    text: "Немного статистики. За последний час 12 игроков из вашего города выиграли деньги.",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🤑👇🏻 Игрок Djala0934 сорвал 16 164 EUR!",
    text: "💸 Ты тоже можешь выиграть — делай ставку прямо сейчас! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵👇🏻 Не пропусти свой шанс разбогатеть!",
    text: "Такое бывает редко — ОГРОМНЫЙ джекпот уже ждет тебя! 🙀 Заходи!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💶👇🏻 ШОК! За 9 часов игроки забрали 293 450 000 EUR!",
    text: "💸 Ты уже попробовал испытать удачу сегодня? 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎳 Играй и побеждай!",
    text: "💥 Прямо сейчас джекпот — 420 711 EUR!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💜 ТОЛЬКО СЕГОДНЯ УДВОЕННЫЕ БОНУСЫ ДЛЯ АКТИВНЫХ!",
    text: "💎 Гигантский джекпот ждет победителя, попробуй! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵👇🏻 Sitnir1989 унес 8000 EUR!",
    text: "💸 Следующий крупный выигрыш может быть твоим — заходи! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Максимальный азарт и большие выигрыши! 💯💨",
    text: "🔥 Выиграй джекпот за считанные секунды!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "✌🏼 Слышал главную новость дня?",
    text: "💥 Сегодня каждый второй игрок уходит с сумасшедшими выигрышами! 😱😱😱",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💙 Твой момент настал!",
    text: "💎 Ты в одном клике от крупного джекпота!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "👑 Alexxx2020 увеличил свой депозит в 25 раз! 🚀💵",
    text: "👇🏻 Нажми и дай себе шанс на финансовый успех! 👇🏻",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Океан бонусов уже ждет тебя! 🌊🎁",
    text: "Подготовил для тебя сюрприз, загляни… 😏",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "❗️ Всего одна ставка – и ты можешь сорвать куш! ❗️",
    text: "Испытай удачу и увеличь свой доход в разы! 💶",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌟Тебе нужен всего один шаг к успеху! 🌟",
    text: "Активируй бонус и начинай охоту за джекпотом 💰👍🏻",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Это может случиться и с тобой…❗️❗️❗️",
    text: "Игрок Alexxx2020 👑 умножил свой депозит в 25 раз! 🚀",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Наши умопомрачительные множители ждут тебя! ❗️❗️",
    text: "🔝 Лови шанс приумножить свои выигрыши до небес! 🚀💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🔥 Kristy1999 🍓 сделала из $30 целых $3000! 💰",
    text: "🔝 Увеличивай ставку в 100 раз или даже больше! 😉",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💲 Хочешь увеличить свой баланс?",
    text: "Джекпоты растут – а вместе с ними и твои шансы! Стань профи больших побед 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Почувствуй драйв и сорви огромный куш! 💸",
    text: "Запускай игру и получи мощный бонус в начале пути! 😉💲",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Играй больше – выигрывай больше! ❗️",
    text: "💰 Фортуна на твоей стороне – жми «Старт» и забирай свой приз! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌟 Фортуна улыбается именно тебе! 🌟",
    text: "💰 Доверься интуиции и выигрывай! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "❗️Твой бонус уже ждет – просто кликни!❗️ 🎁",
    text: "⏰ Торопись, пока фортуна на твоей стороне! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💢💰 Твой банкролл может расти быстрее! 🎁",
    text: "Лови выгодные предложения, заходи и выигрывай! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "😱 Игрок Marcus674 только что выиграл 15 000 EUR!",
    text: "А ты уже сделал свою ставку сегодня?! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🔥 СЕГОДНЯ КРУПНЫЙ ВЫИГРЫШ! 🔥",
    text: "😍 СОРВАЛ ДЖЕКПОТ 125 283 $ 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "😉 Кто рано встает - тому слоты дают! 💎",
    text: "Будь в числе первых! Начни день с победы 🏆",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌒НОЧЬЮ (до 06:00) - Повышенные коэффициенты 💎",
    text: "👉Меньше игроков - Больше вероятность на ДЖЕКПОТ🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "ДОКАЗАНО!!! Утром Х2 раза больше побед 🍀",
    text: "А сколько сегодня выиграешь ты? 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Десятки турниров ждут своего победителя!!!🚀",
    text: "🔥У тебя высокие шансы! Пора попробовать!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Доброе утро!⏰ У нас уже есть несколько счастливчиков!🤩",
    text: "Не ждите ⏱️ воплощайте свои мечты в реальность прямо сейчас!🎉",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🏆 Сражайтесь за ошеломляющие призы! 💰",
    text: "Присоединяйтесь к турнирам, делайте ставки и забирайте трофеи! 🏅",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎉 Игрок Grok03458 получил €15,500! 💶",
    text: "Присоединяйся, ставь умно и стремись к большему! 🚀",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🔥 Зажги свою удачу! 🔥",
    text: "Пополни баланс, крути слоты и смотри, как растут выигрыши! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💥 Рекорды падают! Игроки становятся миллионерами 🥳",
    text: "Крути и побеждай — твой статус миллионера близко! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🔥 Рискуй, всё ради победы! 💸",
    text: "Удвой баланс крупными ставками! 🚀",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🏆 Вчерашние чемпионы! 🌍",
    text: "Прими вызов: Fan*** (370k), Li*** (490k), Tal*** (600k)! 🏆 Твоя очередь!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🏡 Мечтаешь о роскошном доме?",
    text: "🚀 КАЗИНО повышает шансы! ⏳ Действуй сейчас и обеспечь мечту! 🤩",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌞🚀 Новый день — новые победы!",
    text: "Вчера 125 новых миллионеров попали в зал славы нашего КАЗИНО. 🏆 Поздравляем! Сегодня твоя очередь! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Чего ты ждёшь? Огромный выигрыш ждёт тебя в играх Crash!",
    text: "Главный приз до 500,000 EGP! Твоя удача не будет ждать вечно!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Хочешь удвоить свой счёт?",
    text: "Сделай депозит и получи 250% бонус! Не упусти этот золотой шанс!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "100 бесплатных спинов и 50% бонус на твой десятый депозит!",
    text: "Не теряй больше времени - это предложение действует ограниченно!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Не веришь? Махмуд из Каира выиграл 3,000,000 EGP в Aviator этим утром!",
    text: "Станешь ли ты следующим победителем? Входи прямо сейчас!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Среда — день удвоенных выигрышей!",
    text: "Бонус до 250% ждёт тебя только сегодня! Входи и начинай играть прямо сейчас!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Мохамед из Гизы сорвал джекпот в Fruity Diamonds на сумму 1,000,000 EGP!",
    text: "Готовься к игре Crash с призовым фондом 7,500,000 EGP! Забери его прямо сейчас!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Ты ближе, чем когда-либо!",
    text: "Не упусти следующий приз!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Счастливая пятница началась!",
    text: "У тебя всего 24 часа, чтобы получить 16500 EGP бонуса. Спеши - время уходит!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Ахмед из Александрии в шоке - выиграл 3,000,000 EGP в Crash Games!",
    text: "Когда удача улыбнётся тебе? Входи и начинай!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Идеальный приветственный пакет ждёт тебя - 81,000 EGP и 210 бесплатных спинов!",
    text: "Входи и начинай играть сейчас, пока не поздно!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Истории успеха не прекращаются!",
    text: "Фатима из Мансуры выиграла 2,000,000 EGP в 1XTwin Fruits! Входи и проверь свою удачу!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Aviatrix даёт тебе шанс выиграть огромные призы!",
    text: "Чем больше играешь, тем выше шансы на победу! Играй прямо сейчас!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Огромные призы в Hyper Bonus!",
    text: "Получи 250% бонус только сегодня! Не упусти свой шанс!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "100 бесплатных спинов и 50% бонус на твой десятый депозит!",
    text: "Спеши до окончания предложения!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Халид из Гизы выиграл 3,000,000 EGP в Aviator!",
    text: "Сможешь ли ты повторить этот успех?",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Среда - это день огромных бонусов!",
    text: "Получи бонусы до 250% только сегодня! Входи прямо сейчас!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Мохамед из Каира выиграл 15,000,000 EGP в Fruity Diamonds!",
    text: "Станешь ли ты следующим победителем?",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Не упусти шанс!",
    text: "Призы в Crash Games до 750,000 EGP сегодня! Входи и начинай играть!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Счастливая пятница началась!",
    text: "Получи бонус 16000 EGP сейчас! Это предложение доступно только 24 часа!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Амр из Каира выиграл 2,000,000 EGP в Aviator!",
    text: "Ты будешь следующим?",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Ты уже забрал свой приветственный пакет?",
    text: "81,000 EGP и 210 бесплатных спинов ждут тебя! Не упусти шанс!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Сара из Мансуры выиграла 3,500,000 EGP в 1XTwin Fruits!",
    text: "Твоя удача ждёт тебя в игре - играй прямо сейчас!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Огромные призы в Aviatrix не прекращаются!",
    text: "Выиграй сегодня и получи невероятные награды!",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Джекпот — это проще, чем Вы думаете! 💰",
    text: "Сегодня тот день, который изменит вашу жизнь! 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Хочешь джекпот прямо сейчас? Заходи! 🎰",
    text: "Вы хотите сорвать настоящий куш, не так ли?! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вы упускаете шанс выиграть джекпот 💎",
    text: "Время ВЫИГРЫВАТЬ. Сорвите ОГРОМНЫЙ джекпот! 💲",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Выиграй на своем телефоне сегодня! 📱",
    text: "СЕЙЧАС У ТЕБЯ ЕСТЬ ВОЗМОЖНОСТЬ СОРВАТЬ КУШ! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Самые высокие коэффициенты сейчас! 💵",
    text: "Настоящие ПОБЕДЫ, не упустите свой шанс! 🏆",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Высокие джекпоты Ежедневно! 💰",
    text: "Госпожа удача сегодня на вашей стороне! 🎉",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вас ждет огромный джекпот 💎",
    text: "Другие выигрывают миллионы каждый день! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Будь тем, кто сорвет джекпот! 🎰",
    text: "Сегодня тот день, который изменит вашу жизнь! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Играйте сейчас и сорвите джекпот! 🤑",
    text: "СЕЙЧАС У ТЕБЯ ЕСТЬ ВОЗМОЖНОСТЬ СОРВАТЬ КУШ! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Почувствуйте эмоции победы! 🏆",
    text: "Вы хотите сорвать настоящий куш, не так ли?! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Джекпот — это проще, чем Вы думаете! 💎",
    text: "Настоящие ПОБЕДЫ, не упустите свой шанс! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Хочешь джекпот прямо сейчас? Заходи! 💰",
    text: "Госпожа удача сегодня на вашей стороне! 🎉",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вы упускаете шанс выиграть джекпот 💎",
    text: "Другие выигрывают миллионы каждый день! 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Выиграй на своем телефоне сегодня! 📱",
    text: "Время ВЫИГРЫВАТЬ. Сорвите ОГРОМНЫЙ джекпот! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Самые высокие коэффициенты сейчас! 💸",
    text: "Сегодня тот день, который изменит вашу жизнь! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Высокие джекпоты Ежедневно! 💎",
    text: "Играйте сейчас и сорвите джекпот! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Ежедневные специальные предложения! 💥",
    text: "Испытайте свою удачу в еженедельной лотерее 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "ЕЖЕДНЕВНАЯ ГОНКА ИГРОВЫХ АВТОМАТОВ 🏁",
    text: "Горячее предложение для немногих избранных! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Еженедельный бонус уже ждет! 💰",
    text: "Турнир по игре в игровые автоматы для вас! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "СПЕЦИАЛЬНО ДЛЯ ВАС! 💥",
    text: "Достойная награда для постоянных игроков! 🏆",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вас ждут VIP-призы! 🎁",
    text: "Официальное предложение только для тебя! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Получите ЭКСКЛЮЗИВНЫЙ бонус! 💎",
    text: "Новый бонус доступен в личном кабинете! 📱",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Мы приготовили ДЛЯ ВАС ПОДАРОК 🎁",
    text: "Испытайте свою удачу в еженедельной лотерее 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вас ждет БОНУС от КАЗИНО 💰",
    text: "Турнир по игре в игровые автоматы для вас! 🎯",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Ежедневные специальные предложения! 💥",
    text: "Новый бонус доступен в личном кабинете! 📱",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "ЕЖЕДНЕВНАЯ ГОНКА ИГРОВЫХ АВТОМАТОВ 🏁",
    text: "Достойная награда для постоянных игроков! 🏆",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Еженедельный бонус уже ждет! 💰",
    text: "Испытайте свою удачу в еженедельной лотерее 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "СПЕЦИАЛЬНО ДЛЯ ВАС! 💥",
    text: "Горячее предложение для немногих избранных! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вас ждут VIP-призы! 🎁",
    text: "Новый бонус доступен в личном кабинете! 📱",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Получите ЭКСКЛЮЗИВНЫЙ бонус! 💎",
    text: "Официальное предложение только для тебя! 💥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Мы приготовили ДЛЯ ВАС ПОДАРОК 🎁",
    text: "Турнир по игре в игровые автоматы для вас! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вас ждет БОНУС от КАЗИНО 💰",
    text: "Достойная награда для постоянных игроков! 🏆",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Внимание, ваш бонус может сгореть! 🔥",
    text: "Предложение с ограниченным сроком действия! ⏳",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Поторопись! Лучшее время для начала! 🏃‍♂️",
    text: "Почему другие забирают деньги? Забери свое! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Пока ты ждешь, другие выигрывают! 🎰",
    text: "Последний шанс получить свой бонус в игре! 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Подарки почти закончились! 🎁",
    text: "Поторопись забрать сегодняшние бонусы! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Важное напоминание! ⏰",
    text: "Предложение с ограниченным сроком действия! 🔥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Почему ты ещё не действуешь? 🤔",
    text: "Почему другие забирают деньги? Забери свое! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Внимание, ваш бонус может сгореть! 🔥",
    text: "Поторопись забрать сегодняшние бонусы! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Важное напоминание! ⏰",
    text: "Поторопись забрать сегодняшние бонусы! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Крути колесо и выигрывай призы! 🎉",
    text: "Время повеселиться и сорвать большой куш! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вращайте колесо! 🎰",
    text: "Казино, где вы можете выиграть ежедневно! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "ВЕСЕЛЬЕ И ДЕНЬГИ 🤑",
    text: "Время повеселиться и сорвать большой куш! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Крути колесо и выигрывай призы! 🎉",
    text: "Получи максимум приятных эмоций и азарта! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Огромные выигрыши тебя ждут! 💸",
    text: "Время повеселиться и сорвать большой куш! 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Забери свой выигрыш СЕЙЧАС! 🏆",
    text: "Вот секретный подарок для самых удачливых! 🎁 Не теряй время, забери свой приз! 💥",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Забирай свои бонусы прямо сейчас! 🎉",
    text: "Сегодняшний выигрыш 20-50 Фриспинов! 🎰 Ты в шаге от своей победы! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Забери свой приз сейчас! 🏅",
    text: "Вот секретный подарок для самых удачливых! 🎁 Не упусти свой шанс на большой выигрыш! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Вы получили бонус?! 🎉",
    text: "Сегодняшний выигрыш 20-50 Фриспинов! 🎰 Забери бонус прямо сейчас и начни выигрывать! 🤑",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Даем гарантированный выигрыш! 💰",
    text: "Выиграть никогда еще не было так просто! 🤑 Каждый следующий спин может быть успешным! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "БОЛЬШОЙ ВЫИГРЫШ каждые 15 минут! ⏳",
    text: "Выиграть никогда еще не было так просто! 💸 Не теряй шанс, каждый спин приближает к победе! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Сделай свою выигрышную ставку! 🎯",
    text: "Все в ваших руках, просто начните играть! 🏅 Не упусти шанс на огромный выигрыш! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Используй бонусы с умом! 💡",
    text: "Мы сделаем для вас специальное предложение! 🎁",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "Используй 💰 ОГРОМНЫЕ бонусы на следующий депозит с умом! 💡",
    text: "Получите свой бонус на следующий депозит! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Мегабонусы на следующий депозит",
    text: "Получи бонус на депозит, заходи и играй! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 За следующий депозит даём бонусы!",
    text: "Получите свой бонус на следующий депозит! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵 Бонус на следующий депозит!",
    text: "Получи бонус на депозит, заходи и играй! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 Удвой свой депозит и сорви Джекпот!",
    text: "Хотите приумножить деньги? Внесите депозит! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Удвойте свои деньги прямо сейчас!",
    text: "Чем больше депозит, тем больше бонусы! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Удвой свой баланс с депозитом!",
    text: "Депозит - идеальный шанс на роскошную жизнь! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵 Удвоим твой депозит!",
    text: "Чем больше депозит, тем больше бонусы! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 Депозит - огромные бонусы!",
    text: "Хотите приумножить деньги? Внесите депозит! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Даем Бонусы после депозита!",
    text: "Депозит - идеальный шанс на роскошную жизнь! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Делай депозит и побеждай!",
    text: "Хотите приумножить деньги? Внесите депозит! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 Поторопись! Лучшее время для начала!",
    text: "Предложение с ограниченным сроком действия! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Внесите деньги сейчас и получите их!",
    text: "Поторопись забрать сегодняшние бонусы! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Не забудьте получить 100% бонус!",
    text: "Предложение с ограниченным сроком действия! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 С тебя депозит - с нас бонусы!",
    text: "Вноси депозит и забирай бонус прямо сейчас! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 С тебя депозит - с нас бонус!",
    text: "Вноси депозит и забирай бонус прямо сейчас! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Делай депозит и получи бонус!",
    text: "Пополни баланс. Хватит сидеть без дела! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 Пополняй счет - получай бонусы!",
    text: "Вноси депозит и забирай бонус прямо сейчас! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵 ЧТОБЫ ПОЛУЧИТЬ БОНУС, ПОПОЛНИТЕ СЧЕТ!",
    text: "Пополните свой счет! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Внесите депозит. Умножайте деньги!",
    text: "Вноси депозит и забирай бонус прямо сейчас! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Сделайте депозит. Приумножьте деньги!",
    text: "Пополни баланс. Хватит сидеть без дела! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 Бонус +200% на депозит!",
    text: "Пополни баланс. Хватит сидеть без дела! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵 Бонус на депозит!",
    text: "Вноси депозит и забирай бонус прямо сейчас! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Хочешь быть VIP-игроком?",
    text: "ВИП-бонусы для наших лучших игроков! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Стань VIP-игроком!",
    text: "Получите элитный бонус для избранных! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 У нас есть кое-что для вас!",
    text: "Эксклюзивное предложение только для вас! 💵",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 У нас для тебя Сюрприз!",
    text: "ВИП-бонусы для наших лучших игроков! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Получи шикарный бонус!",
    text: "Получите элитный бонус для избранных! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💰 Специально для тебя у нас есть бонусы!",
    text: "Получите бонус к депозиту на любимые игры! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Мы за депозит тебе подарим бонус!",
    text: "Получи максимум приятных эмоций и азарта! 💎",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💎 Сочные бонусы на депозит!",
    text: "Получите бонус к депозиту на любимые игры! 💰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "💵 Получи бонус к депозиту!",
    text: "Получи максимум приятных эмоций и азарта! 🎰",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌋 Luck volcano is blowing!💥",
    text: "❗️ Each next bet can be a winning one ❗️COME IN QUICKLY👇🏻",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "⚠️ UNBELIEVABLY HUGE JACKPOT!⚠️",
    text: "Step up and secure your financial win! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌟 Life-changing rewards are HERE!🎰💥",
    text: "Level up with every spin! 🎯 The more you play—the BIGGER your wins! 💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎰 Jackpot mastery is our signature move! 💥",
    text: "Spin and test your luck in thrilling slots! 🎉",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🏆💥 Grab life-changing wins!!! 💥💪",
    text: "Your jackpot potential is endless—Don't miss the chance to multiply your winnings many times over! 🤩💸",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🎉 Huge congratulations to Enrico777! 💸Already won €11,472!",
    text: "Stop hesitating—prove you’re a true champion! 💪",
    vertical: "gambling",
    event: "sale",
  },
  {
    title: "🌟 Life-changing rewards are HERE!🎰💥",
    text: "Level up with every spin! The more you play—the BIGGER your wins! 💸",
    vertical: "gambling",
    event: "sale",
  },

  //Subscription
  {
    title: "🎮 Готов погрузиться в азарт? Поехали! 🚀",
    text: "Присоединяйся к нашему КАЗИНО и крути топовые слоты! Увеличь баланс — большие выигрыши ждут!",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌟 Незабываемые эмоции начинаются здесь! 🎰",
    text: "Ищете лучшие слоты? Всё есть у нас! 👇",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Войдите в мир, полный волнений и удачи! 🎁🎁",
    text: "Получите мгновенное вознаграждение за первый депозит 👈🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Откройте свой потенциал и начните зарабатывать 🤑",
    text: "📣📣Играйте и смотрите, как ваш доход растет! 👈🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Ваши победы ошеломят ваших друзей! 🎁",
    text: "👇🏻Забирайте крупные призы и заставьте их завидовать.",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Casino помогает превратить ваши мечты в реальность с настоящими деньгами 💹🎁",
    text: "Присоединяйтесь сейчас и ощутите восторг от доминирования в мире казино ☺️",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "VIP-клуб Casino 💎🎁",
    text: "Играйте и получайте эксклюзивные привилегии и бонусы, которые доступны только вам 💵💶💷",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Не можете увеличить свою прибыль многократно? 😛",
    text: "🌟 Пока вы сомневаетесь, другие уже зарабатывают.",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Войдите в мир волнений и удачи! 🎁 Тебя ждёт настоящий клад щедрых наград! 😍",
    text: "Графика слотов поразит твое воображение 😍",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "❗️❗️❗️ Это могло быть твоё время...",
    text: "💯 Невероятные эмоции и гарантированные БОЛЬШИЕ выигрыши 💯",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💎 Удача улыбается тебе! 💎",
    text: "‼️ ТЫ НА ШАГ ОТ СВОЕЙ МЕЧТЫ ‼️",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🤯 Casino ломает законы математики ❌ Готовься к удивлению... 😎",
    text: "❗️Не упусти шанс погрузиться в захватывающие игры!",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💸 Погружайся в игру, и пусть удача сделает чудо! 💸👇🏻",
    text: "🤑 Вступай и используй шанс стать богатым! 🤑",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "САМЫЕ ВЫГОДНЫЕ СЛОТЫ ЗДЕСЬ 👇🏻😱",
    text: "Великолепные награды ждут тех, кто не боится ставить 👇🏻👇🏻👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "👉🏻 Полное погружение и бонусы здесь 👈🏻",
    text: "Мы предоставляем широкий выбор игр для интересного и выгодного отдыха😉👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🕹️Играйте в топовые игры от лучших разработчиков! 🕹️",
    text: "Присоединяйся и исследуй широкий выбор развлечений ✨",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "❗️ Твой шанс увеличить заработок прямо здесь!",
    text: "Играйте на супер-горячих слотах прямо сейчас!🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎰💢 Больше слотов — больше возможностей выиграть ❗️",
    text: "💰Доверься своей удаче и вперед!🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Празднуй прямо у себя дома! 🥳",
    text: "Сделай свой день ярким и прибыльным с нашими крупными выигрышами😊",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "❌Не ограничивай свой финансовый потенциал!! 🤑",
    text: "🎁Лучшие бонусы и самые горячие слоты ждут тебя!😉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💥💣 НОВЫЕ ИГРОВЫЕ ХИТЫ! 💣💥",
    text: "🕹️ Будь в центре событий и открой для себя последние тренды гейминга! Скорее врывайся в новые игры! 👇🏻🤩",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Здесь выигрывают чаще всех! 👇🏻😱",
    text: "Смелые игроки уже забирают крупные призы – не упусти шанс! 👇🏻👇🏻👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "⚡️ Специальное сообщение для тебя! 📩",
    text: "Жми кнопку и получи эксклюзивный пакет бонусов 💲👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Графика слотов выходит за грани реальности! 🎮✨",
    text: "❗️ Почувствуй полный эффект погружения - Жми и играй прямо сейчас!",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "❗️❗️ ВАЖНАЯ НОВОСТЬ ❗️❗️",
    text: "🌍 Именно в это время победителей становится в 3 раза больше! 😱💵 Не теряй момент - проверь сам! 👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "😲 Твои друзья не поверят в твои успехи!",
    text: "Срывай крупные куши и заставь их завидовать! 🏆👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🕹️ Испытай удачу в топовых играх от лидеров индустрии! 🕹️",
    text: "Открой для себя невероятное разнообразие развлечений ✨",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "⚡️❗️ Фантастика! ⚡️❗️ Сегодня удача на стороне каждого! 💸",
    text: "Cкорее вступай в игру – возможно, ты следующий победитель! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💲 На этой неделе выплачено уже $718,916! 😱",
    text: "Ты еще не в игре? Действуй – выигрывай и зарабатывай прямо сейчас! 💵",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Нереальный азарт и мощные выигрыши ОБЕСПЕЧЕНЫ! 🔥💯",
    text: "Жми, чтобы испытать удачу и забрать свое! 💸👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "С нами богатство ближе, чем ты думаешь! 🤑🤑",
    text: "Перестань ждать удачу – она уже здесь! Лови крупные выигрыши на каждом шагу! 💲💲💲",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "‼️ ТЫ В ОДНОМ ШАГЕ ОТ БОГАТСТВА ‼️",
    text: "Врывайся в игру и используй свой шанс стать победителем! 🏆💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💫 Casino – это путь к стабильным победам! 💫",
    text: "👇🏻 Врывайся в игру и забирай свой куш 👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "❗️ 30,000 EURO – готовы к победителю! ❗️",
    text: "Стань частью игры и забери свою долю 👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "❌ Не упусти шанс приумножить свой капитал! 🤑",
    text: "🎁 Уникальные бонусы и лучшие игровые автоматы – только для тебя! 😉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 До конца дня новичкам дарим подарки!",
    text: "Разнообразие игр и бонусов для всех новичков! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💵 Всем новичкам бонус!",
    text: "Специально для тебя у нас есть бонусы! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🤑 Мы подготовили БОНУС",
    text: "Выгодный приветственный пакет ждет вас! 💲",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 Мы за депозит тебе подарим бонус!",
    text: "Разнообразие игр и бонусов для всех новичков! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💵 Бонус на первый депозит!",
    text: "Специально для тебя у нас есть бонусы! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🤑 У нас для тебя Сюрприз",
    text: "Выгодный приветственный пакет ждет вас! 💲",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 До конца дня новичкам дарим подарки!",
    text: "Специально для тебя у нас есть бонусы! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🤑 Мы подготовили БОНУС",
    text: "Разнообразие игр и бонусов для всех новичков! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🚀 Каждый день розыгрыш призов!!!",
    text: "Ждем только тебя... Поторопись...🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔥Посети магазин купонов. Возьми выигрышную ставку.",
    text: "Наслаждайтесь слотами и получайте бонусы за активную игру!",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔥Дополнительные бонусы всем! Не пропусти!",
    text: "Следи за событиями в приложении...",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 ЗАНАЧКА - Бонус БЕЗ Депозита! 💰",
    text: "Играй и увеличивай свою заначку",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Представьте себя в новой машине... 🚖",
    text: "А теперь поторопитесь, вращайте барабаны и воплощайте свои мечты в реальность!💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Ты собираешься работать?👀",
    text: "Наши игроки уже набили свои карманы💰💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Авиатор сделает тебя богатым!",
    text: "Самолет удачи уже даёт выигрыш,успей поставить!🎁",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💎 КАЗИНО — ваш билет к удаче! 💫",
    text: "Топовые игры, эксклюзивные бонусы и судьбоносные призы — всё здесь! 🚀",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎮 Исследуйте топовые игры от лидеров индустрии! 🌟",
    text: "Потрясающая графика, прибыльные комбинации и эксклюзивные предложения — начните прямо сейчас! 💥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌌 Окунись в азартный мир! 🎮",
    text: "Испытай драйв побед в топовых слотах нашего КАЗИНО! 💥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔥 Хочешь адреналина и МЕГА-выигрышей? 💸",
    text: "КАЗИНО уже ждет тебя! Погрузись в игру сейчас! 🌟",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌟 Исследуй новые миры азарта! 🍒",
    text: "Сотни захватывающих игр ждут тебя — начни сейчас! 🎮",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌌 Окунись в мир постоянных побед! 🎰",
    text: "Преврати жизнь в череду праздничных выигрышей! 🎉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💎 Смело шагни в новую жизнь! 💸",
    text: "Открой путь к несметным богатствам в нашем КАЗИНО! 🌟",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "✨ Преврати мечты в реальность! 🔝",
    text: "Подними доход до небес — действуй сейчас! 🌕",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎉 Открой топовое казино! 🌟",
    text: "От слотов до джекпотов — выбери свою игру! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌌 Революция в мире азарта! 🚀",
    text: "Покори слоты с множителями x1000! 💥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌙🎮 Устал? Расслабься в нашем КАЗИНО! 👑",
    text: "Окунись в 100+ игр, где действия важнее слов. ❤️🔥 Твой отдых станет джекпотом! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "👷🧰 Выдохлись? Вечером отдохни в топовом казино 👑🍓💪🏼😎",
    text: "Мы покажем, что превосходство наше — не только на словах, но и на деле! Сейчас же попробуй сотни захватывающих игр 🎰❤️‍🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Новые слоты с повышенной выплатой 💎",
    text: "Самые популярные слоты на твоем смартфоне! 📱",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Испытай её в своих любимых слотах! 🎰",
    text: "Все популярные слоты в твоём смартфоне! 💵",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Лучшие слоты с лучшими выплатами! 💰",
    text: "Играйте в лучшие слоты с огромным бонусом! 🤑",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Лучшие игровые автоматы ждут вас! 🎰",
    text: "САМОЕ ВРЕМЯ ПРОТЕСТИРОВАТЬ НОВЫЕ ИГРЫ! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Какие слоты вы выберете сегодня?! 💸",
    text: "Самые популярные слоты на твоем смартфоне! 📱",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Автоматы дают всем! 💵",
    text: "Играйте в лучшие слоты с огромным бонусом! 🤑",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Новые слоты с повышенной выплатой 💎",
    text: "САМОЕ ВРЕМЯ ПРОТЕСТИРОВАТЬ НОВЫЕ ИГРЫ! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Испытай её в своих любимых слотах! 🎰",
    text: "Самые популярные слоты на твоем смартфоне! 💵",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Лучшие слоты с лучшими выплатами! 💰",
    text: "Все популярные слоты в твоём смартфоне! 📱",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Лучшие игровые автоматы ждут вас! 🎰",
    text: "САМОЕ ВРЕМЯ ПРОТЕСТИРОВАТЬ НОВЫЕ ИГРЫ! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Какие слоты вы выберете сегодня?! 💸",
    text: "Играйте в лучшие слоты с огромным бонусом! 🤑",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Автоматы дают всем! 💵",
    text: "Все популярные слоты в твоём смартфоне! 📱",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Пока ты ждешь, другие выигрывают! 🎰",
    text: "Почему другие забирают деньги? Забери свое! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Подарки почти закончились! 🎁",
    text: "Почему ты ещё не действуешь? 🤔",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Почему ты ещё не действуешь? 🤔",
    text: "Последний шанс получить свой бонус в игре! 🤑",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Почувствуйте роскошь победителей 💎",
    text: "Побалуйте себя отличной игрой прямо сейчас! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "ВЕСЕЛЬЕ И ДЕНЬГИ 🤑",
    text: "Получи максимум приятных эмоций и азарта! 💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Огромные выигрыши тебя ждут! 💸",
    text: "Побалуйте себя отличной игрой прямо сейчас! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Вас ждут невероятные эмоции! 🎉",
    text: "Получи максимум приятных эмоций и азарта! 🤑",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Первая поездка неслыханной щедрости 🛫",
    text: "Время повеселиться и сорвать большой куш! 💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Почувствуйте роскошь победителей 💎",
    text: "Казино, где вы можете выиграть ежедневно! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Вращайте колесо! 🎰",
    text: "Побалуйте себя отличной игрой прямо сейчас! 🎉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Вас ждут невероятные эмоции! 🎉",
    text: "Побалуйте себя отличной игрой прямо сейчас! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Первая поездка неслыханной щедрости 🛫",
    text: "Казино, где вы можете выиграть ежедневно! 💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "БОЛЬШИЕ ВЫИГРЫШИ уже здесь! 💥",
    text: "Все в ваших руках, просто начните играть! 🎮 Ты можешь стать следующим победителем! 🏆",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Успей забрать сегодняшние бонусы! ⏳",
    text: "Играй на бонусы и выигрывай в нашем казино! 🎰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Зайди и убедись! 🔥",
    text: "Последний шаг к Роскошной Жизни. Не упусти шанс! 💎",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎰 Jackpot mastery is our signature move! 💥",
    text: "Spin and test your luck in thrill-packed slots! 🎉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 CRUCIAL UPDATE FOR YOU 💰",
    text: "🎉🎉🎉 Locals are hitting jackpots nonstop—be the next winner! 🎉🎉🎉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Hey friend!👋🏻 Win real cash prizes today!💵",
    text: "Act fast—your fortune awaits! 🌈",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔥 Unique game show with GENEROUS BONUSES🔥",
    text: "Ready to claim them? 🎯 Press now and play",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎮 COUNTLESS games = COUNTLESS paths to victory!💸🔥",
    text: "Every spin boosts your odds! 🔥 Join now and claim your win!",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "😱 ⏰ Sunrise WIN! Andry951 already bagged 4,550€",
    text: "Hurry up and try it! 😉 😉",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 One click to a million! 🚀",
    text: "Seize your shot at effortless cash! 🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🏆💥 Grab life-changing wins!!! 💥💪",
    text: "Your jackpot potential is endless—act now! 🤩💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎰 Free spins in slots are waiting for you only!",
    text: "More bonuses await! 🎁 Press to claim everything now!",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🚀 Maximize your luck’s potential!",
    text: "Join us and plunge into the world of excitement and big wins!🍀",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "⚡️ Legends are born from big wins!",
    text: "No more waiting—craft your fortune now! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎮 Goodbye boredom—hello variety! 🎉",
    text: "Join the game and escape the mundane! 🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "Winnings are growing 💥 And yours can too!💰📈",
    text: "👑 Today 237 players have already won! 🏆Join us🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "⚡️ Sky-high chance to land a GIANT jackpot! 🚀",
    text: "Join us — fortune waits for no one! 💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🏹 Catch your luck🏹",
    text: "🎁 🎁 Big rewards await—start now!💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🌟Escape reality with top-tier slots!🎮✨",
    text: "Dive into the game and leave daily stress behind—your chill zone awaits! 🌴👇",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎯 Massive rewards game starts NOW! 🎰🚀",
    text: "⚡ Don’t blink—big wins await! Dive in and claim your prize! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔍 ATTENTION!!! We need a champion!🏆🏆",
    text: "Epic win ahead—join the quest! 💵 👇🏻",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔥 Unlock fresh profit boosts—new chances await! 🚀",
    text: "🎰 Play your favorite slots and let chance guide you to massive wins! 💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎉 Huge congratulations to Enrico777! 💸Already won €11,472!",
    text: "Stop hesitating—prove you’re a true champion! 💪",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎯 Win BIG with every spin! 🚀",
    text: "Join and become a winner among daredevils! 🔥🔥🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💥 Jealili1992 SMASHED 9,000€—NOW IT’S YOUR TURN!🚀",
    text: "Your moment’s here—claim BIGGER WINS NOW!💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎰 Crown yourself casino king!🎰",
    text: "🎰 Turn small bets into big wins! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎯 Bets, thrills, wins—all under one roof! 🏰✨",
    text: "🎰 Turn small bets into exponential gains—play now and claim your rewards! 💸🎁",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎯 Bets, thrills, wins—all under one roof! 🏰✨",
    text: "🎰 Turn small bets into exponential gains—play now and claim your rewards! 💸🎁",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "💰 Hey! Do you want to earn extra money?💵",
    text: "🎉 Your lucky streak starts NOW—claim your fortune! 🌟",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🤯 Jackpots beyond imagination—your dream win awaits!🎰",
    text: "Dive into massive bonuses and life-changing rewards—play now! 🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "⚡️ Legends are born from big wins!",
    text: "No more waiting—craft your fortune now! 💸",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎮 Dive into premium slots for ultimate relaxation! 🌴✨",
    text: "Dive into the game and leave daily stress —your chill zone awaits! 🌴👇",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🔥 Fresh profit boosts—your earnings are about to skyrocket! 🚀",
    text: "🎰 Play your favorite slots and let chance guide you to massive wins! 💰",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎯 Bets, thrills, wins—all under one roof! 🏰✨",
    text: "🎰 Turn small bets into exponential gains—play now and claim your rewards! 💸🎁",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎮 Goodbye boredom—hello variety! 🎉",
    text: "Join us and discover a vast selection of entertainments!🔥",
    vertical: "gambling",
    event: "subscription",
  },
  {
    title: "🎯 Massive rewards game starts NOW! 🎰🚀",
    text: "⚡ Don’t blink—big wins await! Dive in and claim your prize! 💸",
    vertical: "gambling",
    event: "subscription",
  },
];
