import React, { useState } from "react";
import { Modal, Fade, Box, Typography, TextField } from "@mui/material";
import DOMPurify from "dompurify";
import CustomButton from "./Button";
import { ModalContent, style, StyledBackdrop } from "./CautionModal";
import { useTranslation } from "react-i18next";
import { getToken } from "./Pushes";
import useFetchFormData from "hooks/useFetchFormData";
import ToastMessage from "components/ToastMessage";

interface MetaTagsModalProps {
  openModal: boolean;
  closeModal: () => void;
  id: string;
  pwaMeta?: string;
}

export const MetaTagsModal: React.FC<MetaTagsModalProps> = ({ id, openModal, closeModal, pwaMeta }) => {
  const [metaTagsInput, setMetaTagsInput] = useState(pwaMeta?.replace(/^"(.*)"$/, "$1").replace(/\\"/g, '"') || "");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const processMetaTags = () => {
    // Split the input by line breaks or other delimiters if needed
    const metaTags = DOMPurify.sanitize(metaTagsInput).trim().split(/\n/);
    // Regular expression to validate general <meta> tag structure
    const metaTagRegex = /^<meta\s+([^>]+)\s*\/?>$/i;

    for (let tag of metaTags) {
      tag = tag.trim(); // Remove extra spaces
      if (tag && !metaTagRegex.test(tag)) {
        setError(t("metaTagsError"));
        return false; // Invalid meta tag found
      }
    }

    setError("");
    submitMetaTags(metaTagsInput);
  };
  const token = getToken();
  const { triggerFetch: updatePWA } = useFetchFormData({
    url: `pwa/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const submitMetaTags = async (metaTags: string) => {
    const data = new FormData();
    data.append("metaTags", JSON.stringify(metaTags));
    await updatePWA(data);
    closeModal();
    ToastMessage({ mode: "info", message: t("successPwaUpdate") });
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-meta-tags-title"
      aria-describedby="modal-meta-tags-description"
      slots={{ backdrop: StyledBackdrop }}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <ModalContent
          sx={{
            ...style,
            width: "90%",
            maxWidth: "520px",
            padding: "24px",
            borderRadius: "24px",
            bgcolor: "#FFFFFF",
            boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
            height: "auto",
            maxHeight: "90dvh",
            overflowY: "auto",
          }}
        >
          <Typography
            sx={{
              marginBottom: { xs: "24px", md: "32px" },
              fontFamily: "var(--cygreRegular), sans-serif",
              fontSize: { xs: "18px", md: "22px" },
              fontWeight: 500,
              color: "#2E263D",
              textAlign: "center",
            }}
          >
            {t("nametatags")}
          </Typography>
          <TextField
            multiline
            rows={10}
            onChange={(e) => setMetaTagsInput(e.target.value)}
            value={metaTagsInput}
            variant="outlined"
            placeholder={t("metaTagsPlaceholder")}
            fullWidth
            sx={{
              marginBottom: { xs: "24px", md: "32px" },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                backgroundColor: "#f6f7fb",
              },
              textarea: {
                fontFamily: "var(--cygreRegular), sans-serif",
                fontSize: "14px",
                lineHeight: "22px",
                border: "none",
                color: "black",
                "&::placeholder": {
                  fontSize: "14px",
                  fontFamily: "var(--cygreRegular), sans-serif",
                  textOverflow: "ellipsis !important",
                  color: "#808080",
                },
              },
            }}
          />
          {error && (
            <Typography
              sx={{ color: "red", fontSize: "14px", marginBottom: { xs: "24px", md: "32px" }, textAlign: "center" }}
            >
              {error}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              gap: "8px",
              flexDirection: { xs: "column-reverse", md: "row" },
              alignItems: "center",
            }}
          >
            <CustomButton
              textButton={t("cancel")}
              width="100%"
              height={40}
              customColor="#6D6777"
              customBorderColor="#C9D0D8"
              customHoverColor="transparent"
              customBorderRadius="8px"
              customFontSize="14px"
              customTextTransform="none"
              onClick={closeModal}
            />
            <CustomButton
              textButton={t("send")}
              width="100%"
              height={40}
              customBgColor="#F4701B"
              customHoverColor="#F4701B"
              customBorderRadius="8px"
              customFontSize="14px"
              customTextTransform="none"
              onClick={processMetaTags}
            />
          </Box>
        </ModalContent>
      </Fade>
    </Modal>
  );
};

export default MetaTagsModal;
