import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import showPreviewIcon from "utils/showPreviewImage";
import ImageIcon from "assets/icons/ImageIcon";
import CustomInput from "components/Input";

interface IMergedUserPwaStats {
  id: string;
  appName: string;
  icon: string | File | null;
  campaignId: number;
  totalInstalls: number;
  installsArrByPrice: Record<string, number>[];
  totalInstallsAmount: string;
}

interface IPwaStatsListProps {
  pwaData: IMergedUserPwaStats[];
}

const PwaStatsList = ({ pwaData }: IPwaStatsListProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: "200px",
        padding: "20px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      <Typography
        component="h3"
        sx={{
          marginBottom: "12px",
          fontFamily: "var(--cygreMedium), sans-serif",
          fontSize: "12px",
          fontWeight: "400",
          color: "#2E263D",
        }}
      >
        {t("pwaStatsListTitle")}
      </Typography>

      <List
        dense
        sx={{
          "&.MuiList-root": { display: "flex", flexDirection: "column", gap: "12px", padding: 0 },
        }}
      >
        {pwaData.map((pwa) => {
          const { id, appName, icon, campaignId, totalInstalls, installsArrByPrice, totalInstallsAmount } = pwa;

          return (
            <ListItem key={id} disablePadding>
              <Accordion sx={{ width: "100%", boxShadow: "none", border: "1px solid #EEEEEE", borderRadius: "8px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={appName} id={`${appName}-header`}>
                  {icon ? (
                    <img
                      src={showPreviewIcon(icon)}
                      alt="pwa-icon"
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        marginRight: "12px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        marginRight: "12px",
                        "& svg": {
                          width: "30px",
                          height: "30px",
                        },
                      }}
                    >
                      <ImageIcon />
                    </Box>
                  )}
                  <Typography
                    sx={{
                      margin: "auto 0",
                      fontSize: 14,
                      fontFamily: "var(--cygreMedium), sans-serif",
                      textAlign: "center",
                    }}
                  >
                    {appName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "var(--cygreRegular), sans-serif",
                      color: "#56CA00",
                    }}
                  >
                    {`PWA id: `}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                        color: "#6D6777",
                      }}
                    >
                      {id}
                    </Typography>
                  </Typography>
                  {campaignId && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontFamily: "var(--cygreRegular), sans-serif",
                        color: "#56CA00",
                      }}
                    >
                      {`Keitaro campaign id: `}
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                          color: "#6D6777",
                        }}
                      >
                        {campaignId}
                      </Typography>
                    </Typography>
                  )}

                  <Grid container rowSpacing={3} columnSpacing={2} sx={{ marginTop: "24px" }}>
                    <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                      <CustomInput
                        fullWidth
                        id="installsAmount"
                        name="installsAmount"
                        value={totalInstallsAmount}
                        label={t("totalInstalls")}
                        height="40px"
                        customBgColor="#F6F7FB"
                        customFontSize="14px"
                        isReadOnly
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                      <CustomInput
                        fullWidth
                        id="installs"
                        name="installs"
                        value={totalInstalls}
                        label={t("totalInstallsLabel")}
                        height="40px"
                        customBgColor="#F6F7FB"
                        customFontSize="14px"
                        isReadOnly
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          lineHeight: 1.22,
                          color: "#2E263D",
                          fontFamily: "var(--cygreRegular), sans-serif",
                          marginBottom: "10px",
                        }}
                      >
                        {t("installByPrice")}
                      </Typography>

                      <Grid container rowSpacing={2} columnSpacing={1}>
                        {installsArrByPrice.length === 0 ? (
                          <CustomInput
                            fullWidth
                            id="emptyField"
                            name="emptyField"
                            value={0}
                            height="40px"
                            customBgColor="#F6F7FB"
                            customFontSize="14px"
                            isReadOnly
                          />
                        ) : (
                          installsArrByPrice.map((installByPrice) => {
                            const price = Object.keys(installByPrice)[0];
                            const installs = Object.values(installByPrice)[0];

                            return (
                              <>
                                <Grid size={4}>
                                  <CustomInput
                                    sx={{ input: { color: "#56CA00", textAlign: "center" } }}
                                    fullWidth
                                    id="installPrice"
                                    name="installPrice"
                                    value={`${price}$`}
                                    height="40px"
                                    customBgColor="#F6F7FB"
                                    customFontSize="14px"
                                    isReadOnly
                                  />
                                </Grid>
                                <Grid size={8}>
                                  <CustomInput
                                    fullWidth
                                    id="installsTotalByPrice"
                                    name="installsTotalByPrice"
                                    value={installs}
                                    height="40px"
                                    customBgColor="#F6F7FB"
                                    customFontSize="14px"
                                    isReadOnly
                                  />
                                </Grid>
                              </>
                            );
                          })
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default PwaStatsList;
