import { IUser } from "interfaces/auth";
import { useTranslation } from "react-i18next";
import useModalStore from "store/modalStore";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import showPreviewIcon from "utils/showPreviewImage";
import Profile from "assets/Profile_pic.png";
import useMediaByValue from "hooks/useMediaByValue";
import GroupIcon from "@mui/icons-material/Group";

interface IUsersStatsProps {
  usersArr: IUser[];
}

const NewUsersStats = ({ usersArr }: IUsersStatsProps) => {
  const { t } = useTranslation();
  const { openUserStatsModal, changeUserInfo } = useModalStore();
  const isMediumeViewPort = useMediaByValue("(min-width:920px)");

  const dateNow = new Date();
  const startDate = new Date(dateNow.setDate(dateNow.getDate() - 7));
  const sortedWeekUsers = usersArr?.filter((user) => new Date(user.createdAt) >= startDate);

  const handleUserStatsModalOpen = (id: string) => {
    openUserStatsModal();
    changeUserInfo({ id });
  };

  return (
    <Grid size={isMediumeViewPort ? 6 : 12} sx={{ padding: "26px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "6px", marginBottom: "8px" }}>
        <Box
          sx={{
            width: "26px",
            height: "26px",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(244, 112, 27, 0.16)",
          }}
        >
          <GroupIcon sx={{ width: "16px", height: "16px", fill: "#F4701B" }} />
        </Box>
        <Typography
          sx={{
            color: "#6D6777",
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          {t("users")}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: "#2E263D",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontWeight: "500",
          fontSize: "14px",
        }}
      >
        {`${t("totalRegistered")} ${usersArr?.length || 0}`}
      </Typography>
      <Typography
        sx={{
          color: "#2E263D",
          fontFamily: "var(--cygreRegular), sans-serif",
          fontWeight: "500",
          fontSize: "14px",
          marginBottom: "8px",
        }}
      >
        {`${t("newWeeklyUsers")} ${sortedWeekUsers?.length || 0}`}
      </Typography>
      <Box
        sx={{
          padding: "16px 16px",
          borderRadius: "8px",
          boxShadow:
            "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--cygreRegular), sans-serif",
            fontWeight: "500",
            fontSize: "12px",
            textAlign: "center",
            color: "#2E263D",
            marginBottom: "8px",
          }}
        >
          {t("newUsersList")}
        </Typography>

        {sortedWeekUsers.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "230px",
              maxHeight: "230px",
            }}
          >
            <Typography
              sx={{
                display: "inline-block",
                maxWidth: "200px",
                fontFamily: "var(--cygreRegular), sans-serif",
                fontWeight: "400",
                fontSize: "12px",
                textAlign: "center",
                color: "#2E263D",
              }}
            >
              {t("emptyNewUsersList")}
            </Typography>
          </Box>
        ) : (
          <List
            dense
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              padding: 0,
              minHeight: "230px",
              maxHeight: "230px",
              overflow: "auto",
            }}
          >
            {sortedWeekUsers?.map((user, index) => {
              const { _id, icon, email, telegram, telegramUsername } = user;

              return (
                <ListItem key={_id} disablePadding>
                  <ListItemButton id={_id} sx={{ padding: 0 }} onClick={() => handleUserStatsModalOpen(_id)}>
                    <ListItemIcon sx={{ minWidth: "10px", fontSize: "12px", marginRight: "6px" }}>
                      {index + 1}.
                    </ListItemIcon>
                    <ListItemAvatar sx={{ minWidth: "20px", marginRight: "6px" }}>
                      <Avatar
                        src={`${icon ? showPreviewIcon(icon) : Profile}`}
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={_id}
                      primary={email || telegram || telegramUsername}
                      sx={{
                        "& .MuiTypography-root": {
                          paddingRight: "10px",
                          fontWeight: 400,
                          fontSize: 12,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </Grid>
  );
};

export default NewUsersStats;
