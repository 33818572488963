export const buildAuthUrl = (
  authServiceName: string,
  referralCode: string | null,
  promoCode: string | null | undefined,
  keitaro_subid: string | null
): string => {
  const baseUrl = `${process.env.REACT_APP_API_URL}auth/${authServiceName}`;
  const params: string[] = [];

  if (referralCode) {
    params.push(`referralCode=${referralCode}`);
  }
  if (promoCode) {
    params.push(`promoCode=${promoCode}`);
  }

  if (keitaro_subid) {
    params.push(`keitaro_subid=${keitaro_subid}`);
  }

  return params.length ? `${baseUrl}?${params.join("&")}` : baseUrl;
};
