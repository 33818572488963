import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { IPwa } from "interfaces/pwas";
import { useTranslation } from "react-i18next";
import StatsTable from "components/StatsTable";
import StatsSelectComponent from "components/StatsSelect";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SelectPeriod from "components/Charts/SelectPeriod";
import { IGroupedInstalls, IGroupedPushSubs, IPushSubscription, ITransaction } from "interfaces/stats";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import CustomSelectComponent from "components/Select";
import useIsDesktop from "hooks/useIsDesktop";
import { timezoneSelectOptions } from "./StatsConstans";
import ViewSwitcher from "components/ViewSwitcher";
import { formatMMDDYYYY } from "utils/formateDate";
import { filterStatsBySelectedPeriod } from "utils/statsHelpers";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

export default function Stats() {
  const token = getToken();
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const [pwas, setPwas] = useState<Array<IPwa>>([]);
  const [pwaIds, setPwaIds] = useState<Array<string>>([]);
  const [pwaSelectOptions, setPwaSelectOptions] = useState<Array<string>>([]);
  const [pwaCampaingIds, setPwaCampaingIds] = useState<Array<string>>([]);
  const [pwaStats, setPwaStats] = useState<Array<IPwa>>([]);
  const [storeStats, setStoreStats] = useState<Array<IPwa>>([]);
  const [report, setReport] = useState<string | number>("");
  const [selectedRange, setSelectedRange] = useState<string>("today");
  const [installs, setInstalls] = useState<IGroupedInstalls>({});
  const [pushSubs, setPushSubs] = useState<IGroupedPushSubs>({});
  const [dateRange, setDateRange] = useState<[string | null, string | null]>([null, null]);
  const [timezone, setTimezone] = useState<string>("Europe/Madrid");
  const [view, setView] = useState<"table" | "grid">("grid");
  const [needNewReport, setNeedNewReport] = useState<boolean>(false);

  const fromDate = dateRange[0];
  const toDate = dateRange[1];

  const { triggerFetch } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: loadingReport, triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: selectedRange === "custom" ? fromDate || null : null,
          to: selectedRange === "custom" && !toDate ? fromDate : toDate || null,
          timezone: `${timezone}`,
          interval: selectedRange === "custom" ? null : selectedRange,
        },
        filters: [
          {
            name: "campaign_id",
            operator: "IN_LIST",
            expression: pwaCampaingIds,
          },
        ],
      },
    },
  });

  const { loading: loadingStoreReport, triggerFetch: getStoreReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: selectedRange === "custom" ? fromDate || null : null,
          to: selectedRange === "custom" && !toDate ? fromDate : toDate || null,
          timezone: `${timezone}`,
          interval: selectedRange === "custom" ? null : selectedRange,
        },
        dimensions: ["keyword"],
        metrics: ["clicks", "campaign_unique_clicks", "day", "year"],
        filters: [
          {
            name: "keyword",
            operator: "IN_LIST",
            expression: pwaIds,
          },
        ],
        sort: [
          {
            name: "clicks",
            order: "DESC",
          },
        ],
      },
    },
  });

  const { triggerFetch: getAllUserTransactions } = useFetch({
    url: "transaction/",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { triggerFetch: getAllPushSubscriptions } = useFetch({
    url: "push/stats/pushsubscriptions",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      id: pwaIds,
      geo: [],
    },
  });

  useEffect(() => {
    void getPwas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pwaCampaingIds.length > 0) {
      void getUserTransactions();
      void getPushSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwaCampaingIds, selectedRange, dateRange]);

  useEffect(() => {
    if (pwaCampaingIds.length > 0 && !report && report !== "empty") {
      buildReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwaCampaingIds, report]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setDateRange([formatMMDDYYYY(dates[0]), formatMMDDYYYY(dates[1])]);
    setNeedNewReport(true);
  };

  async function getPwas() {
    const result = await triggerFetch();
    const fetchedPwas = result?.data?.items || [];
    const filteredPwas = fetchedPwas.filter(
      (pwa: any) => pwa.campaignId !== null && pwa.campaignId !== undefined && pwa.campaignId !== 0
    );
    const pwaSelectOptions = fetchedPwas.map((pwa: IPwa) => {
      if (!pwa.campaignId) {
        return { _id: pwa._id, appName: pwa.appName, campaignId: "empty", icon: pwa.creatives.icon || null };
      } else {
        return { _id: pwa._id, appName: pwa.appName, campaignId: pwa.campaignId, icon: pwa.creatives.icon || null };
      }
    });

    const ids = filteredPwas.map((pwa: IPwa) => pwa._id);

    const campaignId = filteredPwas.map((pwa: IPwa) => pwa.campaignId).filter(Boolean);
    setPwas(filteredPwas);
    setPwaCampaingIds(campaignId);
    setPwaIds(ids);
    setPwaSelectOptions(pwaSelectOptions);
  }

  async function getUserTransactions() {
    const { data } = await getAllUserTransactions();

    const userPwaIds = pwas.map((pwa) => pwa._id);
    const filteredItems = (data?.items || []).filter((item: ITransaction) => {
      return item.type === "pwa-install" && userPwaIds.includes(item.pwaId);
    });

    const filtredItemsBySelectedRange =
      filterStatsBySelectedPeriod(filteredItems, selectedRange, fromDate, toDate) || [];

    const groupedInstalls = pwas.reduce(
      (acc, pwa) => {
        const { campaignId, _id } = pwa;
        acc[campaignId] = filtredItemsBySelectedRange.filter((install: ITransaction) => install.pwaId === _id);
        return acc;
      },
      {} as Record<string, ITransaction[]>
    );

    setInstalls(groupedInstalls);
  }

  async function getPushSubscriptions() {
    const { pushSubscriptions } = await getAllPushSubscriptions();

    const filtredItemsBySelectedRange =
      filterStatsBySelectedPeriod(pushSubscriptions, selectedRange, fromDate, toDate) || [];

    const groupedPushSubsriptions = pwas.reduce((acc, pwa) => {
      const { campaignId, _id } = pwa;
      acc[campaignId] = filtredItemsBySelectedRange?.filter((sub: IPushSubscription) => sub.pwa_id === _id);
      return acc;
    }, {} as IGroupedPushSubs);

    setPushSubs(groupedPushSubsriptions);
  }

  async function buildReport() {
    setPwaStats([]);
    setStoreStats([]);
    setNeedNewReport(false);

    if (report === "empty") {
      return;
    }

    const res = await getReport();
    const storeReport = await getStoreReport();

    if (res?.data?.rows) {
      setPwaStats(res.data.rows);
    }
    if (storeReport?.data?.rows) {
      setStoreStats(storeReport.data.rows);
    }
  }

  const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if (name === "pwaName") {
      if (pwaStats.length === 0 || storeStats.length === 0) {
        setNeedNewReport(true);
      }

      setReport(value);
    } else if (name === "timezone") {
      setTimezone(value);
      setNeedNewReport(true);
    }
  };

  const handleResetRange = () => {
    setPwaStats([]);
    setStoreStats([]);
    setReport("");
    setTimezone("Europe/Madrid");
    setDateRange([null, null]);
    setSelectedRange("today");
  };

  const toggleView = () => {
    setView(view === "table" ? "grid" : "table");
  };

  const isLoading = loadingReport || loadingStoreReport;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontSize: "24px" }}>
            {t("statistics")}
          </Typography>
        </Grid>
        <Grid
          size={12}
          sx={{
            display: "flex",
            flexDirection: isDesktop ? "column" : "row",
            alignItems: "flex-end",
            rowGap: isDesktop ? "16px" : "0px",
            columnGap: isDesktop ? "0px" : "10px",
          }}
        >
          <Box sx={{ width: { xs: "100%", lg: "400px" } }}>
            <StatsSelectComponent
              name="pwaName"
              title={t("selectPwa")}
              placeholder={t("selectPwaPush")}
              options={pwaSelectOptions}
              backgroundColor="#F6F7F9"
              noBorder={true}
              value={report}
              onChange={handleSelectChange}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", lg: "400px" } }}>
            <CustomSelectComponent
              options={timezoneSelectOptions}
              name="timezone"
              title={t("timezone")}
              value={timezone}
              onChange={(event: any) => handleSelectChange(event)}
              noBorder
              backgroundColor="#F6F7F9"
            />
          </Box>

          <Box
            sx={{
              width: { xs: "100%", lg: "200px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: "6px",
              paddingTop: "20px",
            }}
          >
            <SelectPeriod
              selectedPeriod={selectedRange}
              onChange={setSelectedRange}
              onChangeDateRange={handleDateChange}
              setNeedNewReport={setNeedNewReport}
              customStyles={{
                width: { xs: "100%", lg: "160px" },
                marginLeft: "0px",
                backgroundColor: "#F6F7F9",
              }}
            />
            <BootstrapTooltip title={t("resetFilter")} placement="top">
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleResetRange}
              >
                <CachedOutlinedIcon sx={{ width: "26px", height: "26px", color: "#6D6777", marginBottom: "4px" }} />
              </Box>
            </BootstrapTooltip>
          </Box>
          {isDesktop ? null : <ViewSwitcher view={view} toggleView={toggleView} />}
        </Grid>
        <Grid size={12}>
          <StatsTable
            getPwas={getPwas}
            loading={isLoading}
            pwas={pwas}
            report={report}
            needNewReport={needNewReport}
            buildReport={buildReport}
            installs={installs}
            pushSubscribes={pushSubs}
            allPwasStats={pwaStats}
            storeStats={storeStats}
            view={view}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
