import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppStore, useErrorAppStore } from "store/appStore";
import { ChangeEvent } from "react";
import { AppDataMockup } from "interfaces/mockupApp";
import { useTranslation } from "react-i18next";
import CustomInput from "components/Input";

export default function ForDesktop() {
  const { appData, saveAppData } = useAppStore();
  const { t } = useTranslation();
  const { errors, clearError } = useErrorAppStore();

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        "&::before": { display: " none" },
      }}
    >
      <AccordionSummary
        sx={{ padding: { xs: "0px 16px", md: "0px 20px" }, height: { xs: "56px", md: "64px" } }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: "16px", fontFamily: "var(--cygreRegular), sans-serif", color: "#2e263d" }}>
          {t("forpc")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxWidth: "500px", padding: { xs: "0px 16px 16px 16px", md: "0px 20px 20px 20px" } }}>
        <CustomInput
          required
          fullWidth
          id="descriptionPC"
          placeholder={t("lastUpdateDescription")}
          multiline={true}
          rows={4}
          name="descriptionPC"
          label={t("description")}
          value={appData.descriptionPC}
          onChange={(e) => handleInputChange(e, "descriptionPC")}
          customBgColor="#F6F7F9"
          error={!!errors.descriptionPC}
          helperText={errors.descriptionPC}
        />
      </AccordionDetails>
    </Accordion>
  );
}
