import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomButton from "components/Button";
import CustomInput from "components/Input";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SignpostIcon from "@mui/icons-material/Signpost";

const TrafficManagementPage = () => {
  const token = getToken();
  const { t } = useTranslation();
  const [currentTrafficLink, setCurrentTrafficLink] = useState<string>("");
  const [trafficInputValue, setTrafficInputValue] = useState<string>("");
  const linkRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:\d+)?(\/[^\s]*)?$/;

  const { triggerFetch: getCurrentLink } = useFetch({
    url: "admin/traffic",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: updateSettingsLoading, triggerFetch: updateGlobalSettings } = useFetch({
    url: "admin/settings",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getCurrentTrafficLink = async () => {
    const { currentInappropriateTrafficLink } = await getCurrentLink();

    if (currentInappropriateTrafficLink) {
      setCurrentTrafficLink(currentInappropriateTrafficLink);
    }
  };

  const handleTrafficInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setTrafficInputValue(value);
  };

  const handleSubmit = async () => {
    if (!trafficInputValue) {
      toast.error(t("emptyTrafficInputValue"));
      return;
    }

    if (!linkRegex.test(trafficInputValue)) {
      toast.error(t("invalidTrafficLink"));
      return;
    }

    const newSettingsValue = {
      inappropriateTrafficLink: trafficInputValue,
    };
    const updatedGlobalSettings = await updateGlobalSettings(newSettingsValue);

    if (updatedGlobalSettings.success) {
      setCurrentTrafficLink(trafficInputValue);
      setTrafficInputValue("");
      toast.success(t("successfulTrafficLinkSave"));
    } else {
      toast.error(t("unsuccessfulTrafficLinkSave"));
    }
  };

  const handleClearTrafficLink = async () => {
    if (!currentTrafficLink) {
      return;
    }

    const settingsWithEmptyTrafficLink = {
      inappropriateTrafficLink: "",
    };
    const emptyTrafficLink = await updateGlobalSettings(settingsWithEmptyTrafficLink);

    if (emptyTrafficLink.success) {
      setCurrentTrafficLink("");
      toast.success(t("successfulTrafficLinkDelete"));
    } else {
      toast.error(t("unsuccessfulTrafficLinkDelete"));
    }
  };

  useEffect(() => {
    void getCurrentTrafficLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", gap: "6px", alignItems: "center", marginBottom: "24px" }}>
        <SignpostIcon sx={{ width: "36px", height: "36px", color: "#6D6777" }} />
        <Typography
          sx={{
            fontFamily: "var(--cygreRegular), sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            color: "#2E263D",
          }}
        >
          {t("trafficPageTitle")}
        </Typography>
      </Box>
      {/* <Typography variant="h4" sx={{ fontSize: "24px", marginBottom: "20px" }}>
        {t("trafficPageTitle")}
      </Typography> */}
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid size={{ xs: 11, lg: 11.5 }}>
          <CustomInput
            required
            disabled
            fullWidth
            id="trafficManagement"
            height="40px"
            placeholder={t("emptyData")}
            value={currentTrafficLink}
            name="trafficManagement"
            label={t("currentTrafficLink")}
            customBgColor="#F6F7F9"
          />
        </Grid>
        <Grid size={{ xs: 1, lg: 0.5 }} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <BootstrapTooltip title={t("delete")} placement="top">
            <Box
              sx={{
                maxHeight: " 40px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={handleClearTrafficLink}
            >
              <DeleteOutlineIcon
                sx={{
                  width: " 30px",
                  height: "40px",
                  color: "#6D6777",
                  "&:hover": {
                    color: "#F4701B",
                  },
                }}
              />
            </Box>
          </BootstrapTooltip>
        </Grid>
        <Grid size={9}>
          <CustomInput
            required
            fullWidth
            id="trafficManagement"
            height="40px"
            placeholder={t("setInappropriateTraffic")}
            value={trafficInputValue}
            name="trafficManagement"
            label={t("changeTrafficLink")}
            onChange={(event) => {
              handleTrafficInputChange(event);
            }}
            customBgColor="#F6F7F9"
          />
        </Grid>
        <Grid size={3} sx={{ display: "flex", alignItems: "flex-end" }}>
          <CustomButton
            textButton={t("save")}
            width="100%"
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="12px"
            customPadding="0"
            loading={updateSettingsLoading}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TrafficManagementPage;
