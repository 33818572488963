import { Box, Card, CardActions, CardContent, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { ICreativeGroupItem } from "interfaces/creatives";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import showPreviewIcon from "utils/showPreviewImage";
import { LazyImage } from "components/LazyImage";
import imgPlaceholder from "assets/imageApp.png";
import useCautionStore from "store/cautionStore";

interface ICreativesListComponentProps {
  creativesArr: ICreativeGroupItem[];
  setIsModalOpen: (value: boolean) => void;
  setCreativesGroupForEdit: (group: ICreativeGroupItem) => void;
}

const CreativesList = ({ creativesArr, setIsModalOpen, setCreativesGroupForEdit }: ICreativesListComponentProps) => {
  const { t } = useTranslation();
  const { handleCreativeToDeleteChange, openCreativesGroupDeleteModal } = useCautionStore();

  const handleDeleteModalOpen = (creativeGroup: ICreativeGroupItem) => {
    handleCreativeToDeleteChange(creativeGroup);
    openCreativesGroupDeleteModal();
  };

  const handleEditCreativeModalOpen = (creativeGroup: ICreativeGroupItem) => {
    setIsModalOpen(true);
    setCreativesGroupForEdit(creativeGroup);
  };

  return (
    <Grid container spacing={2} sx={{ padding: "2px" }}>
      {creativesArr.length === 0 ? (
        <Grid size={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <Typography variant="subtitle2" fontWeight={400}>
            {t("noitems")}
          </Typography>
        </Grid>
      ) : (
        creativesArr.map((createve) => {
          const { _id, group_name, group_type, images } = createve;
          return (
            <Grid key={_id} size={{ xs: 12, md: 6, xl: 4 }}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3)",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: "flex-start",
                    flex: "1 1 auto",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
                    <img
                      src={showPreviewIcon(images.icon)}
                      alt="pwa-icon"
                      style={{
                        width: 76,
                        height: 76,
                        borderRadius: "8px",
                      }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "var(--cygreRegular), sans-serif",
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#2E263D",
                        }}
                      >
                        {t(`${group_name}`)}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "var(--cygreRegular), sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#7c7c81",
                        }}
                      >
                        {`(${t(`${group_type}`)})`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "nowrap",
                      gap: "4px",
                      width: "100%",
                      paddingBottom: "10px",
                      overflowX: "auto",
                      flex: "1 1 auto",
                    }}
                  >
                    {images.previews.map((preview: any, index: number) => {
                      const img = new Image();
                      img.src = preview;
                      const aspectRatio = img.width / img.height;
                      const isPortrait = aspectRatio < 1;
                      return (
                        <LazyImage
                          key={index}
                          customStyles={{
                            objectFit: "contain",
                            aspectRatio: aspectRatio,
                            maxHeight: "150px",
                            maxWidth: isPortrait ? "100px" : "80%",
                            borderRadius:
                              index === 0 ? "8px 0 0 8px" : index === images.previews.length - 1 ? "0 8px 8px 0" : "0",
                          }}
                          src={showPreviewIcon(preview)}
                          placeholder={imgPlaceholder}
                          alt="preview"
                        />
                      );
                    })}
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: "flex-end",
                    backgroundColor: "#f3f2f2",
                    borderTop: "1px solid rgba(165, 163, 174, 0.3)",
                    mt: "auto",
                  }}
                >
                  <BootstrapTooltip title={t("edit")} placement="top">
                    <IconButton aria-label="edit-icon" onClick={() => handleEditCreativeModalOpen(createve)}>
                      <EditIcon sx={{ width: "20px", height: "20px", color: "#6D6777" }} />
                    </IconButton>
                  </BootstrapTooltip>
                  <BootstrapTooltip title={t("delete")} placement="top">
                    <IconButton aria-label="delete-icon" onClick={() => handleDeleteModalOpen(createve)}>
                      <DeleteIcon sx={{ width: "20px", height: "20px", color: "#6D6777" }} />
                    </IconButton>
                  </BootstrapTooltip>
                </CardActions>
              </Card>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default CreativesList;
