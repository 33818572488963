import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PushTable from "components/PushTable";
import { useNavigate } from "react-router";
import StatisticPushWidget from "components/StatisticPushWidget";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const Pushes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function createPush() {
    navigate("/push/create");
  }

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <Typography variant="h4" sx={{ fontSize: "24px" }}>
          {t("pushNotifications")}
        </Typography>
        <Button
          sx={{
            color: "#fff",
            backgroundColor: "#F4701B",
            textTransform: "uppercase",
            "&:hover": {
              backgroundColor: "#F0670B",
            },
          }}
          className="create_btn"
          variant="contained"
          aria-label="create pwa"
          size="medium"
          onClick={createPush}
        >
          {t("createPush")}
        </Button>
      </Box>

      <StatisticPushWidget />
      <PushTable />
    </Box>
  );
};

export default Pushes;
