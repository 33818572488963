import styled from "@emotion/styled";
import { Typography, Box, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import ImageIcon from "assets/icons/ImageIcon";
import ActionButton from "components/ActionButton";
import { IPwa } from "interfaces/pwas";
import showPreviewIcon from "utils/showPreviewImage";
import { useNavigate } from "react-router";
import { RenderPWAStatus } from "components/PwaList/RenderPWAStatus";
import MenuTableButton from "components/Table/MenuTableButton";
import { fetchCountries } from "../../utils/fetchCountries";

interface PwaListTableProps {
  filteredItems: IPwa[];
  getPwas: () => void;
}

export const PWACardsView = ({ filteredItems, getPwas }: PwaListTableProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const countries = fetchCountries();
  return (
    <Grid container spacing={{ xs: 1.2, md: 1.4, xl: 1.6 }}>
      {filteredItems?.length === 0 ? (
        <Grid size={12}>
          <Typography variant="subtitle2">{t("noitems")}</Typography>
        </Grid>
      ) : (
        filteredItems?.map(({ _id, appName, creatives, categoryVertical, domain, status, metaTags, geo }: IPwa) => {
          const pwaCountries = geo?.map(
            (country) => countries.find((countryItem) => countryItem.code === country)?.flag
          );
          const trackerValue = !geo?.length ? "Multi Geo" : pwaCountries.join(" ") || "Multi Geo";
          return (
            <Grid size={{ xs: 12, lg: 12 / 2, xl: 12 / 3 }} key={_id}>
              <Card
                key={_id}
                tabIndex={-1}
                sx={{
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 4px 0px rgba(165, 163, 174, 0.3)",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: { xs: "16px 16px 20px 16px !important", md: "20px 20px 28px 20px !important" },
                    height: "100%",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      marginBottom: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/pwa/edit-pwa/${_id}`);
                      }}
                    >
                      {creatives.icon ? (
                        <img
                          src={showPreviewIcon(creatives.icon)}
                          alt="icon"
                          style={{
                            width: 56,
                            height: 56,
                            borderRadius: "50%",
                            flex: "0 0 auto",
                          }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 56,
                            height: 56,
                            background: "#F6F7FB",
                            borderRadius: "50%",
                            flex: "0 0 auto",
                          }}
                        >
                          <ImageIcon />
                        </Box>
                      )}
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", marginLeft: "12px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: "var(--cygreRegular), sans-serif",
                            lineHeight: 1.43,
                            color: "#2e263d",
                            maxWidth: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {appName}
                        </Typography>
                        {status && <RenderPWAStatus pwaStatus={status} />}
                      </Box>
                    </Box>
                    <MenuTableButton getPwas={getPwas} id={_id} pwaMeta={metaTags} />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "12px", marginBottom: "24px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                      <TypographyStyled
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: 1.43,
                          color: "#6d6777",
                          fontFamily: "var(--cygreMedium), sans-serif",
                        }}
                        variant="subtitle2"
                      >
                        {t("domain")}:
                      </TypographyStyled>
                      <TypographyStyled
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: 1.43,
                          color: "#6d6777",
                          fontFamily: "var(--cygreMedium), sans-serif",
                        }}
                      >
                        {t("vertical")}:
                      </TypographyStyled>
                      <TypographyStyled
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: 1.43,
                          color: "#6d6777",
                          fontFamily: "var(--cygreMedium), sans-serif",
                        }}
                      >
                        {t("tracker")}:
                      </TypographyStyled>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "var(--cygreRegular), sans-serif",
                          lineHeight: 1.43,
                          color: "#2e263d",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(`https://${domain?.name}`, "_blank");
                        }}
                      >
                        {domain?.name || "-"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: 1.43,
                          color: "#2e263d",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {categoryVertical || "-"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: 1.43,
                          color: "#2e263d",
                          fontFamily: "var(--cygreRegular), sans-serif",
                        }}
                      >
                        {trackerValue || "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginLeft: "8px" }}>
                    <ActionButton domain={domain?.name} getPwas={getPwas} status={status} id={_id} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

const TypographyStyled = styled(Typography)(() => {
  return {
    color: "#6D6777",
    fontWeight: 500,
    fontFamily: "var(--cygreRegular), sans-serif",
  };
});
