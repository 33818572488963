import React, { ChangeEvent } from "react";
import TrackSwitch from "./TrackSwitch";
import { AppDataMockup } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import CustomInput from "components/Input";

interface IModal {
  handleChangeSwith?: (name: keyof AppDataMockup) => void;
}

const ModalAdditional = ({ handleChangeSwith }: IModal) => {
  const { t } = useTranslation();
  const { appData, saveAppData } = useAppStore();

  const handleChange = (name: keyof AppDataMockup) => {
    if (handleChangeSwith) {
      handleChangeSwith(name);
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  return (
    <Box
      sx={{
        marginTop: "16px",
        padding: { xs: "16px", md: "20px" },
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        fontFamily: "var(--cygreMedium), sans-serif",
      }}
    >
      <Typography
        sx={{
          marginBottom: "32px",
          fontFamily: "var(--cygreMedium), sans-serif",
          color: "#2E263D",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "32px",
        }}
      >
        {t("additionalsettings")}
      </Typography>

      <CustomInput
        required
        fullWidth
        id="unigueClicksTime"
        height="40px"
        placeholder={t("enterHours")}
        name="unigueClicksTime"
        label={t("uniqueClicksDuration")}
        value={appData.cookiesTtl}
        onlyNumbersValues={true}
        onChange={(event) => handleInputChange(event, "cookiesTtl")}
        customBgColor="#F6F7F9"
        iconEnd={<Typography sx={{ color: "#B4B1B9", fontSize: "14px" }}>{t("hours")}</Typography>}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", marginTop: "24px" }}>
        <TrackSwitch
          description={t("showcookiesconsent")}
          label={t("cookies")}
          isSelected={appData.filesCookies}
          handleChange={() => {
            handleChange("filesCookies");
          }}
        />
        <TrackSwitch
          description={t("displayPWA")}
          label="Fullscreen"
          isSelected={appData.fullscreen}
          handleChange={() => {
            handleChange("fullscreen");
          }}
        />
        <TrackSwitch
          description={t("ignorecloak")}
          label={t("cloakignoring")}
          isSelected={appData.ignoringCloaca}
          handleChange={() => {
            handleChange("ignoringCloaca");
          }}
        />
      </Box>
    </Box>
  );
};

export default ModalAdditional;
