import { IconButton } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { useTranslation } from "react-i18next";

type DateRangeArray = [Date | string | null, Date | string | null];

interface ICustomDatePickerProps {
  onChangeCallback: (dates: DateRangeArray) => void;
  pageChange: (page: number) => void;
}

const CustomDatePicker = ({ onChangeCallback, pageChange }: ICustomDatePickerProps) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    pageChange(0);
    onChangeCallback(dates);
  };

  const ExampleCustomInput = forwardRef(({ onClick }: any, ref) => (
    <BootstrapTooltip title={t("dateFilter")} placement="top">
      <IconButton
        onClick={onClick}
        ref={ref as React.Ref<HTMLButtonElement>}
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "8px",
          backgroundColor: "#F6F7FB",
          boxShadow:
            "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
        }}
      >
        <CalendarMonthIcon sx={{ color: "#6D6777" }} />
      </IconButton>
    </BootstrapTooltip>
  ));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <DatePicker
      open={isOpen}
      selected={startDate}
      startDate={startDate}
      endDate={endDate as any}
      maxDate={new Date()}
      onChange={onChange}
      selectsRange
      withPortal
      customInput={<ExampleCustomInput />}
      onClickOutside={() => setIsOpen(false)}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
    />
  );
};

export default CustomDatePicker;
