import { Box, IconButton, Modal, Typography } from "@mui/material";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { useTranslation } from "react-i18next";
import CloseButtonIcon from "assets/icons/Close";
import CustomButton from "components/Button";
import CustomSelectComponent from "components/Select";
import useVerticals from "mokcup/verticals";
import { useEffect, useRef, useState } from "react";
import CustomInput from "components/Input";
import CustomMultiValueInput from "./CustomMultiValueInput";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PreveiwsList from "./PreveiwsList";
import { ICreativeGroupItem } from "interfaces/creatives";
import { SortEnd } from "react-sortable-hoc";
import { toast } from "react-toastify";

interface ICreativesModalComponentProps {
  open: boolean;
  creativeGroupeForEdit: ICreativeGroupItem | null;
  setCreativesGroupForEdit: (group: ICreativeGroupItem | null) => void;
  setIsModalOpen: (value: boolean) => void;
  setCreativesGroups: React.Dispatch<React.SetStateAction<ICreativeGroupItem[]>>;
}

const CreativesCreateAndEditModal = ({
  open,
  setIsModalOpen,
  setCreativesGroups,
  creativeGroupeForEdit,
  setCreativesGroupForEdit,
}: ICreativesModalComponentProps) => {
  const { t } = useTranslation();
  const token = getToken();
  const verticals = useVerticals();
  const [creativesGroupName, setCreativesGroupName] = useState<string>("");
  const [selectedVertical, setSelectedVertical] = useState<string>("");
  const [creativesGroupTags, setCreativesGroupTags] = useState<string[]>([]);
  const [localIcon, setLocalIcon] = useState<File | string | null>(null);
  const [localPreviews, setLocalPreviews] = useState<(File | string)[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const iconInputRef = useRef<HTMLInputElement>(null);

  const { loading: isCreateGroupLoading, triggerFetch: createCreativesGroup } = useFetch({
    url: "creatives/create-group",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: isUpdateGroupLoading, triggerFetch: updateCreativesGroup } = useFetch({
    url: `admin/creatives/${creativeGroupeForEdit?._id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: oneCreativeDeleteLoading, triggerFetch: deleteOneCreative } = useFetch({
    url: `admin/creatives/${creativeGroupeForEdit?._id}/delete-one`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCreativesGroupForEdit(null);
  };

  const handleImagesAdd = (event: any) => {
    if (event.currentTarget.id === "icon-input" && iconInputRef.current) {
      iconInputRef.current.click();
    } else if (event.currentTarget.id === "icon-preview" && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      if (name === "icon-upload") {
        setLocalIcon(files[0]);
      } else if (name === "preview-upload") {
        setLocalPreviews((prevPreviews) => [...prevPreviews, ...Array.from(files)]);
      }
    }
  };

  const reOrderPreviews = (sort: SortEnd) => {
    const newPreviews = localPreviews.map((preview, i) => {
      if (i === sort.oldIndex) {
        return sort.newIndex === sort.oldIndex ? preview : localPreviews[sort.newIndex];
      } else if (i === sort.newIndex) {
        return sort.oldIndex === sort.newIndex ? preview : localPreviews[sort.oldIndex];
      } else {
        return preview;
      }
    });
    setLocalPreviews(newPreviews);
  };

  const handleGroupCreate = async () => {
    if (!creativesGroupName || !selectedVertical || !creativesGroupTags.length || !localIcon || !localPreviews.length) {
      toast.error(t("allFieldAreRequire"));
      return;
    }
    const formData = new FormData();

    formData.append("group_name", creativesGroupName);
    formData.append("group_type", selectedVertical);
    formData.append("group_tags", JSON.stringify(creativesGroupTags));

    if (localIcon) {
      formData.append("icon", localIcon);
    }

    localPreviews.forEach((file) => {
      formData.append("previews", file);
    });

    try {
      const response = await createCreativesGroup(formData);
      setCreativesGroups((prevState: ICreativeGroupItem[]) => [...prevState, response.group]);
      toast.success(t("successfulCreativesGroupAdded"));

      setIsModalOpen(false);
    } catch (error) {
      toast.error(t("unsuccessfulCreativesGroupAdded"));
    }
  };

  const handleGroupEdit = async () => {
    if (!creativesGroupName || !selectedVertical || !creativesGroupTags.length || !localIcon || !localPreviews.length) {
      toast.error(t("allFieldAreRequire"));
      return;
    }
    const formData = new FormData();

    formData.append("group_name", creativesGroupName);
    formData.append("group_type", selectedVertical);
    formData.append("group_tags", JSON.stringify(creativesGroupTags));

    if (localIcon instanceof File) {
      formData.append("new_icon", localIcon);
    } else if (typeof localIcon === "string") {
      formData.append("old_icon", localIcon);
    } else {
      formData.append("icon_removed", "true");
    }

    const existingPreviews = localPreviews
      .map((item, index) => (typeof item === "string" ? { src: item, order: index } : null))
      .filter(Boolean);

    if (existingPreviews.length) {
      formData.append("existing_previews", JSON.stringify(existingPreviews));
    }

    const newPreviewsFiles: File[] = [];
    const newPreviewsOrders: number[] = [];

    localPreviews.forEach((item, index) => {
      if (item instanceof File) {
        newPreviewsFiles.push(item);
        newPreviewsOrders.push(index);
      }
    });

    if (newPreviewsFiles.length) {
      newPreviewsFiles.forEach((file) => {
        formData.append("new_previews", file);
      });

      formData.append("new_previews_order", JSON.stringify(newPreviewsOrders));
    }

    try {
      const response = await updateCreativesGroup(formData);
      setCreativesGroups((prevState: ICreativeGroupItem[]) => {
        const updatedCreativeGroupsArr = prevState.map((item) =>
          item._id === response.group._id ? response.group : item
        );
        return updatedCreativeGroupsArr;
      });
      toast.success(t("successCreativeGropeUpdate"));

      handleModalClose();
    } catch (error) {
      toast.error(t("unsuccessCreativeGroupUpdate"));
    }
  };

  const handleIconDelete = async (iconSrc: string | File) => {
    try {
      if (typeof iconSrc === "string" && creativeGroupeForEdit) {
        const result = await deleteOneCreative({ deletionCreative: iconSrc });
        if (result.success) {
          setLocalIcon(null);
          toast.success(t("successOneCreativeDeleted"));
          return;
        }
      }
    } catch (error) {
      toast.error(t("unsuccessOneCreativeDeleted"));
    }

    setLocalIcon(null);
  };

  const filterLocalImage = (index: number) => {
    const newPreviews = localPreviews.filter((preview, i) => i !== index);
    setLocalPreviews(newPreviews);
  };

  const handlePreviewDelete = async ({ preview, index }: { preview: string | File; index: number }) => {
    try {
      if (typeof preview === "string" && creativeGroupeForEdit) {
        const result = await deleteOneCreative({ deletionCreative: preview });
        if (result.success) {
          filterLocalImage(index);
          toast.success(t("successOneCreativeDeleted"));
          return;
        }
      }
    } catch (error) {
      toast.error(t("unsuccessOneCreativeDeleted"));
    }

    filterLocalImage(index);
  };

  useEffect(() => {
    if (!creativeGroupeForEdit) return;

    const { group_name, group_type, group_tags, images } = creativeGroupeForEdit;
    setCreativesGroupName(group_name);
    setSelectedVertical(group_type);
    setCreativesGroupTags(group_tags);
    setLocalIcon(images.icon);
    setLocalPreviews(images.previews);
  }, [creativeGroupeForEdit]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-push-template-title"
      aria-describedby="modal-push-template-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: "24px",
          bgcolor: "#FFFFFF",
          boxShadow: "0 0 4px 0 rgba(165, 163, 174, 0.3);",
          width: "90%",
          maxWidth: "720px",
          height: "auto",
          maxHeight: "90dvh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16, width: 32, height: 32 }}
          aria-label="modal-close-button"
          onClick={handleModalClose}
        >
          <CloseButtonIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          sx={{
            marginBottom: { xs: "24px", md: "32px" },
            paddingRight: "14px",
            fontSize: { xs: "20px", md: "24px" },
            fontWeight: 400,
            lineHeight: 1.22,
            color: "#2E263D",
          }}
        >
          {t("uploadyourimages")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "24px", md: "16px" },
            marginBottom: "24px",
          }}
        >
          <CustomInput
            required
            fullWidth
            id="groupName"
            height="40px"
            placeholder={t("enterGroupName")}
            name="groupName"
            label={t("name")}
            value={creativesGroupName}
            onChange={(event: any) => setCreativesGroupName(event.target.value)}
            customBgColor="#F6F7F9"
          />
          <CustomSelectComponent
            title={t("category")}
            options={verticals}
            placeholder={t("entercategory")}
            backgroundColor="#F6F7F9"
            noBorder={true}
            value={selectedVertical}
            onChange={(event: any) => {
              setSelectedVertical(event.target.value);
            }}
          />
        </Box>
        <CustomMultiValueInput
          tags={creativesGroupTags}
          setTags={setCreativesGroupTags}
          height="40px"
          customBgColor="#F6F7F9"
          label={t("tags")}
          placeholder={t("enterTag")}
        />
        {localIcon ? (
          <Box sx={{ position: "relative", width: "104px", height: "104px", margin: "24px 0px" }}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "100%",
                border: "1px dashed #C9D0D8",
                borderRadius: "8px",
                backgroundColor: "#F6F7FB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              src={typeof localIcon === "string" ? localIcon : URL.createObjectURL(localIcon)}
              alt="icon"
            />
            <BootstrapTooltip title={t("delete")} placement="top">
              <IconButton
                sx={{ position: "absolute", top: 0, right: 0, transform: "translate(50%, -50%)" }}
                aria-label="delete-icon"
                onClick={() => handleIconDelete(localIcon)}
              >
                <DeleteOutlineIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "#dc1d00",
                    border: "1px solid #dc1d00",
                    backgroundColor: "#FFF",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </BootstrapTooltip>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "104px",
              height: "104px",
              margin: "24px 0px",
              border: "1px dashed #C9D0D8",
              borderRadius: "8px",
              backgroundColor: "#F6F7FB",
              textAlign: "center",
              position: "relative",
              cursor: "pointer",
            }}
            id="icon-input"
            onClick={handleImagesAdd}
          >
            <input
              type="file"
              id="icon-upload"
              name="icon-upload"
              onChange={handleInputChange}
              ref={iconInputRef}
              style={{
                position: "absolute",
                display: "none",
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            />
            <Typography
              sx={{
                fontFamily: "var(--cygreRegular), sans-serif",
                color: "#2E263D",
                fontSize: "14px",
                lineHeight: 1,
              }}
            >
              {t("icon")}
            </Typography>
          </Box>
        )}

        <PreveiwsList
          localPreviews={localPreviews}
          handleImagesAdd={handleImagesAdd}
          reOrderPreviews={reOrderPreviews}
          handleInputChange={handleInputChange}
          fileInputRef={fileInputRef}
          handlePreviewDelete={handlePreviewDelete}
        />

        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: { xs: "column-reverse", sm: "row" },
            alignItems: "center",
            marginTop: { xs: "24px", md: "32px" },
          }}
        >
          <CustomButton
            width="100%"
            height={40}
            textButton={t("cancel")}
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customHoverColor="transparent"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={handleModalClose}
          />
          <CustomButton
            width="100%"
            textButton={t(creativeGroupeForEdit ? "save" : "add")}
            height={40}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            customTextTransform="none"
            onClick={creativeGroupeForEdit ? handleGroupEdit : handleGroupCreate}
            loading={isCreateGroupLoading || isUpdateGroupLoading}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default CreativesCreateAndEditModal;
