import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { buildAuthUrl } from "utils/buildAuthUrl";

declare global {
  interface Window {
    onTelegramAuth: (user: any) => void;
  }
}

export const ButtonTelegramAuth = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [searchparams] = useSearchParams();
  const referralCode = searchparams.get("ref");
  const promoCode = searchparams.get("promo")?.toUpperCase();
  const keitaro_subid = localStorage.getItem("keitaro_subid");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.setAttribute("data-telegram-login", "ducksay_bot");
      script.setAttribute("data-size", "large");

      const authUrl = buildAuthUrl("telegram", referralCode, promoCode, keitaro_subid);
      script.setAttribute("data-auth-url", authUrl);

      script.setAttribute("data-userpic", "false");
      script.setAttribute("data-request-access", "write");
      script.onload = () => {
        // console.log("Telegram widget script loaded successfully.");
      };

      script.onerror = () => {
        console.error("Error loading Telegram widget script.");
      };

      if (containerRef.current) {
        containerRef.current.appendChild(script);
      }

      window.onTelegramAuth = function (user) {
        // console.log("User authenticated:", user);
      };

      return () => {
        if (containerRef.current) {
          containerRef.current.innerHTML = "";
        }
      };
    }
  }, []);

  return <div ref={containerRef}></div>;
};
