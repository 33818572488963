import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { usePushStore } from "store/pushStore";
import StandartSets from "components/StandartSets";
import IntervalSets, { DayKey } from "components/IntervalSets";
import { IPwa } from "interfaces/pwas";
import useFetch from "hooks/useFetch";
import { getToken } from "components/Stats";
import StatsSelectComponent from "components/StatsSelect";
import EventSets from "../EventSets";
import { TPushEvent } from "../../interfaces/mockupApp";

export interface ILang {
  language: string;
  name: string;
}

interface IMainSection {
  setSelectedPwa: (pwa: IPwa) => void;
  pwaID?: string;
  delivery?: string;
  intervalDates?: Record<DayKey, string | null>;
  scheduledTimestamp?: number;
  useUserTimezone?: boolean;
  pushEvent?: TPushEvent;
}

export default function MainSection({
  setSelectedPwa,
  pwaID,
  delivery,
  intervalDates,
  scheduledTimestamp,
  useUserTimezone,
  pushEvent,
}: IMainSection) {
  const { t } = useTranslation();
  const [pwas, setPwas] = useState<Array<IPwa>>([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectValue, setSelectValue] = useState<string | number>("");

  useEffect(() => {
    if (pwaID) {
      const selectedPwa = pwas.find((pwa) => pwa._id === pwaID);
      if (selectedPwa) {
        setSelectedPwa(selectedPwa);
        setSelectValue(String(selectedPwa.campaignId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwaID, pwas]);

  const { pushData, savePushData } = usePushStore();

  const token = getToken();
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAppCampaignId = Number(event.target.value);

    const selectedPwa = pwas.find((pwa) => pwa.campaignId === selectedAppCampaignId);

    if (selectedPwa) {
      setSelectedPwa(selectedPwa);
      setSelectValue(String(selectedAppCampaignId));
      savePushData({
        pwaId: selectedPwa._id,
        icon: selectedPwa.creatives?.icon || "",
        origin: selectedPwa.domain?.name || "",
      });
    }
  };

  const { triggerFetch } = useFetch({
    url: "pwa/user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  async function getPwas() {
    const result = await triggerFetch();

    const pwas = result?.data?.items || [];

    const selectOptions = pwas
      .filter((pwa: any) => pwa.campaignId !== null && pwa.campaignId !== undefined && pwa.campaignId !== 0)
      .map((pwa: any) => {
        return { _id: pwa._id, appName: pwa.appName, campaignId: pwa.campaignId, icon: pwa.creatives.icon || null };
      });

    setSelectOptions(selectOptions);

    setPwas(result?.data?.items || null);
  }

  useEffect(() => {
    void getPwas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    savePushData({
      delivery: value,
    });
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        "&::before": {
          content: "none",
        },
        "&.Mui-expanded": { margin: 0 },
      }}
    >
      <AccordionSummary
        sx={{ padding: { xs: "0px 16px", md: "0px 20px" }, height: { xs: "56px", md: "64px" } }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          sx={{ lineHeight: "32px", fontSize: "16px", color: "#2E263D", fontFamily: "var(--cygreMedium), sans-serif" }}
        >
          {t("main")}
        </Typography>
      </AccordionSummary>
      <Box>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
          }}
        >
          <Box
            sx={{
              maxWidth: "480px",
              padding: { xs: "0px 16px 16px 16px", md: "0px 20px 20px 20px" },
            }}
          >
            <StatsSelectComponent
              title={Boolean(pwaID) ? "" : t("selectPwa")}
              options={selectOptions}
              backgroundColor="#F6F7F9"
              noBorder={true}
              value={selectValue}
              onChange={(e: any) => handleSelectChange(e)}
              disabled={Boolean(pwaID)}
              placeholder={t("selectPwaPush")}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              width: "100%",
              padding: { xs: "8px 16px 16px 16px", md: "10px 20px 20px 20px" },
              borderTop: "1px solid #EEEEEE",
            }}
          >
            <Box sx={{ minWidth: "214px" }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={pushData.delivery}
                  onChange={handleChange}
                >
                  {!delivery && (
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "#2E263D",
                          fontSize: "14px",
                        },
                      }}
                      value="now"
                      control={<Radio />}
                      label={t("nowDelivery")}
                    />
                  )}

                  {!delivery || delivery === "scheduled" ? (
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "#2E263D",
                          fontSize: "14px",
                        },
                      }}
                      value="scheduled"
                      control={<Radio />}
                      label={t("scheduleDelivery")}
                    />
                  ) : null}

                  {!delivery || delivery === "interval" ? (
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "#2E263D",
                          fontSize: "14px",
                        },
                      }}
                      value="interval"
                      control={<Radio />}
                      label={t("intervalCampaign")}
                    />
                  ) : null}
                  {!delivery || delivery === "event" ? (
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "#2E263D",
                          fontSize: "14px",
                        },
                      }}
                      value="event"
                      control={<Radio />}
                      label={t("eventDelivery")}
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ flexBasis: { sm: "50%" }, maxWidth: { xs: "100%", sm: "50%" } }}>
              {pushData.delivery === "scheduled" && (
                <StandartSets scheduledTimestamp={scheduledTimestamp} useUserTimezone={useUserTimezone} />
              )}
              {pushData.delivery === "interval" && <IntervalSets intervalDates={intervalDates} />}
              {pushData.delivery === "event" && <EventSets />}
            </Box>
          </Box>
        </AccordionDetails>
      </Box>
    </Accordion>
  );
}
